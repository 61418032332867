<template>
  <div class="container" style="max-height: 1000px;
    min-height: 600px;">







    <div class="row">
      <div >
        <div style="display: flex;
    justify-content: center;">
          <v-card
            max-width="1000"
            style="box-shadow: none !important; background-color: transparent"
          >
           <div class="secondaryContainer">

            

            <v-sheet
    elevation="1"
    max-width="auto"
    style="box-shadow: none !important;background: none"
  >

  <v-card>
   
   
    <v-tabs v-model="tab" bg-color="#002d5b" align-tabs="center">
  <v-tab 
    v-for="i in featuringData" 
    :key="i" 
    :value="i" 
    @click="changevalue(i)"
  >
    <div 
      class="pa-1"
      style="font-weight: 600; font-size: 16px;"
    >
      Flight {{ i }}
    </div>
  </v-tab>
</v-tabs>
    <v-tabs-items  v-model="tab">
      <v-tab-item>
        <v-card v-if="tab == changeData" class="position-relative" >
          <v-card-text class="p-sm-4 p-0">
            

            <v-slide-group class="p-sm-4 p-0"
      center-active
      show-arrows
    >
      <v-slide-group-item
       v-for="(data, index) of BrandFeature" :key="index"
        v-slot="{toggle }"
      >
        <v-card
        v-if="this.fareRes.getPrice?this.fareRes.getPrice==data.TotalPrice?false:true:true"

          :class="this.selectrate === index ? 'slide-card': 'slide-card'"
          class="ma-2 pa-3 farecard"
          height="auto"
          @click="toggle"
          
        >
      
        <div class="d-flex" style="justify-content: space-between">
                              <div>
                                <v-img
  :src="this.fareRes.brantDeatil.ownerlogo"
  style="
    width:30px;
    position: relative;
    top: 7px;"
/>
                              </div>
                              <div>
                                <v-radio color="primary"
                                  @click="submit(data,index)"
                                  class="custom-radio"
                                size="15" :value="index"
                                 v-model="selectrate"></v-radio>
                              </div>
                            </div>


                            <div style="font-weight:600;font-size:15px;" class="mt-2">
                              {{data.BrandName}}
                            </div>



<div class="mt-2">
  <span style="font-weight:500;font-size:14px;">Cabin : </span>
  <span style="font-weight:500;font-size:12px;">{{ this.fareRes.brantDeatil.cabinClass}}</span>
</div>

<!-- {{console.log(data.brantFare,'`Flight${changeData}`')}} -->
<div v-for="(value, key) in data.brantFare[0]?.[`Flight${changeData}`]" :key="key" class="mt-1">
  <div v-if="Array.isArray(value)">
    <div style="font-weight:600;font-size:15px">{{ key }}</div>
    <ul v-if="value.length > 0" >



      
      <li
        v-for="(item,index2) in value"
        :key="index2"
        style="font-weight:400;font-size:13px;margin-top: 5px;"
      >
     
        <v-icon size="20">
          {{ seticon(item)}}
        </v-icon>
        <v-icon
          size="10"
          style="position: relative; top: 5px; right: 4px;"
          :color="item.Application == 'F' || item.Application == 'C' ? 'green' : 'red'"
        >
          {{
            item.Application == 'F'
              ? 'mdi-check-circle'
              : item.Application == 'C'
              ? 'mdi-currency-usd'
              : 'mdi-close-circle'
          }}
        </v-icon>
        {{ item.Name }}-{{ item.source}}
        {{
          item.Application == 'F'
            ? 'free'
            : item.Application == 'C'
            ? 'included for a fee'
            : 'not included'
        }}
      </li>
    </ul>
  </div>
</div>


         
            <div class="d-flex flex-column justify-content-between" >
                         
              <div class="mt-2" style="text-align: center">
                            <div style="font-size: 18px; font-weight: 600">
                              {{currency}} {{data.personPrice}}
                            </div>
                            per person
                          </div>

                           <div style="text-align: center;">
                            <v-btn
                                rounded="3"
                                width="150px"
                                @click="submit(data,index)"
                                :style="
                                
                                  this.selectrate === index
                                    ? 'background-color:green !important;'
                                    : 'background-color:#002d5b !important'
                                "
                                color="primary"
                                outlined
                              >
                                {{
                                  this.selectrate === index
                                    ? `${$t("brantFareContent.selected")}`
                                    : `${$t("brantFareContent.select")}`
                                }}
                              </v-btn>
                           </div>
                              
                            </div>
                          
          
        </v-card>
      </v-slide-group-item>
    </v-slide-group>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>



  </v-sheet>


  
           </div>









            
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    childData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      tabs: [
        { id: 1, title: "Home", content: "This is the Home tab content." },
        { id: 2, title: "Profile", content: "This is the Profile tab content." },
        { id: 3, title: "Messages", content: "This is the Messages tab content." },
        { id: 4, title: "Settings", content: "This is the Settings tab content." },
        { id: 5, title: "About", content: "This is the About tab content." },
      ],
      // tab: "one",
       changeData:1,
      BrandFeature:[],
      selectrate: 0,
      fareRes: "",
      dataLoder: true,
      totalBrantFare: [],
      brantFare1: [],
      BrandFeaturesDetails: {},
      BrandedFareID: [],
      BrandFareDetails: [],
      brantName: [],
      offerId: "",
      selectOffer: [],
      currency: "",
      returnvalue: "",
      featuringData:"",
        text: 'Lorem ipsum dolor sit amet', model: 1, 
      
    };
  },




  methods: {

    changevalue(i){
     
      if(i > 0){
        setTimeout(()=>{
          this.changeData=i
        },5)
     
        console.log(i,this.changeData,'this.changeData')
      }
      else{
        console.log(i,this.changeData,'this.changeDataeeee')
      }
     
    },



    seticon(item){


// console.log(item,'item')

let icon=[
              {"Name": "Seat Selection",
                    "Application": "F",
                    icon:"mdi-seat-recline-extra"
                },
                {
                    "Name": "Wifi",
                    "Application": "F",
                    icon:"mdi-wifi"
                },
                {
                    "Name": "Meals",
                    "Application": "F",
                    icon:"mdi-food-fork-drink"
                },
                {
                    "Name": "Beverages",
                    "Application": "C",
                    icon:"mdi-coffee"
                },
                {
                    "Name": "Cabin Baggage",
                    "Application": "F",
                    icon:"mdi-bag-personal"
                
                },
                {
                    "Name": "Checked Bag",
                    "Application": "F",
                    icon:"mdi-bag-suitcase"
                
                },
                {
                    "Name": "Cancellation",
                    "Application": "D",
                    icon:"mdi-refresh"
                },
                {
                    "Name": "Changes",
                    "Application": "C",
                    icon:"mdi-currency-usd"
                }
]

const result = icon.find((c) => c.Name === item.Name);
return result ? result.icon : '';



    },




    submit(data,index) {
      console.log(data,'888888')
      this.selectrate = index;
      this.returnvalue = data
      this.$emit("childValue", this.returnvalue);
      console.log(
        this.brantName,
        this.returnvalue,
        this.selectrate,
        "brantName"
      );
      // this.$emit('returnvalue', this.returnvalue);
    },

    getBrantFare() {
      this.selectOffer = this.fareRes?.OffersGroup?.AirlineOffers?.Offer;
      this.currency = this.fareRes.brantDeatil.currency;
      this.offerId = this.fareRes?.brantDeatil?.selectId || "";

      console.log(this.fareRes.brantDeatil.brantFare,'this.fareRes.brantDeatil.brantFare')
    let inputData=this.fareRes.brantDeatil.brantFare

      let convertToArray = (data) => {
    return Object.values(data);
};

const result = convertToArray(inputData);
this.featuringData=result.length
      // console.log(this.fareRes.brantDeatil.brantFare,featuringData,'00tttt')
      
      this.BrandedFareID.push(this.offerId)
      this.BrandFeature.push({
            BrandName:this.fareRes.brantDeatil.brantName,
            BrandId:this.fareRes.brantDeatil.BrandId,
            TotalPrice:this.fareRes.brantDeatil.TotalPrice,
            personPrice:this.fareRes.brantDeatil.perperson,
            brantFare:[this.fareRes.brantDeatil.brantFare]
                                        })
           console.log(this.BrandFeature,'BrandFeature')
                                        

     this.selectOffer.find((c) =>{
      if(c.OfferID === this.offerId){
        // console.log(c,this.BrandedFareID,'000000')
      c.BrandedFareIDs.forEach((d)=>this.BrandedFareID.push(d))

       
      }
     } );
     console.log(this.BrandedFareID,'00000555550')


      console.log(this.brantName, " this.brantName");
      // this.brantFare1 = this.fareRes?.OffersGroup?.AirlineOffeselectIdrs?.Offer?.[0]?.OfferItem?.[0]?.[0]?.BrandFeature?.Segment1 || [];

      this.BrandFeaturesDetails =
        this.fareRes?.DataLists?.BrandFeaturesDetails || {};

      console.log(this.BrandedFareID, this.fareRes, "this.fareRes777");
      this.BrandFareDetails =
        this.fareRes?.OffersGroup?.AirlineOffers?.BrandedOffers || [];
     

      if (this.BrandedFareID.length > 0) {
        this.BrandedFareID.forEach((d) => this.getbrantFareCheck(d));
      }
      

      console.log(this.BrandFeature, "this.totalBrantFare");
    },

    getbrantFareCheck(data) {
      console.log(data,this.BrandedFareID, "999999");
      this.BrandFareDetails.forEach((a) => {
        // let fareId = "";
        if ( data === a.ID) {
          console.log(a, "000000000");

          this.BrandFeature.push({
            BrandName:a.BrandName,
            BrandId:a.BrandId,
            TotalPrice:a.TotalPrice.BookingCurrencyPrice,
            personPrice:a.PerPerson.BookingCurrencyPrice,
            brantFare:[a.OfferItem?.[0]?.[0]?.BrandFeature]
                                        })

    
        }

        
      });
      // this.BrandFeature[0].brantFare[0].Flight1.Bags[0].source="11111111111"
      //  this.BrandFeature[0].brantFare[0].Flight2.Bags[0].source="22222222222"
      // console.log(this.BrandFeature,this.BrandedFareID,'this.BrandFeature')
    },

   
  },
  created() {
    this.fareRes=this.childData
    console.log(this.fareRes,this.childData, "this.fareRes");
    if (this.fareRes?.ShoppingResponseId) {
      this.getBrantFare();

    }
  },
};
</script>

<style scoped>
/* @import "@/assets/main.css"; */

/* .containererer {
  width: 80%;
  display: block;
  margin: auto;
} */

.secondaryContainer table {
  /* overflow: scroll; */
  border-collapse: collapse;
  color: white;
  table-layout: fixed;
}
.secondaryContainer table tr {
  padding: 8px;
}
.secondaryContainer {
  height: auto;
  padding: 5px;
  overflow: auto;
  border-collapse: collapse;
  border-radius: 10px;
  text-align: left;
}

.secondaryContainer th {
  width: 150px;
  white-space: nowrap;
  height: 30px;
  padding: 20px;
  /* position: sticky; */
  top: 0;
  background-color: white;
  color: #3ba6bc;
  border-right: 1px dotted grey;
}

.secondaryContainer td {
  /* border-bottom: none; */
  font-size: 14px;
  text-align: center;
  min-width: 200px;
  white-space: nowrap;
  /* height: 30px; */
  color: black;
  padding: 14px 5px !important;
  /* border-bottom: 1px solid #ddd; */
}

.secondaryContainer tr {
  /* height: 40px; */
  border: none !important;
}

.secondaryContainer table {
  border: none;
  border-collapse: collapse;
}
.secondaryContainer table td {
  border-left: 1px dotted grey;
}
.secondaryContainer table td:last-child {
  border-right: 1px dotted grey;
}
.secondaryContainer table td:first-child {
  border-left: none;
  text-align: left;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
}

/* .secondaryContainer table tr:first-child td:first-child {
    border-bottom: 1px solid grey;
} */

.secondaryContainer table tr:last-child td:first-child {
  border-bottom: none;
}

/* .secondaryContainer th div{
    padding: 12px !important;
    text-align: center;
    background: grey;
    color: white;
    border-radius: 10px 10px 0px 0px;
} */
td,
th {
  text-align: left;
  padding: 10px;
}
.secondaryContainer tr:hover {
  background: #f0f0f0;
  cursor: pointer;
}

/* .secondaryContainer tr:last-child{
  height: 60px;
} */

.secondaryContainer tr:first-child:hover,
.secondaryContainer tr:last-child:hover {
  background: initial;
  cursor: default;
}

.fight-img-filter {
  filter: invert(32%) sepia(77%) saturate(297%) hue-rotate(181deg)
    brightness(105%) contrast(100%);
}

.slide-card:hover {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px,
    rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
  transition: box-shadow 0.1s ease-out;
}
.select-card {
  box-shadow: none;
  border: 1px solid lightgray;
  border-radius: 15px;
  max-width: 100%;
  background-color: white !important;
  width: 300px;
}

:deep(.farecard .v-card__overlay, .farecard .v-card__underlay){
  display:none !important;
}.custom-radio .v-radio {
  transform: scale(1.5); /* Adjust the scale as needed */
}

.slide-card{
    border-radius: 15px;
    background-color: white !important;
    border: 1px solid lightgray;
    box-shadow: none;
    max-width: 250px !important;
    min-width: 290px;
    width: auto;
    margin: 8px 4px !important;
    padding: 12px !important;
}
:deep(.v-slide-group__prev){
  z-index: 99;
  position: absolute;
  left: 0px;
  top: 50%;
}
:deep(.v-slide-group__next){
  z-index: 99;
  position: absolute;
  right: 0px;
  top: 50%;
}
:deep(.v-slide-group__next i){
  background: #002d5b;
  padding: 20px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
:deep(.v-slide-group__prev i){
  background: #002d5b;
  padding: 20px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .slide-card{
    min-width: 320px !important;
  }
  :deep(.v-slide-group__prev){
  left:-12px;
}
  :deep(.v-slide-group__next){
  right: -1px;
}
  :deep(.v-slide-group__prev i){
    padding: 8px;
    font-size: 28px;
  }
  :deep(.v-slide-group__next i){
    padding: 8px;
    font-size: 28px;
  }
} 
</style>
