<template>
  <div class="pagewrap modify-search" v-if="!pageloader" tabindex="0">
    <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
      <div class="head-wrapper">

        <!-- review booking flight-->
        <div class="flight-search">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <span class="fw-bold theme-text-white">{{ $t("flightInfoContent.contents.travellerDetails") }}</span>
              </div>
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <ul class="reviewStatus step3">
                  <li><span class="numbering completed">1</span><span class="reviewText grayText font12 ">{{
                    $t("flightInfoContent.contents.flightSelected") }}</span></li>
                  <li><span class="numbering onpage">2</span><span class="reviewText grayText font12 active">{{
                    $t("flightDetContent.contents.review") }}</span></li>
                  <li><span class="numbering ">3</span><span class="reviewText grayText font12 ">{{
                    $t("flightDetContent.contents.traveller") }}
                      &amp; {{ $t("flightDetContent.contents.addons") }}</span></li>
                  <li><span class="numbering ">4</span><span class="reviewText grayText font12 ">{{
                    $t("flightDetContent.contents.makePayment") }}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- body section -->


      <div class="content-section">
        <!-- flight result page - oneway start -->
        <div class="review-flight py-3" style="background-color:#efeeee;">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="d-flex justify-content-between align-items-start mb-3" style="display:none !important;">
                  <div class="fw-bold">{{ $t("flightInfoContent.contents.flightDetails") }}</div>
                </div>
                <!-- flight details section -->
                <div class="d-none" v-for="(data, index) of roundresult" :key="index">
                  <div class="fw-bold" v-if="data.Return">{{ $t("flightInfoContent.contents.roundTrip") }}</div>
                  <div class="fw-bold" v-if="!data.Return">{{ $t("flightInfoContent.contents.oneway") }}</div>
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequal(data.Depature)" class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoData.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoData.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoData" :key="index"
                              :style="sameLogoData.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName(data.fromTrip) }} {{ $t("flightInfoContent.contents.to")
                              }} {{ getarrName(data.toTrip) }}</div>
                            <div class="font-medium" v-if="data.Return">{{ $t("flightInfoContent.contents.departure") }}
                              {{ depdate(data.Depature) }} - {{ $t("flightInfoContent.contents.arrival") }} {{
                              getstartdate(data.Return) }}</div>
                            <div class="font-medium" v-else>{{ $t("flightInfoContent.contents.departure") }} {{
                              depdate(data.Depature) }} </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart"
                          role="button" aria-expanded="false" aria-controls="collapseDepart">{{
                            $t("flightInfoContent.contents.flightDetails") }}<i class="bi bi-chevron-down ps-1"></i></a>

                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3" v-for="(data1, index) of data.Depature" :key="index">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium"> {{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ (data.Depaturejourney.Stops) }} Stops</span> <span
                                    class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <v-icon class="bi bi-briefcase me-1"
                                    font-size="16px"></v-icon> Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Depature.length - 1">
                                  Layover Time: {{ data.Depaturejourney.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3" v-if="data.Return">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequal1(data.Return)" class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoData1.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoData1.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoData1" :key="index"
                              :style="sameLogoData1.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName(data.toTrip) }} To {{ getarrName(data.fromTrip) }}</div>
                            <div class="font-medium" v-if="data.Return">Departure {{ depdate(data.Return) }} - Arrival
                              {{ getstartdate(data.Depature) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseReturn"
                          role="button" aria-expanded="false" aria-controls="collapseReturn">Flight Details
                          <i class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseReturn">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="px-3" v-for="(data1, index) of data.Return" :key="index">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span class="fw-normal">{{
                                    getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ data.Returnjourney.Stops }} Stops</span> <span
                                    class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span class="fw-normal">{{
                                    getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i>
                                  Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Return.length - 1">
                                  Layover Time: {{ data.Returnjourney.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-none" v-for="(data, index) of multiresult" :key="index">
                  {{ console.log(data, 'datavaluee') }}
                  <div class="fw-bold">Multicity</div>
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequalMulti(data.Flight1)" class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoDataMulti.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoDataMulti.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoDataMulti" :key="index"
                              :style="sameLogoDataMulti.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName1(data.muticityData[0]) }} To {{
                              getarrName1(data.muticityData[0]) }}</div>
                            <div class="font-medium">Departure {{ depdate(data.Flight1) }} - Arrival {{
                              getstartdate(data.Flight1) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart"
                          role="button" aria-expanded="false" aria-controls="collapseDepart">Flight
                          details<i class="bi bi-chevron-down ps-1"></i></a>

                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart">
                      <div class="row" v-for="(data1, index) of data.Flight1" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium"> {{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ (data.Flight1JourneyTime.Stops) }} Stops</span>
                                  <span class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <v-icon class="bi bi-briefcase me-1"
                                    font-size="16px"></v-icon> Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Flight1.length - 1">
                                  Layover Time: {{ data.Flight1JourneyTime.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequalMulti1(data.Flight2)"
                            class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoDataMulti1.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoDataMulti1.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoDataMulti1" :key="index"
                              :style="sameLogoDataMulti1.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName1(data.muticityData[1]) }} To {{
                              getarrName1(data.muticityData[1]) }}</div>
                            <div class="font-medium">Departure {{ depdate(data.Flight2) }} - Arrival {{
                              getstartdate(data.Flight2) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseReturn"
                          role="button" aria-expanded="false" aria-controls="collapseReturn">Flight
                          Details
                          <i class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseReturn">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="px-3" v-for="(data1, index) of data.Flight2" :key="index">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span class="fw-normal">{{
                                    getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ data.Flight2JourneyTime.Stops }} Stops</span>
                                  <span class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span class="fw-normal">{{
                                    getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i>
                                  Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Flight2.length - 1">
                                  Layover Time: {{ data.Flight2JourneyTime.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="data.Flight3" class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequalMulti2(data.Flight3)"
                            class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoDataMulti2.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoDataMulti2.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoDataMulti2" :key="index"
                              :style="sameLogoDataMulti2.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName1(data.muticityData[2]) }} To {{
                              getarrName1(data.muticityData[2]) }}</div>
                            <div class="font-medium">Departure {{ depdate(data.Flight3) }} - Arrival {{
                              getstartdate(data.Flight3) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseFlight3"
                          role="button" aria-expanded="false" aria-controls="collapseFlight3">Flight
                          Details
                          <i class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseFlight3">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="px-3" v-for="(data1, index) of data.Flight3" :key="index">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span class="fw-normal">{{
                                    getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ data.Flight3JourneyTime.Stops }} Stops</span>
                                  <span class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span class="fw-normal">{{
                                    getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i>
                                  Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Flight3.length - 1">
                                  Layover Time: {{ data.Flight3JourneyTime.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="data.Flight4" class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div v-if="areLogosequalMulti3(data.Flight4)"
                            class=" d-flex align-items-center flex-wrap pe-0"
                            :class="sameLogoDataMulti3.length == 1 ? 'col-1' : 'col-2'"
                            :style="sameLogoDataMulti3.length >= 2 ? 'width:10%' : ''">
                            <div v-for="(logo, index) in sameLogoDataMulti3" :key="index"
                              :style="sameLogoDataMulti3.length >= 2 ? 'width:50%' : ''">
                              <v-img :src="logo" width="40px" class="gx-1"></v-img>
                            </div>
                          </div>
                          <div class="col-10">
                            <div class="fw-bold">{{ getdepName1(data.muticityData[3]) }} To {{
                              getarrName1(data.muticityData[3]) }}</div>
                            <div class="font-medium">Departure {{ depdate(data.Flight4) }} - Arrival {{
                              getstartdate(data.Flight4) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseFlight4"
                          role="button" aria-expanded="false" aria-controls="collapseFlight4">Flight
                          Details
                          <i class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseFlight4">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="px-3" v-for="(data1, index) of data.Flight4" :key="index">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                  <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                  <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                </div>
                                <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS</div>
                                <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                <div class="font-medium float-start mx-5">{{ data.Refund }}</div>

                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                </div>
                                <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span class="fw-normal">{{
                                    getdated(data1.Departure.Date) }}</span></div>
                                <div class="font-small">{{ data1.Departure.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                </div>
                                <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{
                                    data1.layoverTimes }}</span>
                                  <span class="font-small d-block">{{ data.Flight4JourneyTime.Stops }} Stops</span>
                                  <span class="font-small d-block">Equipment:{{ data1.Equipment.AircraftCode }}</span>
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span class="fw-normal">{{
                                    getdated1(data1.Arrival.Date) }}</span></div>
                                <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                  class="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i>
                                  Baggage </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== data.Flight4.length - 1">
                                  Layover Time: {{ data.Flight4JourneyTime.Time }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- travel details section -->

                <div class="d-flex justify-content-between align-items-start mb-3">
                  <div class="fw-bold">{{ $t("flightInfoContent.contents.travellerDetails") }} {{ adultData.length }}
                  </div>
                </div>
                <!-- add traveller details -->
                <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                  <div class="col-12 border-bottom">
                    <!-- flight details section -->
                    {{ console.log(adultData, 'adulttt11222') }}
                    <div class="theme-box-shadow theme-border-radius bg-light mb-3" v-for="(data, index) of adultData"
                      :key="index">
                      <div class="row border-bottom py-2 m-auto">
                        <div class="col-8">
                          <div class="fw-bold font-medium">{{ $t("flightInfoContent.contents.adult") }} {{ index + 1 }}
                          </div>
                        </div>
                        <div class="col-4 text-end align-self-center">
                          <a class="font-small" data-bs-toggle="collapse" :href="'#collapseAdult-' + index"
                            role="button" aria-expanded="false" :aria-controls="'collapseAdult-' + index"
                            :class="!dropdown1 ? 'not-collapsed' : 'collapsed'" @click="dropdown1 == !dropdown1"><i
                              class="bi bi-chevron-down ps-1"></i></a>

                        </div>
                      </div>

                      <div class="collapse" :id="'collapseAdult-' + index"
                        :class="!dropdown1 ? 'collapse' : 'collapse show'">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="px-3">
                              <ul class="row pt-3 m-0 ps-0">
                                <li class="col-12 col-md-2 title">
                                  <label class="visually-hidden" for="inlineFormSelectTitle">Preference</label>
                                  <!-- <select class="form-select" id="inlineFormSelectTitle" >
                                                                    <option >Title</option>
                                                                    <option selected value="1">Mr.</option>
                                                                    <option value="2">Mrs.</option>
                                                                    <option value="3">Ms.</option>
                                                                </select> -->
                                  <v-select :items="['Mr', 'Miss', 'Mrs', 'Ms']" v-model="data.salutation"
                                    variant="underlined"></v-select>
                                </li>
                                <li class="col-12 col-md-3 pb-3 ">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.firstName')"
                                    variant="underlined" v-model="data.fname" :rules="firstName"
                                    @keydown="filterkey($event)" class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.middleName')"
                                    variant="underlined" v-model="data.middle" @keydown="filterkey($event)"
                                    class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.lastName')"
                                    variant="underlined" v-model="data.last" :rules="lastName"
                                    @keydown="filterkey($event)" class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-lg-3 pb-3 gender">
                                  <label for="inlineFormSelectTitle3" style="font-size:14px;">{{
                                    $t("flightInfoContent.contents.labels.gender") }}</label>
                                  <v-select v-model="data.gender" :items="['Male', 'Female']" variant="underlined"
                                    :rules="[(v) => !!v || 'This field is required']"></v-select>
                                </li>
                                <li class=" col-12 col-lg-9 pb-3 datePickerSection">
                                  <label for="inlineFormSelectTitle3" style="font-size:14px;">{{
                                    $t("flightInfoContent.contents.labels.dateofBirth") }}</label>
                                  <div class="row gx-0">
                                    <div class="col-12 col-md-8 d-flex g-0 border-0">
                                      <div class="col-4 col-lg-6 p-0">
                                        <v-text-field v-model="data.dateDob.year" class="yearPick"
                                          id="inlineFormSelectTitle3"
                                          :label="this.$t('flightInfoContent.contents.labels.year')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.year')"
                                          :rules="yearRules(data.Type, index)" maxlength="4" variant="underlined"
                                          @keypress="filter(event)" @input="inputHandle(data, index)"
                                          ref="yearInput"></v-text-field>
                                      </div>
                                      <div class="col-4 col-lg-6">
                                        <v-select class="monthPick border-0" id="inlineFormSelectTitle4"
                                          :label="this.$t('flightInfoContent.contents.labels.month')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.month')"
                                          v-model="data.dateDob.month"
                                          :items="data.isYearValid ? monthListOriginal : []" variant="underlined"
                                          :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"></v-select>
                                      </div>
                                      <div class="col-4 col-lg-6">

                                        <v-text-field v-model="data.dateDob.date" id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :label="this.$t('flightInfoContent.contents.labels.date')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.date')"
                                          :rules="dateRules(data, index)" maxlength="2" variant="underlined"
                                          @keypress="filter(event)"></v-text-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="adultData.length < adult" class="d-flex justify-content-between align-items-start mb-3"
                      style="cursor:pointer;">
                      <div class="font-medium" style="color:#008CFF" @click="addAdult"><i
                          class="bi bi-plus-lg me-1"></i>{{ $t("flightInfoContent.contents.add") }} {{
                            $t("flightInfoContent.contents.adult") }}</div>
                    </div>
                    <div
                      v-if="errorMessage.length && errorMessage.includes('Please add the required number of adults') && adultData.length !== adult">
                      <div class="text-danger">{{ $t("flightInfoContent.contents.errText") }} {{
                        $t("flightInfoContent.contents.adult") }}</div>
                    </div>
                  </div>
                  <!-- child details section -->
                  <!-- <div class="d-flex align-items-between my-3">
                                    <div class="flex-grow-1">
                                        <span class="fw-bold text-uppercase">Child</span>
                                    </div>
                                </div> -->
                  <!-- repetable element -->
                  <div class="col-12 border-bottom">
                    <div class="theme-box-shadow theme-border-radius bg-light mb-3" v-for="(data, index) of childData"
                      :key="data">
                      <div class="row border-bottom py-2 m-auto">
                        <div class="col-8">
                          <div class="fw-bold font-medium">{{ $t("flightInfoContent.contents.child") }} {{ index + 1 }}
                          </div>
                        </div>
                        <div class="col-4 text-end align-self-center">
                          <a class="font-small" data-bs-toggle="collapse" :href="'#collapseChild-' + index"
                            role="button" aria-expanded="false" :aria-controls="'collapseChild-' + index"
                            :class="!dropdown1 ? 'not-collapsed' : 'collapsed'" @click="dropdown1 == !dropdown1"><i
                              class="bi bi-chevron-down ps-1"></i></a>

                        </div>
                      </div>
                      <div class="collapse" :id="'collapseChild-' + index"
                        :class="!dropdown1 ? 'collapse' : 'collapse show'">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="px-3">
                              <ul class="row pt-3 m-0 ps-0">
                                <li class="col-12 col-md-2 title">
                                  <label class="visually-hidden" for="inlineFormSelectTitle5">Preference</label>
                                  <v-select :items="['Mr', 'Miss']" v-model="data.salutation"
                                    variant="underlined"></v-select>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.firstName')"
                                    variant="underlined" v-model="data.fname" :rules="firstName"
                                    @keydown="filterkey($event)" class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.middleName')"
                                    variant="underlined" v-model="data.middle" @keydown="filterkey($event)"
                                    class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.lastName')"
                                    variant="underlined" v-model="data.last" :rules="lastName"
                                    @keydown="filterkey($event)" class="Name"></v-text-field>
                                </li>
                                <li class="col-12 col-lg-3 pb-3 gender">
                                  <label for="inlineFormSelectTitle3" style="font-size:14px;">{{
                                    $t('flightInfoContent.contents.labels.gender') }}</label>
                                  <v-select v-model="data.gender" :items="['Male', 'Female']" variant="underlined"
                                    :rules="[(v) => !!v || 'this field is required']"></v-select>
                                </li>
                                <li class="col-12 col-lg-9 pb-3 datePickerSection">
                                  <label for="inlineFormSelectTitle3" style="font-size:14px;">{{
                                    $t("flightInfoContent.contents.labels.dateofBirth") }}</label>
                                  <div class="row gx-0">
                                    <div class="col-12 col-md-8 d-flex g-0 border-0">
                                      <div class="col-4 col-lg-6 p-0">
                                        <v-text-field v-model="data.dateDob.year" class="yearPick"
                                          id="inlineFormSelectTitle3"
                                          :label="this.$t('flightInfoContent.contents.labels.year')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.year')"
                                          :rules="yearRules(data.Type, index)" maxlength="4" variant="underlined"
                                          @keypress="filter(event)" @input="inputHandle(data, index)"
                                          ref="yearInput"></v-text-field>
                                      </div>
                                      <div class="col-4 col-lg-6">
                                        <v-select class="monthPick border-0" id="inlineFormSelectTitle4"
                                          :label="this.$t('flightInfoContent.contents.labels.month')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.month')"
                                          v-model="data.dateDob.month"
                                          :items="data.isYearValid ? monthListOriginal : []" variant="underlined"
                                          :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"></v-select>
                                      </div>
                                      <div class="col-4 col-lg-6">

                                        <v-text-field v-model="data.dateDob.date" id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :label="this.$t('flightInfoContent.contents.labels.date')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.date')"
                                          :rules="dateRules(data, index)" maxlength="2" variant="underlined"
                                          @keypress="filter(event)"></v-text-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="childData.length < child" class="d-flex justify-content-between align-items-start mb-3"
                      style="cursor:pointer;">
                      <div class="font-medium" style="color:#008CFF" @click="addChild"><i
                          class="bi bi-plus-lg me-1"></i>{{ $t("flightInfoContent.contents.add") }} {{
                            $t("flightInfoContent.contents.child") }}</div>
                    </div>
                    <div
                      v-if="errorMessage.length && errorMessage.includes('Please add the required number of child') && childData.length !== child">
                      <div class="text-danger">{{ $t("flightInfoContent.contents.errText") }} {{
                        $t("flightInfoContent.contents.child") }}</div>
                    </div>
                  </div>
                  <!-- child details section -->
                  <!-- <div class="d-flex align-items-between my-3">
                                    <div class="flex-grow-1">
                                        <span class="fw-bold text-uppercase">Infant</span>
                                    </div>
                                </div> -->
                  <!-- repetable element -->
                  <div class="col-12 border-bottom">

                    <div class="theme-box-shadow theme-border-radius bg-light mb-3" v-for="(data, index) of infrantData"
                      :key="data">
                      <div class="row border-bottom py-2 m-auto">
                        <div class="col-8">
                          <div class="fw-bold font-medium">{{ $t("flightInfoContent.contents.infant") }} {{ index + 1 }}
                          </div>
                        </div>
                        <div class="col-4 text-end align-self-center">
                          <a class="font-small" data-bs-toggle="collapse" :href="'#collapseInfant-' + index"
                            role="button" aria-expanded="false" :aria-controls="'collapseInfant-' + index"
                            :class="!dropdown1 ? 'not-collapsed' : 'collapsed'" @click="dropdown1 == !dropdown1"><i
                              class="bi bi-chevron-down ps-1"></i></a>

                        </div>
                      </div>
                      <div class="collapse" :id="'collapseInfant-' + index"
                        :class="!dropdown1 ? 'collapse' : 'collapse show'">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="px-3">
                              <ul class="row pt-3 m-0 ps-0">
                                <li class="col-12 col-md-2 title">
                                  <label class="visually-hidden" for="inlineFormSelectTitle1">Preference</label>
                                  <v-select :items="['Mr', 'Miss']" v-model="data.salutation"
                                    variant="underlined"></v-select>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.firstName')"
                                    variant="underlined" v-model="data.fname" :rules="firstName"
                                    @keydown="filterkey($event)"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.middleName')"
                                    variant="underlined" v-model="data.middle"
                                    @keydown="filterkey($event)"></v-text-field>
                                </li>
                                <li class="col-12 col-md-3 pb-3">
                                  <v-text-field :label="this.$t('flightInfoContent.contents.labels.lastName')"
                                    variant="underlined" v-model="data.last" @keydown="filterkey($event)"
                                    :rules="lastName"></v-text-field>
                                </li>
                                <li class="col-12 col-lg-3 pb-3 gender">
                                  <label for="inlineFormSelectTitle3" style="font-size:14px;">{{
                                    $t('flightInfoContent.contents.labels.gender') }}</label>
                                  <v-select v-model="data.gender" :items="['Male', 'Female']" variant="underlined"
                                    :rules="[(v) => !!v || 'this field is required']"></v-select>
                                </li>
                                <li class="col-12 col-lg-9 pb-3 datePickerSection" style="font-size:14px;">
                                  <label for="inlineFormSelectTitle3">{{
                                    $t("flightInfoContent.contents.labels.dateofBirth") }}</label>
                                  <div class="row gx-0">
                                    <div class="col-12 col-md-8 d-flex g-0 border-0">
                                      <div class="col-4 col-lg-6 p-0">
                                        <v-text-field v-model="data.dateDob.year" class="yearPick "
                                          id="inlineFormSelectTitle3"
                                          :label="this.$t('flightInfoContent.contents.labels.year')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.year')"
                                          :rules="yearRules(data.Type, index)" maxlength="4" variant="underlined"
                                          @keypress="filter(event)" @input="inputHandle(data, index)"
                                          ref="yearInput"></v-text-field>
                                      </div>
                                      <div class="col-4 col-lg-6">
                                        <v-select class="monthPick border-0" id="inlineFormSelectTitle4"
                                          :label="this.$t('flightInfoContent.contents.labels.month')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.month')"
                                          v-model="data.dateDob.month" :items="data.isYearValid ? monthList : []"
                                          variant="underlined" :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"></v-select>
                                      </div>
                                      <div class="col-4 col-lg-6">

                                        <v-text-field v-model="data.dateDob.date" id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :label="this.$t('flightInfoContent.contents.labels.date')"
                                          :placeholder="this.$t('flightInfoContent.contents.labels.date')"
                                          :rules="dateRules(data, index)" maxlength="2" variant="underlined"
                                          @keypress="filter(event)"></v-text-field>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="infrantData.length < inf" class="d-flex justify-content-between align-items-start mb-3"
                      style="cursor:pointer;">
                      <div class="font-medium" style="color:#008CFF" @click="addInf"><i
                          class="bi bi-plus-lg me-1"></i>{{ $t("flightInfoContent.contents.add") }} {{
                            $t("flightInfoContent.contents.infant") }}</div>
                    </div>
                    <div
                      v-if="errorMessage.length && errorMessage.includes('Please add the required number of infants') && infrantData.length !== inf">
                      <div class="text-danger">{{ $t("flightInfoContent.contents.errText") }} {{
                        $t("flightInfoContent.contents.infant") }}</div>
                    </div>
                  </div>
                </div>
                <!-- contact information details section -->
                <div class="d-flex justify-content-between align-items-start mb-3">
                  <div class="fw-bold">{{ $t("flightInfoContent.contents.contactInfo") }}</div>
                </div>
                <!-- add contact details -->
                <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0" v-for="data of paxContacts"
                  :key="data">
                  <div class="row">
                    <div class="col-12">
                      <span class=" font-medium">{{ $t("flightInfoContent.contents.subHaed") }}</span>
                    </div>
                    <div class="col-12">
                      <ul class="row pt-3 mb-0 ps-0">
                        <li class="col-12 col-md-4 pb-3 country">
                          <!-- <label class="font-medium" for="inlineFormSelectCountryCode">Country
                                                    Code</label> -->
                          <v-autocomplete :items="countrycode" item-title="name" variant="underlined" width="auto"
                            :rules="[
                              (v) => !!v || 'Country Code is required',
                            ]"
                            :label="this.$t('flightInfoContent.contents.labels.countryCode')" v-model="data.code"
                            class="countryCode">
                          </v-autocomplete>
                        </li>
                        <li class="col-12 col-md-4 pb-3">
                          <!-- <label class="font-medium" for="specificSizeInputMobileNo">Mobile
                                                    No</label> -->
                          <!-- <input type="number" class="form-control" id="specificSizeInputMobileNo"
                                                    placeholder="Mobile No"> -->
                          <v-text-field v-model="data.number"
                            :label="this.$t('flightInfoContent.contents.labels.phoneNumber')" minlength="5"
                            maxlength="15" :rules="phoneNumberValid" variant="underlined" class="Name"></v-text-field>
                        </li>
                        <li class="col-12 col-md-4 pb-3">
                          <!-- <label class="font-medium" for="specificSizeInputEmail">Email</label> -->
                          <!-- <input type="text" class="form-control" id="specificSizeInputEmail"
                                                    placeholder="Email"> -->
                          <v-text-field :label="this.$t('flightInfoContent.contents.labels.emailId')"
                            :rules="emailRequire" variant="underlined" v-model="data.email" class="Name"></v-text-field>
                        </li>
                      </ul>
                    </div>


                  </div>
                </div>

                <!-- -------seat map----- -->

                <!-- <div>
                  <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
                    <div class="fw-bold">Request Your Seats (Optional)</div>
                  </div>
                  <div class="col-12">
                    <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0">
                      <div v-if="dataLoder" style="justify-content: center;display: flex;padding: 20px 0px;">
                        <v-progress-circular color="primary" :size="50" :width="4" indeterminate></v-progress-circular>
                      </div>
                      <div v-if="totalSeat.length > 0">
                        <div class="mt-3">
                          <div class="d-flex flex-wrap">
                            <div class="pe-2 mt-1" v-for="(data, index) in totalSeat" :key="index">
                              <v-btn variant="outlined"
                                :class="flightSelect == index ? 'tru-flight' : 'tru-flightfalse'"
                                @click="selectdata(data, index)">
                                <div>{{ data.origin }}-{{ data.destination }}</div>
                              </v-btn>
                            </div>
                          </div>

                          <div class=" d-flex flex-wrap mt-2">
                            <div class="pe-2 mt-1" v-for="(data, index) of totalpassanger" :key="index">
                              <v-btn style="font-size:13px" variant="outlined" @click="totalpassData(data, index)"
                                :class="passSelect == index ? 'tru-pass' : ''">
                                Passenger{{ index + 1 }}</v-btn>
                            </div>
                          </div>

                          <div v-if="totalSeat[indexData].cabins.length > 0" style="overflow-x: scroll; border: 1px solid lightgrey" class="mt-2 pa-2">
                            <div v-for="(data, index) in totalSeat[indexData].cabins[0].columns" :key="index"
                              class="d-flex mt-2">
                              <div class="px-1 text-center">
                                <div style="width: 25px;font-weight:500">{{ data.column }}</div>
                              </div>
                              <div class="px-1 text-center" v-for="(datas, index1) in totalData[data.column]"
                                :key="index1">
                                <div>

                                  <div :class="{
                                    'false-sheet': datas.seats[0].occupied === 'true',
                                    'true-sheet': datas.seats[0].occupied === 'false',
                                    'tru-icon': datas.seats[0].occupied === 'false' && datas.color,
                                  }" @click="seatSelectdata(datas, index1, data.column)">
                                    <v-icon :color="datas.seats[0].occupied === 'false' ? 'white' : 'white'" size="25">
                                      mdi-seat-passenger
                                    </v-icon>
                                  </div>
                                </div>
                                <div>
                                  <div v-if="data.space" style="font-size:13px">
                                    {{ datas.row_number }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex justify-content-between flex-wrap mt-2 pa-1"
                          style="border: 1px solid lightgray;">
                          <div class="d-flex flex-wrap m-1nt " style="font-size:13px">

                            <div class="d-flex ms-1">
                              <div class="av-sheet"></div>
                              <div class="mt-1">Available Seat</div>
                            </div>
                            <div class="d-flex ms-2">
                              <div class="oc-sheet"></div>
                              <div class="mt-1">Occupied Seat</div>
                            </div>
                            <div class="d-flex ms-2">
                              <div class="sel-sheet"></div>
                              <div class="mt-1">Selected Seat</div>
                            </div>

                          </div>
                          <div>
                          </div>

                        </div>

                        <div style="overflow-x: scroll;">
                          <table>
                            <tr>
                              <th></th>
                              <th v-for="(data, index) in totalSeat" :key="index">
                                <div style="font-size: 14px;font-weight: 400;">
                                  flight{{ index + 1 }}
                                </div>
                                <div style="font-weight:500" class="mt-1"> {{ data.origin }}-{{ data.destination }}</div>
                              </th>

                            </tr>
                            <tr v-for="(data, index) of totalpassanger" :key="index">
                              <td>
                                <div>
                                  <span>{{ index + 1 }}.</span>
                                  <span>{{ data.name ? data.name : "-------" }}</span>
                                </div>

                              </td>
                              <td v-for="(seat, index) of data.seat_number" :key="index">
                                {{ console.log(seat, 'seat') }}
                                <div style="font-size: 14px; letter-spacing: 1px;">{{ seat }}</div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->


                <!-- GST details section -->
                <!-- <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">GST Details Confirmation (Optional)</div>
                            </div> -->
                <!-- add GST details -->
                <!-- <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <span class=" font-medium">Note: All communication related to booking
                                            will be sent to this email address and mobile.</span>
                                    </div>
                                    <div class="col-12">
                                        <ul class="row pt-3">
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputGSTINNumber">GSTIN
                                                    Number</label>
                                                <input type="number" class="form-control"
                                                    id="specificSizeInputGSTINNumber" placeholder="Enter GSTIN Number">
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputCompanyName">Company
                                                    Name*</label>
                                                <input type="text" class="form-control"
                                                    id="specificSizeInputCompanyName" placeholder="Enter Name">
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputCompanyEmail">Company
                                                    Email*</label>
                                                <input type="text" class="form-control"
                                                    id="specificSizeInputCompanyEmail"
                                                    placeholder="Enter Company Email">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                <!-- Add-ons details section -->
                <!-- <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">Add-ons</div>
                            </div> -->
                <!-- add Add-ons details -->
                <!-- <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="d-flex flex-row">
                                            <li class="theme-box-shadow theme-border-radius p-3 border me-3 d-flex flex-row">
                                                <v-img src="../assets/meal.png" width="30px" class="img-fluid me-2"
                                                        title="meal" alt="meal"></v-img>
                                                       <p class="my-0 text-primary text-center">Add Meals</p> 
                                            </li>
                                            <li class="theme-box-shadow theme-border-radius p-3 border me-3 d-flex flex-row">
                                                    <v-img src="../assets/baggage.png" width="30px" class="img-fluid me-2"></v-img>
                                                    <p class="my-0 text-primary">Add Baggage</p>
                                            </li>
                                            <li class="theme-box-shadow theme-border-radius p-3 border d-flex flex-row">
                                             
                                                        <v-img src="../assets/seat.png" width="30px" class="img-fluid me-2"></v-img>
                                                        <p class="my-0 text-primary">Seat</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            
                <div class="row">
                  <div class="col-12 col-md-12">
                    <!-- Acknowledgement details section -->
                    <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
                      <div class="fw-bold">{{ $t("flightInfoContent.contents.reserveYourSeat") }}</div>
                    </div>
                    <!-- Acknowledgement details -->
                    <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                     
                          <div class="form-check mb-3 border-bottom">
                            <div>
                              <p><v-icon style="color: #002D5B;">mdi-check-bold</v-icon><span class="ms-1 font-medium">{{ $t("flightInfoContent.contents.text1") }}</span></p>
                              <p><v-icon style="color: #002D5B;">mdi-check-bold</v-icon><span class="ms-1 font-medium">{{ $t("flightInfoContent.contents.text2") }}</span></p>
                              <p><v-icon style="color: #002D5B;">mdi-check-bold</v-icon><span class="ms-1 font-medium">{{ $t("flightInfoContent.contents.text3") }}</span></p>
                            </div>
                            <!-- <input v-model="formCheckBox" class="form-check-input" type="checkbox" value=""
                              id="flexCheckChecked" checked>
                            <label class="form-check-label font-small mt-1" for="flexCheckChecked">
                              {{ $t("flightInfoContent.contents.ackInfo") }} <a href="#">{{
                                $t("flightInfoContent.contents.privacyPolicy") }}</a>, the <a href="#">{{
                                  $t("flightInfoContent.contents.termsCondition") }}</a>
                              of {{ portal_id }}
                            </label>
                            <p v-if="!formCheckBox && checkForm" class="w-50 m-0 f-size-12 fw-400"
                              style="color: #b00020">{{ $t("flightInfoContent.contents.agreeText") }} </p> -->
                          </div>
                          <!-- <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-effect btn-book px-5 " @click="submitform">Select</button>
                            </div> -->


                          <!-- <v-btn
                                                    width="25%"
                                                    height="40px"
                                                    class="continue-btn"
                                                    type="submit"
                                                    style="
                                                        color: white;
                                                        text-transform: capitalize;
                                                        background-color: #212529;
                                                    " @click="submitform"
                                                    >Continue</v-btn> -->
                       
                    </div>
                  </div>
                  <div class="d-flex justify-content-end d-none">
                            <button type="submit" class="btn btn-effect btn-book px-5 "  @click="submitform">Continue</button>
                            </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="fw-bold mb-3">{{ $t("flightInfoContent.contents.bookingDetails") }}</div>
                <div class="row mx-lg-0 mx-2">
                  <!-- flight info strip repetable -->
                  <div class="col-12 px-0" v-for="(data, index) of roundresult" :key="index">
                    <div class="theme-box-shadow theme-border-radius theme-bg-white bg-light p-3 mb-3">
                      <!-- repetable -->
                      <div class="row g-0">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <!-- <div v-if="areLogosequal(data.Depature)">
                                                              <div v-for="(logo,index) in sameLogoData" :key="index" :style="sameLogoData.length >= 2 ? 'width:50%' : ''">
                                                                <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                              </div>
                                                            </div> -->

                                <div v-if="areLogosequal(data.Depature)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoData" :key="index"
                                    :style="{ width: sameLogoData.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoData.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.departure")
                                    }} - {{ depdate(data.Depature) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName(data.fromTrip) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName(data.toTrip) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Depature[0].Departure.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{ data.Depature[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Depaturejourney.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Depaturejourney.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Depature[data.Depature.length-1].Arrival.AirportCode }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Depature[data.Depature.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- repetable -->
                      <div class="row g-0" v-if="data.Return">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <!-- <div v-if="areLogosequal1(data.Return)">
                                                                <div v-for="(logo,index) in sameLogoData1" :key="index" :style="sameLogoData1.length >= 2 ? 'width:50%' : ''">
                                                                  <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                                </div>
                                                            </div> -->
                                <div v-if="areLogosequal1(data.Return)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoData1" :key="index"
                                    :style="{ width: sameLogoData1.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoData1.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.return") }} -
                                    {{ depdate(data.Return) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName(data.toTrip) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName(data.fromTrip) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Return[0].Departure.AirportCode }}
                              </div>
                              <div class="font-medium theme-text-accent-one">{{ data.Return[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Returnjourney.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Returnjourney.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Return[data.Return.length-1].Arrival.AirportCode }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Return[data.Return.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-center">
                        <v-btn
                          style="width: 140px;height:37px;min-width: 0;min-height: 0;text-transform:none;letter-spacing:0;margin:0 auto;border-radius:5px;border:0.5px solid #464444;"
                          class="travel-btn btn-book" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop"
                          aria-controls="staticBackdrop">
                          {{ $t("flightInfoContent.contents.travelDetails") }}</v-btn>
                      </div>
                    </div>
                    <div class="drawer-section">
                      <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop"
                        aria-labelledby="staticBackdropLabel">
                        <div class="offcanvas-header">
                          <h5 class="offcanvas-title" id="staticBackdrop">{{
                            $t("flightInfoContent.contents.flightDetails") }}</h5>
                          <button type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                            color="white">
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>

                        <div class="offcanvas-body pt-0">
                          <div v-for="(flightdatas, index) of roundresult" :key="index">
                            {{ console.log(flightdatas, 'datasssss') }}
                            <v-card style="border:1px solid grey;" class="my-4">
                              <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">{{
                                  $t("flightInfoContent.contents.departure") }}</h6>
                                <span class="fw-bold ms-2" style="font-size:16px;"> {{ getdepName(flightdatas.fromTrip)
                                  }} - {{ getarrName(flightdatas.toTrip) }}</span>
                              </div>
                              <v-divider class="my-0"></v-divider>
                              <div v-for="(data, index) of flightdatas.Depature" :key="index">
                                <v-timeline truncate-line="both" class="ms-5 my-4">
                                  <v-timeline-item class="" size="x-small" justify="left">
                                    <template v-slot:opposite>
                                      <div>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight(data.Departure.Date)
                                        }}</span>
                                        <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                          getflightdep(data)
                                          }}</span>
                                      </div>
                                    </template>
                                    <div>
                                      <span class="fw-600" style="font-size:16px;">{{ data.Departure.AirportCode }}
                                      </span><br />
                                      <p class="m-0" style="font-size:12px;">{{ data.Departure.AirportName }}</p>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="my-2" justify="left">
                                    <template v-slot:icon>
                                      <v-avatar :image="data.MarketingCarrier.logo"
                                        style="background:white;"></v-avatar>
                                    </template>
                                    <template v-slot:opposite>
                                      <div>
                                        <p class="f-size-14 airline-name m-0">
                                          {{ data.MarketingCarrier.Name }}

                                          <br />
                                          <span class="f-size-12 airline-id">
                                            {{ data.MarketingCarrier.AirlineID }}
                                            -
                                            {{ data.MarketingCarrier.FlightNumber }}
                                          </span>
                                        </p>
                                      </div>
                                    </template>
                                    <div>
                                      <p class="m-0" style="font-size:14px;">{{ data.FlightDetail.FlightDuration.Value }}
                                      </p>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="my-2" size="x-small">
                                    <template v-slot:opposite>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight1(data.Arrival.Date)
                                      }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightarr(data)
                                        }}</span>
                                    </template>
                                    <div>
                                      <span class="fw-600" style="font-size:16px;">{{ data.Arrival.AirportCode }}
                                      </span><br />
                                      <p class="m-0" style="font-size:12px;">{{ data.Arrival.AirportName }}</p>
                                    </div>
                                  </v-timeline-item>

                                </v-timeline>

                                <v-card v-if="flightdatas.Depature.length - 1 !== index"
                                  style="width:65%;position:relative;left:15%;background-color:#002d5b;color:white;"
                                  class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                      <v-img src="../assets/sandClock.png" width="15px" syle="padding-top:5px;"></v-img>
                                    </div>
                                    <div v-if="flightdatas.Return">
                                      {{ console.log(flightdatas,'asdasdasdflightdatsdasddatas') }}
                                      <p class="m-0 ms-2" style="font-size:14px;">{{ flightdatas.layoverTimes1[index] }} -
                                        {{
                                          $t("flightInfoContent.contents.layoverTime") }}</p>
                                    </div>
                                    <div v-if="!flightdatas.Return">
                                      <p class="m-0 ms-2" style="font-size:14px;">{{ flightdatas.layoverTimes[index] }} -
                                        {{
                                          $t("flightInfoContent.contents.layoverTime") }}</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                            </v-card>

                            <v-card v-if="flightdatas.Return" style="border:1px solid grey;" class="my-4">
                              <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">{{ $t("flightInfoContent.contents.arrival")
                                  }}</h6>
                                <span class="fw-bold ms-2" style="font-size:16px;"> {{ getdepName(flightdatas.toTrip) }}
                                  - {{
                                  getarrName(flightdatas.fromTrip) }}</span>
                              </div>
                              <v-divider class="my-0"></v-divider>
                              <div v-for="(data, index) of flightdatas.Return" :key="index">
                                <v-timeline truncate-line="both" class="ms-5 my-4">
                                  <v-timeline-item class="my-2" size="x-small" justify="left">
                                    <template v-slot:opposite>
                                      <div>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight(data.Departure.Date)
                                        }}</span>
                                        <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                          getflightdep(data)
                                          }}</span>
                                      </div>
                                    </template>
                                    <div>
                                      <span class="fw-500" style="font-size:16px;">{{ data.Departure.AirportCode }}
                                      </span><br />
                                      <p class="m-0" style="font-size:12px;">{{ data.Departure.AirportName }}</p>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="my-2" justify="left">
                                    <template v-slot:icon>
                                      <v-avatar :image="data.MarketingCarrier.logo"
                                        style="background:white;"></v-avatar>
                                    </template>
                                    <template v-slot:opposite>
                                      <div>
                                        <p class="f-size-14 airline-name m-0">
                                          {{ data.MarketingCarrier.Name }}

                                          <br />
                                          <span class="f-size-12 airline-id">
                                            {{ data.MarketingCarrier.AirlineID }}
                                            -
                                            {{ data.MarketingCarrier.FlightNumber }}
                                          </span>
                                        </p>
                                      </div>
                                    </template>
                                    <div>
                                      <p class="m-0" style="font-size:14px;">{{ data.FlightDetail.FlightDuration.Value }}
                                      </p>
                                    </div>
                                  </v-timeline-item>

                                  <v-timeline-item class="my-2" size="x-small">
                                    <template v-slot:opposite>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight1(data.Arrival.Date)
                                      }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightarr(data)
                                        }}</span>
                                    </template>
                                    <div>
                                      <span class="fw-500" style="font-size:16px;">{{ data.Arrival.AirportCode }}
                                      </span><br />
                                      <p class="m-0" style="font-size:12px;">{{ data.Arrival.AirportName }}</p>
                                    </div>
                                  </v-timeline-item>

                                </v-timeline>

                                <v-card v-if="flightdatas.Return.length - 1 !== index"
                                  style="width:65%;position:relative;left:15%;background-color:#002d5b;color:white;"
                                  class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                      <v-img src="../assets/sandClock.png" width="15px"
                                        style="padding-top:5px;"></v-img>
                                    </div>
                                    <div>
                                      <p class="m-0 ms-2" style="font-size:14px;">{{ flightdatas.layoverTimes2[index] }} -
                                        {{
                                          $t("flightInfoContent.contents.layoverTime") }}</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                            </v-card>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0" v-for="(data, index) of multiresult" :key="index">
                    <div class="theme-box-shadow theme-border-radius theme-bg-white bg-light p-3 mb-3">
                      <!-- repetable -->
                      <div class="row g-0">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <div v-if="areLogosequalMulti(data.Flight1)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoDataMulti" :key="index"
                                    :style="{ width: sameLogoDataMulti.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoDataMulti.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.departure")
                                    }} - {{
                                    depdate(data.Flight1) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName1(data.muticityData[0]) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName1(data.muticityData[0]) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Flight1[0].Departure.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{ data.Flight1[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight1JourneyTime.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight1JourneyTime.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Flight1[data.Flight1.length-1].Arrival.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Flight1[data.Flight1.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <div v-if="areLogosequalMulti1(data.Flight2)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoDataMulti1" :key="index"
                                    :style="{ width: sameLogoDataMulti1.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoDataMulti1.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.departure")
                                    }} - {{
                                    depdate(data.Flight2) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName1(data.muticityData[1]) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName1(data.muticityData[1]) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Flight2[0].Departure.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{ data.Flight2[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight2JourneyTime.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight2JourneyTime.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Flight2[data.Flight2.length-1].Arrival.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Flight2[data.Flight2.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="data.Flight3" class="row g-0">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <div v-if="areLogosequalMulti2(data.Flight3)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoDataMulti2" :key="index"
                                    :style="{ width: sameLogoDataMulti2.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoDataMulti2.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.departure")
                                    }} - {{
                                    depdate(data.Flight3) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName1(data.muticityData[2]) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName1(data.muticityData[2]) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Flight3[0].Departure.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{ data.Flight3[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight3JourneyTime.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight3JourneyTime.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Flight3[data.Flight3.length-1].Arrival.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Flight3[data.Flight3.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="data.Flight4" class="row g-0">
                        <div class="col-12 pb-3 mb-3 border-bottom">
                          <div class="row">
                            <div class="col-12 col-md-12 mb-2">
                              <div class="d-flex">
                                <div v-if="areLogosequalMulti3(data.Flight4)"
                                  class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                  <div v-for="(logo, index) in sameLogoDataMulti3" :key="index"
                                    :style="{ width: sameLogoDataMulti3.length >= 2 ? '50%' : '100%' }">
                                    <v-img :src="logo" :width="sameLogoDataMulti3.length >= 2 ? '40px' : 'auto'"
                                      class="gx-1"></v-img>
                                  </div>
                                </div>
                                <div class="d-flex flex-column ms-2">
                                  <span class="font-medium fw-bold mb-0">{{ $t("flightInfoContent.contents.departure")
                                    }} - {{
                                    depdate(data.Flight4) }}</span>
                                  <span class="font-medium mb-0 theme-text-accent-one">
                                    {{ getdepName1(data.muticityData[3]) }}<i class="bi bi-arrow-right"></i> {{
                                    getarrName1(data.muticityData[3]) }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-start text-center">
                              <div class="font-medium fw-bold text-uppercase">{{ data.Flight4[0].Departure.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{ data.Flight4[0].Departure.Time }}
                              </div>
                            </div>
                            <div class=" col-6 col-md-6 text-center align-self-center">
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight4JourneyTime.Time }}
                              </div>
                              <div class="font-extra-small theme-text-accent-one">{{ data.Flight4JourneyTime.Stops }} {{
                                $t("flightInfoContent.contents.stop") }}
                              </div>
                            </div>
                            <div class="col-3 col-md-3 text-lg-end text-center">
                              <div class="font-medium fw-bold text-uppercase">{{
                                data.Flight4[data.Flight4.length-1].Arrival.AirportCode
                                }}</div>
                              <div class="font-medium theme-text-accent-one">{{
                                data.Flight4[data.Flight4.length-1].Arrival.Time }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-center">
                        <v-btn
                          style="width: 140px;height:37px;min-width: 0;min-height: 0;text-transform:none;letter-spacing:0;margin:0 auto;border-radius:5px;border:0.5px solid #464444;"
                          class=" travel-btn btn-book" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop"
                          aria-controls="staticBackdrop">
                          Travel Details</v-btn>
                      </div>
                    </div>

                    <div class="drawer-section">
                      <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop"
                        aria-labelledby="staticBackdropLabel">
                        <div class="offcanvas-header">
                          <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">{{
                            $t("flightInfoContent.contents.flightDetails") }}
                          </h5>
                          <button type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                            color="white">
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>

                        <div class="offcanvas-body pt-0">
                          <v-card style="border:1px solid grey;" class="my-4">
                            <div class="d-flex flex-row align-items-center">
                              <h6 class="p-2 mb-0" style="font-size:14px;">{{ $t("flightInfoContent.contents.flight")
                                }}1</h6>
                              <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[0]) }} - {{
                                getarrNameMulti(data.muticityData[0]) }}</span>
                            </div>

                            <v-divider class="my-0"></v-divider>
                            <div v-for="(data1, index) of data.Flight1" :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight(data1.Departure.Date)
                                        }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightdep(data1)
                                        }}</span>
                                    </div>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                        {{ data1.MarketingCarrier.Name }}

                                        <br />
                                        <span class="f-size-12 airline-id">
                                          {{ data1.MarketingCarrier.AirlineID }}
                                          -
                                          {{ data1.MarketingCarrier.FlightNumber }}
                                        </span>
                                      </p>
                                    </div>
                                  </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{ data1.FlightDetail.FlightDuration.Value }}
                                    </p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                      getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Arrival.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                              <v-card v-if="data.Flight1.length - 1 !== index"
                                style="width:55%;position:relative;left:15%;background-color:#002d5b;color:white;"
                                class="p-1">
                                <div class="d-flex justify-center">
                                  <div class="">
                                    <v-img src="../assets/sandClock.png" width="15px" style="padding-top:5px;"></v-img>
                                  </div>
                                  <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{ data.layoverTimes1[index] }} - {{
                                      $t("flightInfoContent.contents.layoverTime") }}</p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-card>
                          <v-card style="border:1px solid grey;" class="my-4">
                            <div class="d-flex flex-row align-items-center">
                              <h6 class="p-2 mb-0" style="font-size:14px;">{{ $t("flightInfoContent.contents.flight")
                                }}2</h6>
                              <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[1]) }} - {{
                                getarrNameMulti(data.muticityData[1]) }}</span>
                            </div>
                            <v-divider class="my-0"></v-divider>
                            <div v-for="(data1, index) of data.Flight2" :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight(data1.Departure.Date)
                                        }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightdep(data1)
                                        }}</span>
                                    </div>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                        {{ data1.MarketingCarrier.Name }}

                                        <br />
                                        <span class="f-size-12 airline-id">
                                          {{ data1.MarketingCarrier.AirlineID }}
                                          -
                                          {{ data1.MarketingCarrier.FlightNumber }}
                                        </span>
                                      </p>
                                    </div>
                                  </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{ data1.FlightDetail.FlightDuration.Value }}
                                    </p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                      getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Arrival.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                              <v-card v-if="data.Flight2.length - 1 !== index"
                                style="width:55%;position:relative;left:21%;background-color:#002d5b;color:white;"
                                class="p-1">
                                <div class="d-flex justify-center">
                                  <div class="">
                                    <v-img src="../assets/sandClock.png" width="15px" style="padding-top:5px;"></v-img>
                                  </div>
                                  <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{ data.layoverTimes2[index] }} - {{
                                      $t("flightInfoContent.contents.layoverTime") }}</p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-card>
                          <v-card v-if="data.Flight3" style="border:1px solid grey;" class="my-4">
                            <div class="d-flex flex-row align-items-center">
                              <h6 class="p-2 mb-0" style="font-size:14px;">{{ $t("flightInfoContent.contents.flight")
                                }}3</h6>
                              <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[2]) }} - {{
                                getarrNameMulti(data.muticityData[2]) }}</span>
                            </div>
                            <v-divider class="my-0"></v-divider>
                            <div v-for="(data1, index) of data.Flight3" :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight(data1.Departure.Date)
                                        }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightdep(data1)
                                        }}</span>
                                    </div>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                        {{ data1.MarketingCarrier.Name }}

                                        <br />
                                        <span class="f-size-12 airline-id">
                                          {{ data1.MarketingCarrier.AirlineID }}
                                          -
                                          {{ data1.MarketingCarrier.FlightNumber }}
                                        </span>
                                      </p>
                                    </div>
                                  </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{ data1.FlightDetail.FlightDuration.Value }}
                                    </p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                      getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }}
                                    </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Arrival.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                              <v-card v-if="data.Flight3.length - 1 !== index"
                                style="width:55%;position:relative;left:15%;background-color:#002d5b;color:white;"
                                class="p-1">
                                <div class="d-flex justify-center">
                                  <div class="">
                                    <v-img src="../assets/sandClock.png" width="15px" style="padding-top:5px;"></v-img>
                                  </div>
                                  <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{ data.layoverTimes3[index] }} - {{
                                      $t("flightInfoContent.contents.layoverTime") }}</p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-card>
                          <v-card v-if="data.Flight4" style="border:1px solid grey;" class="my-4">
                            <div class="d-flex flex-row align-items-center">
                              <h6 class="p-2 mb-0" style="font-size:14px;">{{ $t("flightInfoContent.contents.flight")
                                }}4</h6>
                              <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[3]) }} - {{
                                getarrNameMulti(data.muticityData[3]) }}</span>
                            </div>
                            <v-divider class="my-0"></v-divider>
                            <div v-for="(data1, index) of data.Flight4" :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                        getdatedFlight(data1.Departure.Date)
                                        }}</span>
                                      <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                        getflightdep(data1)
                                        }}</span>
                                    </div>
                                  </template>
                                  <div>
                                    <span class="fw-600 f-size-16">{{ data1.Departure.AirportCode }} </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-16 airline-name m-0">
                                        {{ data1.MarketingCarrier.Name }}

                                        <br />
                                        <span class="f-size-12 airline-id">
                                          {{ data1.MarketingCarrier.AirlineID }}
                                          -
                                          {{ data1.MarketingCarrier.FlightNumber }}
                                        </span>
                                      </p>
                                    </div>
                                  </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{ data1.FlightDetail.FlightDuration.Value }}
                                    </p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{
                                      getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600 f-size-16">{{ data1.Arrival.AirportCode }} </span><br />
                                    <p class="m-0" style="font-size:12px;">{{ data1.Arrival.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                              <v-card v-if="data.Flight4.length - 1 !== index"
                                style="width:55%;position:relative;left:15%;background-color:#002d5b;color:white;"
                                class="p-1">
                                <div class="d-flex justify-center">
                                  <div class="">
                                    <v-img src="../assets/sandClock.png" width="15px" style="padding-top:5px;"></v-img>
                                  </div>
                                  <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{ data.layoverTimes4[index] }} - {{
                                      $t("flightInfoContent.contents.layoverTime") }} </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-card>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="fw-bold mb-3">{{ $t("fareDetContent.fareDetails") }}</div>
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="theme-box-shadow theme-border-radius bg-light">
                      <ul class="p-0">
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex justify-content-between">
                            <span class="font-medium " style="font-weight:500;">
                              {{ $t("fareDetContent.passenger") }}</span>
                          </div>
                        </li>
                        <!-- gst charge fare -->
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex flex-column">
                            <span v-if="adultLength > 0">{{ adultLength }} x {{ $t("fareDetContent.adult") }}</span><br
                              v-if="childLength > 0" />
                            <span v-if="childLength > 0">{{ childLength }} x {{ $t("fareDetContent.child") }}</span><br
                              v-if="infLength > 0" />
                            <span v-if="infLength > 0">{{ infLength }} x {{ $t("fareDetContent.infrant") }}</span>
                            <!-- </span> -->
                          </div>
                        </li>
                        <!-- grand total charge fare -->
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex justify-content-between">
                            <span class="" style="font-weight:600">
                              {{ $t("fareDetContent.grandTotal") }}</span>
                            <span class="">
                              <span class="fw-bold"> {{ currency }} {{ totalAmountpassenger }}</span>
                            </span>
                          </div>
                        </li>
                        <li class="border-bottom p-3 font-medium">
                          <a class="d-flex align-center justify-content-between theme-text-accent-one"
                            data-bs-toggle="collapse" href="#faresummary" role="button" aria-expanded="false"
                            aria-controls="faresummary">
                            <span class="font-medium fw-bold">{{ $t("fareDetContent.fareSummary") }}</span>
                            <span>
                              <i class="bi bi-plus-circle-fill theme-text-accent-two"></i>
                            </span>
                          </a>

                          <div class="collapse" id="faresummary">
                            <div v-for="(res, index) of getDataResult" :key="index"
                              class="bordeer-top-0 border border-2 border-top-0">
                              <div class="comDetailSection">
                                <div class="paxpricesection">
                                  <table class="table table-borderless mb-0">
                                    <thead>
                                      <tr class="f-size-10 fw-500">
                                        <td style="background: #dee2e6">{{ $t("fareDetContent.pax") }}</td>
                                        <td style="background: #dee2e6">{{ $t("fareDetContent.base") }}</td>
                                        <td style="background: #dee2e6">{{ $t("fareDetContent.tax") }}</td>
                                        <td style="background: #dee2e6">{{ $t("fareDetContent.perPerson") }}</td>
                                      </tr>
                                    </thead>
                                    <tbody class="f-size-10 fw-400">
                                      {{ console.log(res, "priceDetailllll...1") }}
                                      <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                                        {{ console.log(priceDetail, "priceDetailllll...2") }}
                                        <tr
                                          v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                          {{ console.log(priceDetail, "priceDetailllll...3") }}
                                          <td>{{ priceDetail.pax_type }}</td>
                                          <td>{{ priceDetail.baseprice }}</td>
                                          <td>{{ priceDetail.taxprice }}</td>
                                          <td>{{ getperpersonPrice(priceDetail) }}</td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex justify-content-between">
                            <span class="font-medium">{{ $t("fareDetContent.basePrice") }}<br />
                              {{ $t("fareDetContent.taxesFees") }}</span>
                            <div class="d-flex flex-column">
                              <div class="row">
                                <div class="col-4 pe-0">{{ currency }}</div>
                                <div class="col-8 text-end ps-2">{{ totalbasePassanger }}</div>
                              </div>
                              <div class="row">
                                <div class="col-4 pe-0">{{ currency }}</div>
                                <div class="col-8 text-end">{{ totalTaxPassanger }}</div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="border-bottom font-medium">
                          <div class="d-flex text-start">
                            <a class="baggage border-none p-3"
                              style="font-size:13px;font-weight:600;color: rgb(13, 110, 253); text-decoration: underline;">
                              <span data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop4"
                                aria-controls="staticBackdrop">{{
                                  $t("baggageContent.heading") }}</span>
                            </a>
                          </div>
                          <div class="drawer-section">
                            <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1"
                              id="staticBackdrop4" aria-labelledby="staticBackdropLabel" >
                              <div class="offcanvas-header"
                                style="background: #5293cb !important;color: #fff !important;">
                                <h6 class="offcanvas-title" id="staticBackdrop4">{{ $t("baggageContent.heading") }}</h6>
                                <button @click="closeBaggageRules" type="button" class="text-reset"
                                  data-bs-dismiss="offcanvas" aria-label="Close" color="white">
                                  <v-icon>mdi-close</v-icon>
                                </button>
                              </div>
                              <div class="offcanvas-body pt-0" style="overflow-x:hidden;">
                                <v-card class="mt-4">
                                  <v-tabs v-model="tab" align-tabs="center" stacked>
                                    <v-tab value="tab-1" class="m-0">
                                      <v-icon>mdi-bag-checked</v-icon>
                                      {{ $t("baggageContent.baggage") }}
                                    </v-tab>
                                    <v-tab value="tab-2">
                                      <v-icon>mdi-book-cancel-outline</v-icon>
                                      {{ $t("baggageContent.cancelRules") }}
                                    </v-tab>
                                  </v-tabs>

                                  <v-card-text class="pt-0">
                                    <keep-alive>
                                      <v-window v-model="tab">
                                        <div v-if="tab === 'tab-1'">
                                          <h6 class="mt-1">{{ $t("baggageContent.baggageRules") }}:</h6>
                                          <div class="p-2 rounded" v-for="(data, index) of roundresult" :key="index">
                                            <div>
                                              <div style="font-size: 15px; font-weight: 500">
                                                {{ data.fromTrip }} - {{ data.toTrip }}
                                              </div>
                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div>
                                  
                                </div> -->
                                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  ">

                                                  <span> {{ $t("baggageContent.checked") }}:</span>
                                                  <span v-if="data.depBaggage" class="ms-1">
                                                    {{ data.depBaggage.PieceAllowance.TotalQuantity || 0 }}

                                                    {{ data.depBaggage.PieceAllowance.TotalQuantity > 1 ?
                                                      this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}
                                                  </span>
                                                  <span v-if="data.BaggageData" class="ms-1">
                                                    {{ data.BaggageData.PieceAllowance.TotalQuantity || 0 }}

                                                    {{ data.BaggageData.PieceAllowance.TotalQuantity > 1 ?
                                                      this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}
                                                  </span>
                                                  <v-icon color="gray" style="color: gray !important">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                  
                                </div> -->
                                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  ">
                                                  <span>{{ $t("baggageContent.carryOn") }}:</span>
                                                  <span v-if="data.depBaggage" class="ms-1">
                                                    {{
                                                      data.depBaggage.PieceAllowance
                                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + " "
                                                    +
                                                    $t("baggageContent.piece")
                                                    }}
                                                  </span>
                                                  <span v-if="data.BaggageData" class="ms-1">
                                                    {{
                                                      data.BaggageData.PieceAllowance
                                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + " "
                                                    +
                                                    $t("baggageContent.piece")
                                                    }}
                                                  </span>
                                                  <v-icon color="gray" style="color: gray !important">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                            <hr v-if="data.Return" />
                                            <div v-if="data.Return" class="mt-2">
                                              <div class="mt-1" style="font-size: 15px; font-weight: 500">
                                                {{ data.toTrip }} - {{ data.fromTrip }}
                                              </div>
                                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                              <div class="d-flex ms-2 mt-1">
                                                <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  ">
                                                  <span> {{ $t("baggageContent.checked") }}:</span>
                                                  <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                                  <span class="ms-1">
                                                    {{
                                                      data.arrBaggage.PieceAllowance
                                                        .TotalQuantity || 0
                                                    }}
                                                    {{
                                                      data.arrBaggage.PieceAllowance
                                                        .TotalQuantity > 1
                                                        ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                    }}
                                                  </span>
                                                  <v-icon color="gray" style="color:gray !important;">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                  
                                </div> -->
                                                <div style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  ">
                                                  <span>{{ $t("baggageContent.carryOn") }}:</span>
                                                  <span class="ms-1">
                                                    {{
                                                      data.arrBaggage.PieceAllowance
                                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + " "
                                                    +
                                                    $t("baggageContent.piece")
                                                    }}
                                                  </span>
                                                  <v-icon color="gray" style="color: gray !important">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="multiresult">
                                            <div class="border p-2 rounded" v-for="(data, index) of multiresult"
                                              :key="index">
                                              <div>
                                                <div style="font-size: 15px; font-weight: 500">
                                                  {{ getdepNameMulti(data.muticityData[0]) }} -
                                                  {{ getarrNameMulti(data.muticityData[0]) }}
                                                </div>
                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span> {{ $t("baggageContent.checked") }}: </span>
                                                    <span class="mt-1">
                                                      {{ data.BaggageData1.PieceAllowance.TotalQuantity || 0 }}
                                                      {{ data.BaggageData1.PieceAllowance.TotalQuantity > 1 ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}</span>
                                                    <v-icon color="gray" style="color: gray !important">mdi-bag-suitcase</v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                    <span class="">{{ data.BaggageData1.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + "" + $t("baggageContent.piece")}}</span>
                                                    <v-icon color="gray" style="color: gray !important">mdi-bag-checked</v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr v-if="data.Flight2" />
                                              <div v-if="data.Flight2" class="mt-2">
                                                <div class="mt-1" style="font-size: 15px; font-weight: 500">
                                                  {{ getdepNameMulti(data.muticityData[1]) }} -
                                                  {{ getarrNameMulti(data.muticityData[1]) }}
                                                </div>
                                                <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400; margin-left: 5px;">

                                                    <span> {{ $t("baggageContent.checked") }}: </span>
                                                    <span class="mt-1">
                                                      {{data.BaggageData2.PieceAllowance.TotalQuantity || 0}}
                                                      {{ data.BaggageData2.PieceAllowance.TotalQuantity > 1 ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}</span>
                                                    <v-icon color="gray" style="color: gray !important">mdi-bag-suitcase</v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                    <span class="">{{ data.BaggageData2.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + "" + $t("baggageContent.piece")}}</span>
                                                    <v-icon color="gray" style="color: gray !important">mdi-bag-checked</v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr v-if="data.Flight3" />
                                              <div v-if="data.Flight3" class="mt-2">
                                                <div class="mt-1" style="font-size: 15px; font-weight: 500">
                                                  {{ getdepNameMulti(data.muticityData[2]) }} -
                                                  {{ getarrNameMulti(data.muticityData[2]) }}
                                                </div>
                                                <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span> {{ $t("baggageContent.checked") }}: </span>
                                                    <span class="mt-1">
                                                      {{ data.BaggageData3.PieceAllowance.TotalQuantity || 0 }}{{ data.BaggageData3.PieceAllowance.TotalQuantity > 1? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}</span>
                                                    <v-icon color="gray" style="color: gray !important">
                                                      mdi-bag-suitcase
                                                    </v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                    <span class="">{{ data.BaggageData3.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + "" + $t("baggageContent.piece")}}</span>
                                                    <v-icon color="gray" style="color: gray !important">
                                                      mdi-bag-checked
                                                    </v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr v-if="data.Flight4" />
                                              <div v-if="data.Flight4" class="mt-2">
                                                <div class="mt-1" style="font-size: 15px; font-weight: 500">
                                                  {{ getdepNameMulti(data.muticityData[3]) }} -
                                                  {{ getarrNameMulti(data.muticityData[3]) }}
                                                </div>
                                                <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span> {{ $t("baggageContent.checked") }}: </span>
                                                    <span class="mt-1">
                                                      {{ data.BaggageData4.PieceAllowance.TotalQuantity || 0 }}{{ data.BaggageData4.PieceAllowance.TotalQuantity > 1 ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')}}</span>
                                                    <v-icon color="gray" style="color: gray !important">
                                                      mdi-bag-suitcase
                                                    </v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <div style="font-size: 14px;font-weight: 400;margin-left: 5px;">
                                                    <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                    <span class="">{{ data.BaggageData4.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageContent.piece") : 1 + "" + $t("baggageContent.piece")}}</span>
                                                    <v-icon color="gray" style="color: gray !important">
                                                      mdi-bag-checked
                                                    </v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-if="tab === 'tab-2'">
                                          <h6 class="mt-2">{{ $t("baggageContent.cancelRules") }}:</h6>
                                          <div class="p-2 cancellationRules">
                                            <div v-html="fareRulesContent"></div>
                                            <p class="my-1">{{ $t("baggageContent.errMsg") }}</p>
                                          </div>
                                        </div>
                                      </v-window>
                                    </keep-alive>
                                  </v-card-text>
                                </v-card>
                              </div>
                            </div>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="display:none !important;">
                <div class="col-12">
                  <div class="theme-box-shadow theme-border-radius bg-light p-3 font-small">
                    Cancellation &amp;
                    Date change
                    charges <a href="#" class="">More</a></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-9">
                  <div class="d-flex justify-content-end ">
                            <button type="submit" class="btn btn-effect btn-book px-5 "  @click="submitform">Continue</button>
                            </div>
                  <!-- Acknowledgement details section -->
                  <div class="d-flex d-none justify-content-between align-items-start mb-3">
                    <div class="fw-bold">{{ $t("flightInfoContent.contents.acknowledgement") }}</div>
                  </div>
                  <!-- Acknowledgement details -->
                  <div class="d-none theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                    <div class="row">
                      <div class="col-12">


                      

                        <!-- <div class="form-check mb-3">
                          <input v-model="formCheckBox" class="form-check-input" type="checkbox" value=""
                            id="flexCheckChecked" checked>
                          <label class="form-check-label font-small mt-1" for="flexCheckChecked">
                            {{ $t("flightInfoContent.contents.ackInfo") }} <a href="#">{{
                              $t("flightInfoContent.contents.privacyPolicy") }}</a>, the <a href="#">{{
                                $t("flightInfoContent.contents.termsCondition") }}</a>
                            of {{ portal_id }}
                          </label>
                          <p v-if="!formCheckBox && checkForm" class="w-50 m-0 f-size-12 fw-400" style="color: #b00020">
                            {{
                              $t("flightInfoContent.contents.agreeText") }} </p>
                        </div> -->
                        <button type="submit" class="btn btn-effect btn-book px-5">{{
                          $t("flightInfoContent.contents.continue")
                          }}</button>

                        <!-- <v-btn
                                                    width="25%"
                                                    height="40px"
                                                    class="continue-btn"
                                                    type="submit"
                                                    style="
                                                        color: white;
                                                        text-transform: capitalize;
                                                        background-color: #212529;
                                                    " @click="submitform"
                                                    >Continue</v-btn> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>

          <!-- button section -->


        </div>
      </div>

    </v-form>
  </div>
  <!-- <div v-if="pageloader">
      <pageloader></pageloader>
  </div> -->

  <div v-if="pageloader">
    <pageLoader></pageLoader>
  </div>

</template>
<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import airLogo from "@/airlinesLogo.json";
// import checkOutJson from "@/checkoutPage.json";
// import jsonData from "@/jsondata.json";
// import oneway from "@/onewayJson.json";
import axios from "axios";
import country1 from "@/coundtrycode.json";
// import dataLoder from "@/components/dataLoder.vue";

import pageLoader from "@/components/pageLoader.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    // headerCom,
    // footerCom,
    // travellerDetail,
    // VueDatePicker,
    // pageloader,
    // dataLoder,
    pageLoader,
  },
  data() {
    const monthListAll = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1); // Use any year, here I used 2000
      const monthName = date
        .toLocaleString("default", { month: "short" })
        .substring(0, 3);
      return monthName;
    });

    return {
      dataLoder: false,
      shoppingid: "",
      selectId: "",
      pointOfSale: "",
      tab: null,
      fareRulesContent: "",
      sameLogoData: [],
      sameLogoData1: [],
      sameLogoDataMulti: [],
      sameLogoDataMulti1: [],
      sameLogoDataMulti2: [],
      sameLogoDataMulti3: [],
      errorMessage: [],
      diffLogo: null,
      thirdLogo: null,
      sameLogo: null,
      sameLogo1: null,
      dropdown1: true,
      collapseData: null,
      checkOutPage: true,
      pageloader: true,
      // pageloader: false,
      dialog1: false,
      dialog2: false,
      airlogodata: [],
      portal: false,
      countrycode: [],
      portal_shopId: "",
      portal_offId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "23%",
      booking_Id: "",
      formCheckBox: false,
      checkForm: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight1: [],
      dobAdultError: false,

      submitClicked: false,
      // dobChildError: false,
      // dobInfrantError: false,

      dataLink: "",

      bookingviewApi: "",
      airLogoApi: "",

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      local_id: "",
      Localoneway_id: null,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
        (v) => (v.length > 1) || "Max 2 letters are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      segmentList: [],
      seatSelect: [],
      passSelect: 0,
      flightSelect: 0,
      indexData: 0,
      monthListOriginal: [...monthListAll],
      monthList: [...monthListAll],
      storeMonthList: [],
      fareRulesApi: "",
      portal_id: "",
      autocompleteApi: "",
      seatmapApi: "",
      totalSeat: [],
      totalData: [],
      totalpassanger: [],
    };
  },
  methods: {

    seatPassanger() {
      let $data = []; 

     if (this.roundresult.length > 0) {
       $data = this.roundresult;
      
       console.log(this.roundresult, "seatmapping");
     } else if (this.onewayresult.length > 0) {
       $data = this.onewayresult;
     } else if (this.multiresult.length > 0) {
       $data = this.multiresult;
     }
    
     let obj={
 adult: $data[0].Adult||0,
 chd:$data[0].Child || 0,
 inf:$data[0].Inf || 0
}
  const totalPassengers = 
    Number(obj.adult || 0) + 
    Number(obj.chd || 0) + 
    Number(obj.inf || 0);
console.log(totalPassengers,'totalPassengers')
  this.totalpassanger = [];

  for (let i = 0; i < Number(obj.adult || 0); i++) {
    this.totalpassanger.push({ seat_number: [], type: 'Adult',index:i });
  }

  for (let i = 0; i < Number(obj.chd || 0); i++) {
    this.totalpassanger.push({ seat_number: [], type: 'Child',index:i });
  }

  for (let i = 0; i < Number(obj.inf || 0); i++) {
    this.totalpassanger.push({ seat_number: [], type: 'Infrant',index:i });
  }


  console.log(this.totalpassanger, this.totalSeat.length, "this.totalpassanger");
}
,

    getseatselect() {
      let $event = [];
      this.seatSelect.forEach((v, i) => {
        // console.log(v, i, "keerthikannan123");
        this.seatSelect[i].forEach((c) => {
          // console.log(c,c.cabins.length, "vasanthaganesh");
          $event.push(c);
          //           if (c && Array.isArray(c.cabins) && c.cabins.length > 0) {
          //   $event.push(c);
          // }

        });
      });
      this.totalSeat = $event;
      // console.log(this.totalSeat, "this.totalSeat");
      //  let arrayData = []; // You can remove this line as it's not being used

      if (this.totalSeat.length > 0) {
        this.dataLoder = false
        this.totalSeat[0].cabins.forEach((c) => {
          // console.log(c, "ppppppppppppppppp");
          let arrayData = [];
          arrayData = c.columns;


          let data = 0;
          arrayData.forEach((a, l) => {
            console.log(a, "rowsrows");
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }
            c.seat_rows.forEach((d) => {
              console.log(d, a, '000000')
              if (d.seats[0].seat_number == a.column) {
                console.log(this.totalData, d, a.column, 'lololol')

                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }

                this.totalData[a.column].push(d);
              }
            });
            this.totalData[a.column].forEach((w) => {
              w.color = false;
              console.log(w, "dddwwwwdddd");
            });

          });
          console.log(arrayData, "arrayData");
        });
        console.log(this.totalData, "$data$data$data");
      }
    },


    selectdata(data, index) {
      console.log(data, index, '000000');
      // this.passSelect = 0;
      this.flightSelect = index
      this.indexData = index;
      this.totalData = [];
      let falData = false;
      if (this.totalSeat.length > 0) {
        this.totalSeat[index].cabins.forEach((c, k) => {
          c.seat_rows[k].color = falData[k];

          let arrayData = [];
          arrayData = c.columns;
          let data = 0;
          arrayData.forEach((a, l) => {
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }

            c.seat_rows.forEach((d) => {
              // console.log(d, "rowsrows");
              if (d.seats[d.seats.length - 1].seat_number == a.column) {
                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }
                this.totalData[a.column].push(d);
              }
            });

            // this.totalData[a.column].forEach((w) => {
            //   console.log(w, "dddwwwwdddd");
            // });
          });
        });
      }
    },
    seatSelectdata(datas, index1, index) {
      console.log(this.passSelect, this.flightSelect, datas, 'this.passSelect')

      if (datas.seats[0].occupied === 'false') {


        for (let key in this.totalData) {
          this.totalData[key].forEach(item => (item.color = false));
        }
        if (this.totalData[index] && this.totalData[index][index1]) {
          this.totalData[index][index1].color = true;
        }


        this.totalpassanger[this.passSelect].seat_number[this.flightSelect] =
          `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${index}` || "";

        if (this.totalSeat.length - 1 != this.flightSelect) {
          this.flightSelect = this.flightSelect + 1
          this.selectdata(this.totalSeat[this.flightSelect], this.flightSelect)
        }

      }


    },



    getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    closeBaggageRules() {
      this.tab = 'tab-1'
    },

    filterkey(evt) {
      let key = evt.key;
      if (/^[0-9]*$/.test(key)) {
        evt.preventDefault();
      }
    },
    getdepNameMulti(data) {
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },
    getdatedFlight($event) {
      return moment($event).format("MMM DD");
    },
    getdatedFlightMulti($event) {
      return moment($event[0].Date).format("MMM DD");
    },
    getdatedFlight1($event) {
      return moment($event).format("MMM DD");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    validateData() {
      this.errorMessage = [];
      let hasErrors = false;

      if (this.adultData.length !== this.adult) {
        this.errorMessage.push("Please add the required number of adults");
        hasErrors = true;
      }
      if (this.childData.length !== this.child) {
        this.errorMessage.push("Please add the required number of child");
        hasErrors = true;
      }
      if (this.infrantData.length !== this.inf) {
        this.errorMessage.push("Please add the required number of infants");
        hasErrors = true;
      }

      return hasErrors;
    },

    getsegment() {
      if (this.roundresult) {
        this.roundresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
          if (v.Return) {
            v.Return.forEach((e) => {
              this.segmentList.push(e);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }


      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

    inputHandle(data, index) {
      if (data.Type == "Adult") {
        if (data.dateDob.year.length < 4) {
          this.adultData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Child") {
        if (data.dateDob.year.length < 4) {
          this.childData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Infrant") {
        if (data.dateDob.year.length < 4) {
          this.infrantData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      }
    },

    inputChange(data, index) {
      // console.log(data,index,'data,indexdata,indexdata,index')

      const enteredYear = data.dateDob.year;

      if (data.Type == "Adult") {
        this.adultData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....1");
      } else if (data.Type == "Child") {
        this.childData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....2");
      } else if (data.Type == "Infrant") {
        this.infrantData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        let currentDate = moment(
          this.segmentList[this.segmentList.length - 1].Departure.Date
        ).format("YYYY-MMM-DD");
        let maximumDate = new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate() - 15
        );
        let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");

        console.log(currentDate, "currentDate...", maxMonth, "maxMonth...");

        if (enteredYear == new Date(maximumDate).getFullYear()) {
          const maxMonthIndex = this.monthListOriginal.indexOf(maxMonth);
          this.monthList = [...this.monthListOriginal];
          this.monthList.splice(maxMonthIndex + 1);
          console.log(this.monthList, "this.monthListthis.monthList....3");
        } else {
          this.monthList = [...this.monthListOriginal];
          console.log(this.monthList, "this.monthListthis.monthList.....4");
        }
      }
    },

    yearRules(Type, index) {
      return [
        (v) => !!v || "Please enter the Year",
        (v) => /^\d+$/.test(v) || "Please enter a valid Year",
        (v) => {
          const currentYear = new Date(
            this.segmentList[0].Departure.Date
          ).getFullYear();
          const enteredYear = parseInt(v, 10);

          let minYear, maxYear;

          if (Type === "Adult") {
            minYear = currentYear - 100;
            maxYear = currentYear - 12;

            if (v.length === 4) {
              console.log(enteredYear, "enteredYearenteredYear");
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.adultData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Child") {
            this.segmentList.forEach((v) => {
              if (this.segmentList.length - 1) {
                maxYear = new Date(v.Departure.Date).getFullYear();
              }
            });

            maxYear = currentYear - 2;
            minYear = maxYear - 10;

            console.log(minYear, 'minYearminYearminYear')


            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {

                this.childData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Infrant") {
            let currentDate = moment(
              this.segmentList[this.segmentList.length - 1].Departure.Date
            ).format("YYYY-MMM-DD");
            let maximumDate = new Date(
              new Date(currentDate).getFullYear(),
              new Date(currentDate).getMonth() - 1,
              new Date(currentDate).getDate()
            );
            let maxDate = moment(maximumDate, "YYYY-MMM-DD").format(
              "YYYY-MMM-DD"
            );

            maxYear = new Date(maxDate).getFullYear();
            minYear = maxYear - 2;

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.infrantData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          }
        },
      ];
    },

    monthRules(Type, index) {
      return [
        // () => (!!this.adultData[index].dateDob.year || !!this.childData[index].dateDob.year || !!this.infrantData[index].dateDob.year) || `Please enter the Year first`,
        () => {
          if (Type === "Adult") {
            if (!this.adultData[index].dateDob.year) {
              return (
                !!this.adultData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Child") {
            if (!this.childData[index].dateDob.year) {
              return (
                !!this.childData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Infrant") {
            if (!this.infrantData[index].dateDob.year) {
              return (
                !!this.infrantData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          }
        },

        (v) => !!v || `Please enter the Month`,
      ];
    },

    dateRules(data, index) {
      return [
        () => {
          if (data.Type === "Adult") {
            if (
              !this.adultData[index].dateDob.year &&
              !this.adultData[index].dateDob.month
            ) {
              return (
                (!!this.adultData[index].dateDob.year &&
                  !!this.adultData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Child") {
            if (
              !this.childData[index].dateDob.year &&
              !this.childData[index].dateDob.month
            ) {
              return (
                (!!this.childData[index].dateDob.year &&
                  !!this.childData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Infrant") {
            if (
              !this.infrantData[index].dateDob.year &&
              !this.infrantData[index].dateDob.month
            ) {
              return (
                (!!this.infrantData[index].dateDob.year &&
                  !!this.infrantData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          }
        },

        (v) => /^\d+$/.test(v) || "Please enter the date",
        (v) => (v >= 1 && v <= 31) || "Please enter a valid date",
        (v) => /^\d{2}$/.test(v) || 'format like "05"',

        (v) => {
          const dataDob =
            data.Type === "Adult"
              ? this.adultData[index].dateDob
              : data.Type === "Child"
                ? this.childData[index].dateDob
                : this.infrantData[index].dateDob;

          const year = dataDob.year;
          const month = dataDob.month;

          if (year && month) {
            console.log(year, month, "yearyearyearyear");
            const monthIndex = this.monthList.indexOf(month);
            console.log(monthIndex, "monthIndexmonthIndexmonthIndex");
            console.log(this.monthList, "monthListmonthListmonthList");
            const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate();
            console.log(lastDayOfMonth, "lastDayOfMonthlastDayOfMonth");
            return (
              (v >= 1 && v <= lastDayOfMonth) || `Invalid date for ${month}`
            );
          }

          return true;
        },

        (v) => {
          let currentDate = moment(
            this.segmentList[this.segmentList.length - 1].Departure.Date
          ).format("YYYY-MMM-DD");
          let maximumDate = new Date(
            new Date(currentDate).getFullYear(),
            new Date(currentDate).getMonth(),
            new Date(currentDate).getDate() - 15
          );
          let formatDate = moment(maximumDate, "YYYY-MMM-DD").format(
            "YYYY-MMM-DD"
          );
          let maxYear = new Date(formatDate).getFullYear();
          let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");
          let maxDate = new Date(formatDate).getDate();
          maxDate = maxDate.toString().padStart(2, "0");
          console.log(maxDate, "maxDatemaxDatemaxDate..111.");
          console.log(
            currentDate,
            "currentDate....",
            maximumDate,
            "maximumDatemaximumDatemaximumDate..."
          );

          if (data.Type === "Infrant") {
            let normalDate = v >= 1 && v <= 31;
            let validDate = v >= 1 && v <= maxDate;

            if (
              maxYear == data.dateDob.year &&
              maxMonth == data.dateDob.month
            ) {
              return (
                validDate ||
                `Allow travel before ${maxMonth},${maxDate} for infrants`
              );
            } else {
              return normalDate;
            }
          }
        },
      ];
    },

    tryAgain1() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....1");
      this.dialog1 = false;
      this.portalDataConvert();
    },
    tryAgain2() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....2");
      this.dialog2 = false;
     

      if (this.local_id) {
        this.$router.push({
          path: "/seatmap",
          query: { pass_uid: `${this.local_id}` },
        });

        setTimeout(() => {
          this.pageloader = true;
          this.dialog2 = true;
        }, 5000);
      } else {
        this.$router.push({
          path: "/seatmap",
          query: { pass_uid1: `${this.Localoneway_id}` },
        });

        setTimeout(() => {
          this.pageloader = true;
          this.dialog2 = true;
        }, 5000);
      }
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },
    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },
    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },


    areLogosequal(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoData.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoData.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData
    },
    areLogosequal1(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoData1.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoData1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData1
    },
    areLogosequalMulti(data) {
      // this.sameLogoDataMulti = []
      // console.log(this.sameLogoDataMulti,'sameLogoDataMultisameLogoDataMulti')

      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti
    },
    areLogosequalMulti1(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti1.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti1
    },
    areLogosequalMulti2(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti2.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti2.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti2,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti2
    },
    areLogosequalMulti3(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti3.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti3.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti3,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti3
    },


    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getdepName(data) {
      console.log(data, "getdepcode1");
      return data.split("(")[0];
    },
    // getdepNameCode(){
    //   return data.split("(")[1].split(")")[0]
    // },
    getdepName1(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getarrName(data) {
      return data.split("(")[0];
    },
    getarrName1(data) {
      return data.to;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, DD MMM");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
      console.log(data, 'dataaaaaa')
      return moment(data[data.length - 1].Arrival.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },

    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },


    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobChildError = false;
        } else {
          v.clicked1 = false;
          this.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          this.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      console.log(this.adultData, 'adultDataaaa')
      console.log(this.adultData.length, 'adultDataaaa......1')
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.slice(0, 1) < this.adult) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
            isYearValid: false,
          });
        }

        else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },
    addAdult() {
      console.log(this.adultData.length, 'adultData......2')
      if (this.adultData.length < this.adult) {
        this.adultData.push({
          fname: "",
          last: "",
          middle: "",
          gender: "Male",
          salutation: "Mr",
          dateDob: {
            year: null,
            month: null,
            date: null,
          },
          dateOfBirth: null,
          ffbnumber: false,
          TSAnumber: false,
          Apinumber: false,
          Type: "Adult",
          clicked1: false,
          dobAdultError: false,
          isYearValid: false,

        });
        this.errorMessage = ""
      }

    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.slice(0, 1) < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            isYearValid: false,
            // dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },
    addChild() {
      if (this.childData.length < this.child) {
        this.childData.push({
          fname: "",
          last: "",
          middle: "",
          gender: "Male",
          salutation: "Mr",
          dateDob: {
            year: null,
            month: null,
            date: null,
          },
          dateOfBirth: null,
          ffbnumber: false,
          TSAnumber: false,
          Apinumber: false,
          Type: "Child",
          clicked1: false,
          isYearValid: false,
          // dobChildError: false,
        });
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.slice(0, 1) < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            isYearValid: false,
            // dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    addInf() {
      if (this.infrantData.length < this.inf) {
        this.infrantData.push({
          fname: "",
          last: "",
          middle: "",
          gender: "Male",
          salutation: "Mr",
          dateDob: {
            year: null,
            month: null,
            date: null,
          },
          dateOfBirth: null,
          ffbnumber: false,
          TSAnumber: false,
          Apinumber: false,
          Type: "Infrant",
          clicked1: false,
          isYearValid: false,
          // dobInfrantError: false,
        });
      }
    },

    getadult() {
      // this.pageloader = false;
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      // this.pageloader = false;
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      // this.pageloader = false;
      //   let res = this.local_id.split("-");
      //   let res1 = res[res.length - 1];
      //   let data_pass = [];
      //   data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));



      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },
    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },
    pushData() {
       if(this.seatmapApi){
        this.$router.push({
          path: "/seatmap",
          query: { pass_id1: this.Localoneway_id }
        })
      }
      else {
        this.$router.push({
          path: "/confirmbook",
          query: { pass_id1: this.Localoneway_id }
        })
      }
    },

    pushData1() {
       if(this.seatmapApi){
        this.$router.push({
          path: "/seatmap",
          query: { pass_id: this.local_id }
        });
      }
      else {
        this.$router.push({
          path: "/confirmbook",
          query: { pass_id: this.local_id }
        })
      }
    },
    pushData2() {
       if(this.seatmapApi){
        this.$router.push({
          path: "/seatmap",
          query: { pass_id: this.local_id }
        });
      }
      else {
        this.$router.push({
          path: "/confirmbook",
          query: { pass_id: this.local_id }
        })
      }
    },
    totalSeatData() {

      this.totalpassanger.forEach((c, i) => {
        if (c.type == "Adult") {
          this.adultData[i].seat_requested = c.seat_number
          // c.name=
        }
        if (c.type == "Child") {
          this.childData[i].seat_requested = c.seat_number
        }
        if (c.type == "Infrant") {
          this.infrantData[i].seat_requested = c.seat_number
        }

      })


    },
    async submitform() {
      localStorage.setItem("totalpassanger", JSON.stringify(this.totalpassanger));
      // this.pageloader = true;
      //this.totalSeatData()
      
      localStorage.setItem("totalpassanger", JSON.stringify(this.totalpassanger));
      console.log(this.totalpassanger,'this.totalpassanger')
      if (this.$refs.form) {
        const { valid } = await this.$refs.form.validate()
        // this.fareinsert();
        let contact = {
          contactCheck: this.contactCheck,
          couponsCheck: this.couponsCheck,
        };
        localStorage.setItem("Travelcontact", JSON.stringify(contact));
        this.passengersDatas.adultDatas = this.adultData;
        this.passengersDatas.childDatas = this.childData;
        this.passengersDatas.infrantDatas = this.infrantData;
        console.log(this.adultData, 'this.adultData')



        console.log(this.valid1, 'validbvdjgevjhgvjh', this.paxContacts[0].number, this.formCheckBox,!this.validateData())

        if (valid && this.paxContacts[0].number && !this.validateData()) {
          console.log(this.validateData(), !this.validateData(), 'thisvalidateData')


          this.datenumber = true;

          this.submitClicked = true;

          if (this.portal) {

            console.log(this.pageloader, this.paxContacts[0].number, this.formCheckBox, !this.validateData(),
              "pageloaderpageloader...pageloaderpageloader");


            this.pageloader = true;
            console.log(this.portal, 'portal')

            let uniDataId = `${"env"}-${this.local_id}`;
            localStorage.setItem(`${uniDataId}`, JSON.stringify(this.portalResData));
            if (this.roundresult.length > 0) {
              localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));
                if(this.seatmapApi){
                  this.$router.push({
                    path: "/seatmap",
                    query: { pass_id: uniDataId },
                  });
                }
                else {
                  this.$router.push({
                    path: "/confirmbook",
                    query: { pass_id: uniDataId },
                  });
                }
              
            } else if (this.onewayresult.length > 0) {
              localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));
              if(this.seatmapApi){
                this.$router.push({
                  path: "/seatmap",
                  query: { pass_id: uniDataId },
                });
              }
              else{
                this.$router.push({
                  path: "/confirmbook",
                  query: { pass_id: uniDataId },
                });
              }
            } else if (this.multiresult.length > 0) {
              localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));
              localStorage.setItem("env-local_id", JSON.stringify(this.portalResData))
                if(this.seatmapApi){
                  this.$router.push({
                    path: "/seatmap",
                    query: { pass_id1: uniDataId },
                  });
                }
                else{
                  this.$router.push({
                    path: "/confirmbook",
                    query: { pass_id1: uniDataId },
                  });
                }
            }
          }
          else {
            this.pageloader = true;


            setTimeout(() => {

              if (this.Localoneway_id) {
                console.log(valid, this.paxContacts[0].number, this.formCheckBox, !this.validateData(), "ggghghghghg.....2");

                // console.log('this.Localoneway_id ',this.Localoneway_id );
                this.pushData()

              } else if (this.local_id.includes("multi")) {
                // console.log('this.local_id ',this.local_id );
                this.pushData1()

              } else if (this.local_id) {
                // console.log('work....work....3', this.local_id);
                this.pushData2()

              } else {
                console("Not....working....");
              }
            }, 3000);
          }



          sessionStorage.setItem("TravelerDetails", JSON.stringify(this.passengersDatas));
          localStorage.setItem("passengerContact", JSON.stringify(this.paxContacts));
        }

        else {
          console.log('ppppp')
          console.log(valid, this.formCheckBox, "ggghghghghg.....4");
          this.dobAdultError = true;
          this.dobChildError = true;
          this.dobInfrantError = true;
          this.datenumber = true;

          if (!this.formCheckBox) {
            console.log(this.formCheckBox, "ggghghghghg.....6");
            this.checkForm = true;
          }

          // return this.valid1;
        }

      }

    },


    getPriceDetails(e, type, quantityKey) {
      // console.log(e, "univer");
      if (e) {
        let basePrice =
          e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
            .BookingCurrencyPrice;
        let taxPrice =
          e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
            .BookingCurrencyPrice;
        let count = e.PassengerQuantity;

        return {
          baseprice: parseFloat(basePrice).toFixed(2),
          taxprice: parseFloat(taxPrice).toFixed(2),
          [quantityKey]: count,
          pax_type: type,
        };
      }
    },

    async portalDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: this.pointOfSale,
        api_env: "CERT",
        request_type: "user_booking_view",
      };

      console.log(userBooking, this.bookingviewApi, "userBookinguserBooking");
      try {
        const responce = await axios.post(this.bookingviewApi, userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        console.log(responce, "responcedataresponcedata");
        if (responce.data.view_response == "fail") {
          setTimeout(() => {
            this.dialog1 = true;
          }, 3000);
          console.log("dialog1111");
        } else {
          let result = responce.data;
          this.portalData.push(result);
          console.log(this.portalData, "this.portalDatathis.portalData");
          this.checkOutPage = true;
          // this.dataloader = false;
          // this.pageloader = false;
          this.showConfirmPage = true;
          console.log("dialog11114444444444444");
        }
      }
      catch (err) {
        console.log(err, "ppppp5555555");
        setTimeout(() => {
          this.dialog3 = true;
        }, 3000);
      }

      // catch((err) => {
      //   console.log(err, "ppppp5555555");
      //   setTimeout(() => {
      //     this.dialog3 = true;
      //   }, 3000);
      // });

      if (this.portalData.length > 0 && (!this.portalData[0] == "" || null)) {
        this.dialog3 = false;
        console.log("sdfadfzxczxsdvasdfasdf");
        await this.retrieveairlogo();
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          let baggageList = [];
          baggageList =
            this.portalData[i].DataLists.BaggageAllowanceList.BaggageAllowance;
          console.log(baggageList, "baggageListbaggageList");
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          // console.log(flightsegment, "flightsegment");
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);
          let getSegment = this.portalData[i].OffersGroup.AirlineOffers.Offer.OfferItem[0][0].FareComponent;
          console.log(getSegment,'getSesgmentgetSesgment...1');
          let restripType = this.portalData[i].TripType;
          console.log(restripType,'getSesgmentgetSesgment...2',typeof(restripType));

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let Baggage1 = [];
          let Baggage2 = [];
          let Baggage3 = [];
          let Baggage4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF") {
                inf = t.PassengerQuantity;
              }
              // else if(this.local_id.includes("multi")){
              //   this.$router.push({ path: "/confirmBook", query: { pass_uid: this.local_id } })
            });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              console.log(s, 'sasdasdasdsdasa', index);
              if (index == 0) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage1.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage2.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 2) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage3.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 3) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage4.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });

          let BaggageData1 = [];

          if (this.offerData1.length > 0) {
            Baggage1.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                  this.Flight1.push(seg)
                  // getSegment.length > 2 && this.portalData[i].TripType === "MULTICITY" ? this.Flight1.push(seg) : this.portalResData.push({ Depature: seg });
                  console.log(this.Flight1, "thisflightsegment...1");
                  console.log(this.portalResData, "portalResDataportalResData...1");
                }
              });
            }
          }

          let BaggageData2 = [];
          if (this.offerData2.length > 0) {
            Baggage2.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData2.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                  console.log(this.Flight2, "thisflightsegment...2",restripType);

                }
              });
            }
          }

          let BaggageData3 = [];
          if (this.offerData3.length > 0) {
            Baggage3.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData3.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          let BaggageData4 = [];
          if (this.offerData4.length > 0) {
            Baggage4.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData4.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          console.log(flightsegment, 'flightsegmentsdasdas...1');
          console.log(this.airlogodata, 'flightsegmentsdasdas...2');
          for (let i = 0; i < this.airlogodata.length; i++) {
            for (let j = 0; j < flightsegment.length; j++) {
              if (flightsegment[j].MarketingCarrier.AirlineID.trim() == this.airlogodata[i].id.trim()) {
                console.log(flightsegment[j].MarketingCarrier.AirlineID, 'flightsegmentsdasdas...3', this.airlogodata[i].id);
                flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
              }
            }
          }
          console.log(flightsegment, 'flightsegmentsdasdas...4');


          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];

          let combinedPricedetail = [];

          console.log($data, "dataofferdataoffer");

          $data.forEach((v) => {
            // console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            if (s.ReqCurrency == "USD") {
              currency.push("US$");
            } else if (s.ReqCurrency == "CAD") {
              currency.push("CA$");
            } else {
              currency.push("US$");
            }
            ownername.push(s.OwnerName);
            console.log(s, "airlineDataassairlineDataass");
            console.log(this.airlogodata, "airlogodataairlogodata");

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);

            let adultPriceDetails = [];
            let childPriceDetails = [];
            let infPriceDetails = [];
            s.OfferItem[0].forEach((e) => {
              if (e.PassengerType === "ADT") {
                adultPriceDetails = this.getPriceDetails(e, "Adult", "adultcount");
              }

              if (e.PassengerType === "CNN") {
                childPriceDetails = this.getPriceDetails(e, "Child", "childcount");
              }

              if (eval.PassengerType === "INF") {
                infPriceDetails = this.getPriceDetails(e, "Inf(lap)", "infcount");
              }
            })

            combinedPricedetail.push([
              adultPriceDetails,
              childPriceDetails,
              infPriceDetails,
            ]);
          });

          this.muticityData = [];

          if(restripType == "MULTICITY"){
            console.log(restripType,'restripTypeeee')
            if (this.Flight1 && this.Flight1.length > 0) {
              this.muticityData.push({
                from: this.Flight1[0][0].Departure.AirportCode,
                to: this.Flight1[0][this.Flight1[0].length - 1].Arrival
                  .AirportCode,
              });
            }
  
            if (this.Flight2 && this.Flight2.length > 0) {
              this.muticityData.push({
                from: this.Flight2[0][0].Departure.AirportCode,
                to: this.Flight2[0][this.Flight2[0].length - 1].Arrival
                  .AirportCode,
              });
            }
  
            if (this.Flight3 && this.Flight3.length > 0) {
              this.muticityData.push({
                from: this.Flight3[0][0].Departure.AirportCode,
                to: this.Flight3[0][this.Flight3[0].length - 1].Arrival
                  .AirportCode,
              });
            }
  
            if (this.Flight4 && this.Flight4.length > 0) {
              this.muticityData.push({
                from: this.Flight4[0][0].Departure.AirportCode,
                to: this.Flight4[0][this.Flight4[0].length - 1].Arrival
                  .AirportCode,
              });
            }
          }

          console.log(this.muticityData, "muticityData...1");

          for(let e = 0; e < this.muticityData.length; e++){
              console.log(e,'frsomfsromsdasdaasfrsom...1');
              const [fromcity, tocity] = await Promise.all([this.fetchCity(this.muticityData[e].from), this.fetchCity(this.muticityData[e].to)]);
              console.log(fromcity,'frsomfsromsdasdaasfrsom...2',tocity);
              console.log(fromcity[0] ? fromcity[0].city : null,'frsomfsromsdasdaasfrsom...2',tocity[0] ? tocity[0].city : null);
              let objcityname = {
                from: fromcity[0].city,
                to: tocity[0].city
              }
              this.muticityData[e] = objcityname;
            }

          console.log(this.muticityData, "muticityData...2");

          console.log(this.portalResData, "portalResDataportalResData...1");

          let getfromcode;
          let gettocode;
          let fromcitycode;
          let tocitydode;

          if(this.portalResData.length > 0){
            getfromcode = this.portalResData[0].Depature[0].Departure.AirportCode;
            gettocode = this.portalResData[0].Depature[this.portalResData[0].Depature.length - 1].Arrival.AirportCode;
  
            if (getfromcode && gettocode) {
              var [fromCityList, toCityList] = await Promise.all([
                this.fetchCity(getfromcode),
                this.fetchCity(gettocode)
              ]);
  
              fromcitycode = `${fromCityList[0].city} (${getfromcode})`;
              tocitydode = `${toCityList[0].city} (${gettocode})`;
            }
          }

          console.log(this.portalResData, "portalResDataportalResData...2");

          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].currency = currency[i];
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].pricedetails = combinedPricedetail[i];
            
            if (this.Flight2.length > 0 && restripType == "MULTICITY" && !this.Flight3.length > 0) {
              this.portalResData[i].Flight1 = this.portalResData[0].Depature;
              this.portalResData[i].Flight2 = this.Flight2[i];
              this.portalResData[i].BaggageData1 = BaggageData1[i];
              this.portalResData[i].BaggageData2 = BaggageData2[i];
              this.portalResData[i].Flight1JourneyTime = source1[i];
              this.portalResData[i].Flight2JourneyTime = source2[i];
              this.portalResData[i].muticityData = this.muticityData;
            }else{
              if (!this.Flight2.length > 0) {
                this.portalResData[i].Depature = this.portalResData[0].Depature;
                this.portalResData[i].depBaggage = BaggageData1[i];
                this.portalResData[i].Depaturejourney = source1[i];
                // this.portalResData[i].BaggageData = BaggageData1[i];
              }else{
                this.portalResData[i].Depature = this.portalResData[0].Depature;
                this.portalResData[i].depBaggage = BaggageData1[i];
                this.portalResData[i].Depaturejourney = source1[i];
                // this.portalResData[i].BaggageData = BaggageData1[i];
                this.portalResData[i].Return = this.Flight2[i];
                this.portalResData[i].arrBaggage = BaggageData2[i];
                this.portalResData[i].Returnjourney = source2[i];
              }
            }

            
            if (this.Flight3.length > 0) {
              this.portalResData[i].Flight1 = this.portalResData[0].Depature;
              this.portalResData[i].Flight2 = this.Flight2[i];
              this.portalResData[i].Flight3 = this.Flight3[i];
              // this.muticityData[0].from = this.portalResData[0].Depature[0].Departure.AirportCode;
              // this.muticityData[0].to = this.portalResData[0].Depature[this.portalResData[0].Depature.length - 1].Arrival.AirportCode;
              this.portalResData[i].BaggageData1 = BaggageData1[i];
              this.portalResData[i].BaggageData2 = BaggageData2[i];
              this.portalResData[i].BaggageData3 = BaggageData3[i];
              this.portalResData[i].Flight1JourneyTime = source1[i];
              this.portalResData[i].Flight2JourneyTime = source2[i];
              this.portalResData[i].Flight3JourneyTime = source3[i];

              this.portalResData[i].muticityData = this.muticityData;
            }

            if (this.Flight4.length > 0) {
              this.portalResData[i].BaggageData4 = BaggageData4[i];
              this.portalResData[i].Flight4 = this.Flight4[i];

              this.portalResData[i].Flight4JourneyTime = source4[i];
              // this.muticityData[3].from = this.Flight4[0][0].Departure.AirportCode;
              // this.muticityData[3].to = this.Flight4[0][this.Flight4[0].length - 1].Arrival.AirportCode;
              this.portalResData[i].muticityData = this.muticityData;
            }

            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
            this.portalResData[i].fromTrip = fromcitycode;
            this.portalResData[i].toTrip = tocitydode;
          }
          console.log(
            this.portalResData,
            "thisportalResDatathisportalResData"
          );

          if (((this.offerData2.length > 0 && !this.offerData3.length > 0) || (this.offerData3.length > 0 || this.offerData4.length > 0)) && restripType === "MULTICITY") {
            console.log(this.portalResData, "this.portalResadassDaata...1");
            this.multiresult = this.portalResData;
            this.getmultitime1();
            this.dataloader = false;

            // this.itineraryInfo = true;
            // this.gettimeret();
            // this.gettimedep();
            // console.log(this.roundresult, "this.roundresult88");
          } else if ((this.offerData1.length > 0 && !this.offerData2.length > 0) || this.offerData2.length > 0) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
            if(this.offerData2.length > 0){
              this.gettimeret();
            }
            this.gettimedep();
            this.dataloader = false;
            console.log(this.roundresult, "this.roundresult88");
          } 
          // else if (this.offerData1.length > 0) {
          //   this.onewayresult = this.portalResData;
          //   console.log(this.onewayresult, "this.onewayresult5555");
          //   this.itineraryInfo1 = true;
          //   this.gettimedep1();
          //   this.dataloader = false;
          // }

          this.portalResData.forEach((v) => {
            console.log(v, "ppppp");
            this.local_id = v.selectId;
            this.currency = v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = v.Taxprice;
            this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
            // this.totalbasePassanger = v.Baseprice;
            this.totalAmountpassenger = v.TotalPrice;
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          console.log(this.portalResData,"this.portalResDatathis.portalResData");

          this.pageloader = false;
          // this.getseatMapping();
          this.getsegment();
        }
      } else {
        this.dialog3 = true;
        console.log("flightdata");
      }
    },

    gettimedep1() {
      for (let i = 0; i < this.onewayresult.length; i++) {
        for (let j = 0; j < this.onewayresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresult[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresult[i].Depature[currentIndex].Arrival.Date}T${this.onewayresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresult[i].Depature[nextIndex].Departure.Date}T${this.onewayresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.onewayresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimeret() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Return.length;

          const time1 = new Date(
            `${this.roundresult[i].Return[currentIndex].Arrival.Date}T${this.roundresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Return[nextIndex].Departure.Date}T${this.roundresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    gettimedep() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Depature.length;

          const time1 = new Date(
            `${this.roundresult[i].Depature[currentIndex].Arrival.Date}T${this.roundresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Depature[nextIndex].Departure.Date}T${this.roundresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    getmultitime1() {
      console.log('multiityty')
      for (let i = 0; i < this.multiresult.length; i++) {
        for (let j = 0; j < this.multiresult[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight1.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight1[currentIndex].Arrival.Date}T${this.multiresult[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight1[nextIndex].Departure.Date}T${this.multiresult[i].Flight1[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight1[currentIndex].layoverTimes1 = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        for (let j = 0; j < this.multiresult[i].Flight2.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight2.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight2[currentIndex].Arrival.Date}T${this.multiresult[i].Flight2[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight2[nextIndex].Departure.Date}T${this.multiresult[i].Flight2[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight2[currentIndex].layoverTimes2 = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        if (this.multiresult[i].Flight3) {
          for (let j = 0; j < this.multiresult[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight3.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight3[currentIndex].Arrival.Date}T${this.multiresult[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight3[nextIndex].Departure.Date}T${this.multiresult[i].Flight3[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight3[currentIndex].layoverTimes3 = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }

        if (this.multiresult[i].Flight4) {
          for (let j = 0; j < this.multiresult[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight4.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight4[currentIndex].Arrival.Date}T${this.multiresult[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight4[nextIndex].Departure.Date}T${this.multiresult[i].Flight4[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight4[currentIndex].layoverTimes4 = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }
      }
    },



    async fetchCity(event) {
      if (event.length >= 3) {
        try {
          const response = await axios.get(`${this.autocompleteApi}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          });

          let resData = response.data;

          console.log(event, 'respresponseonse...1');
          return resData;

        } catch (error) {
          console.log(error, "erroroor.....");
        }
      } else {
        return [];
      }
    },

    async retrieveairlogo() {
      try {
        const response = await axios.get(this.airLogoApi, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        console.log(response, 'responsesdasdasdasd')
        if (response.data) {
          this.airlogodata = response.data;
          console.log(this.airlogodata, 'airlogoairlogo');
        }
        else {
          console.log('No data in airlogodata');
        }
      }
      catch (error) {
        console.log(error, "ressloerrrororooool");
      }
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.airLogoApi = getConfigData1.payload.portal_configuration.content_data.airlines_logo.url;
        this.bookingviewApi = getConfigData1.payload.portal_configuration.API_endpoints.bookingview;
        this.fareRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.pointOfSale = getConfigData1.payload.portal_configuration.pointofsale;
        this.autocompleteApi = getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;

        this.seatmapApi = getConfigData1.payload.portal_configuration.API_endpoints.seatmap;
        // console.log(this.bookingviewApi,'bookingviewApibookingviewApibookingviewApi')
      }
    },
    passeSeatNameData(data, index) {

      this.totalpassanger.forEach((c, i) => {
        console.log(c, i, this.adultData, 'sadsdasdasdasdasdsa')
        if (c.type == data.Type && c.index == index) {
          c.name = ` ${data.fname}${data.middle}${data.last}`
        }
        if (c.type == data.Type && c.index == index) {
          c.name = ` ${data.fname}${data.middle}${data.last}`
        }
        if (c.type == data.Type && c.index == index) {
          c.name = ` ${data.fname}${data.middle}${data.last}`
        }

      })

      console.log(this.totalpassanger, 'this.totalpassanger')

    }


  },
  computed: {
    getDataResult() {
      if (this.roundresult && this.roundresult.length > 0) {
        return this.roundresult;
      } else if (this.onewayresult && this.onewayresult.length > 0) {
        return this.onewayresult;
      } else if (this.multiresult && this.multiresult.length > 0) {
        return this.multiresult;
      }

      return [];


    },


  },

  watch: {
    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          // console.log(newData, 'newDatanewDatanewDatanewDatanewDatanewData')
          newData.forEach((data, index) => {
            // this.totalpassanger[index].name=`${data.salutation}  ${data.fname}${data.middle}${data.last}`
            console.log(data, index, this.totalpassanger, 'data,indexdata,index')

            this.passeSeatNameData(data, index)
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data, index) => {
            this.passeSeatNameData(data, index)
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
        newData.forEach((data, index) => {
          this.passeSeatNameData(data, index)
          if (data.salutation !== "Mr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }

          if (data.dateDob.date) {
            // console.log(data.dateOfBirth.date,'newDatanewData')
            if (data.dateDob.year && data.dateDob.month && data.dateDob.date) {
              const monthIndex = this.monthList.indexOf(data.dateDob.month);
              const convertDob = new Date(
                parseInt(data.dateDob.year),
                monthIndex,
                parseInt(data.dateDob.date)
              );

              const formatDate = moment(convertDob).format("YYYY-MMM-DD");

              data.dateOfBirth = formatDate;

              // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
            }
          }
        });
      },
    },

    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },


  mounted() {
    window.scrollTo(0, 0)
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });


    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
    this.seatPassanger();

    this.seatPassanger();
  },


  beforeRouteLeave(to, from, next) {
    if (to.fullPath.startsWith('/flightDetailsCom')) {
      // console.log('Case...1');
      next();
    }
    else if (!this.validateData() && this.submitClicked) {
      // console.log('Case...2');
      next();
    }
    else if (!this.validateData() && !this.submitClicked) {
      // console.log('Case...3');
      alert('Please Fill the Details and Click the "Continue" button');
      this.submitform();
      next(false);
      this.submitClicked = false;
    }
    else if (this.validateData() && !this.submitClicked) {
      // console.log('Case...4');
      alert('Please complete the form before leaving');
      this.submitform();
      next(false);
      this.submitClicked = false;
    }
  },

  created() {

    this.getConfig();
    this.retrieveairlogo();

    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      console.log("working....11111");
      this.$router.push("/");
    }

    console.log(this.bookingviewApi, 'userBookinguserBooking');

    this.local_id = this.$route.query.pass_id;
    // this.local_id1 = this.$route.query.pass_id;
    this.dataLink = location.href;
    this.Localoneway_id = this.$route.query.pass_id1;
    console.log(this.local_id, 'uidsadasdadadadada')
    console.log(this.Localoneway_id, "this.local_idthis.local_id888888888888");

    //     if (!this.$route.query.pass_uid) {

    // }

    if (this.dataLink.includes("env")) {
      if (this.dataLink.includes("env")) {
        console.log(this.dataLink, "data_idthisdata8");
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.airlogodata = airLogo;
        this.portalDataConvert();
        this.retrieveairlogo();
        this.checkOutPage = false;
        // setTimeout(() => {
        //   this.getsegment();
        // }, 2000);
      }
    } else if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid, 'uid')

      if (onewaydata_uid) {
        this.roundresult.push(onewaydata_uid);
        // this.pageloader = this.roundresult ? false : true;
        this.getadult();
        this.itineraryInfo1 = true;
        this.pageloader = false;

        console.log(this.roundresult, "onewayresult.....onewayresult");

        setTimeout(() => {
          this.getsegment();
        }, 2000);
      } else if (this.Localoneway_id !== onewaydata_uid) {
        console.log('onewayData')
        setTimeout(() => {
          this.pageloader = true;
          this.dialog2 = true;
        }, 5000);
      }
    } 
    else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          // this.pageloader = this.multiresult ? false : true;
          this.multiData();
          this.pageloader = false;
          console.log(data_uid, "multicitymulticitymulticity");
          setTimeout(() => {
            this.getsegment();
          }, 2000);
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.Localoneway_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          // this.pageloader = this.roundresult ? false : true;
          this.getadult();
          this.itineraryInfo = true;
          this.pageloader = false;

          console.log(this.roundresult, "roundresult.....roundresult");

          setTimeout(() => {
            this.getsegment();
          }, 2000);
        } else if (this.local_id !== data_uid) {
          console.log("dialog1dialog1dialog2");

          setTimeout(() => {
            this.pageloader = true;
            this.dialog2 = true;
          }, 5000);
        }
      }
    this.seatPassanger();

    } else {
      // console.log('redirectingggg...')
      //   this.$router.push("/");
      // setTimeout(() => {
      //   console.log('dialog1dialog1dialog2')
      //         this.pageloader = true;
      //         this.dialog1 = true;
      //       }, 5000);
    }
    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId
      this.selectId = this.roundresult[0].selectId
      // this.getseatMapping();
    }
    else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId
      this.selectId = this.multiresult[0].selectId
      // this.getseatMapping();
    }

    let fareRequest = {
      request_type: "farerules",
      shopping_response_id: this.shoppingid,
      offer_id: this.selectId,
      portal_id: this.portal_id
    }
    console.log(fareRequest, 'fareRequestssssss')
    axios.get(this.fareRulesApi, {
      // headers: {
      // 	"Content-Type": "application/JSON",
      // },
      params: fareRequest
    })
      .then((response) => {
        this.fareRulesContent = response.data
        // console.log(response.data, "fareRulesContentfareRulesContent.....")
      })
      .catch((error) => {
        console.log(error, 'erroroor.....')
      })
  },
  
};
</script>

<style scoped>
@import "@/assets/main.css";


/* extra css */
>>>.bi-clock::before {
  font-size: 17px;
}

>>>.bi-info-circle-fill::before {
  font-size: 17px;
}

.datePickerSection .yearPick,
.monthPick,
.datePick {
  width: 175px;
}

>>>.v-field--active .v-label.v-field-label {
  background: #f8f9fa;
}

>>>.datePickerSection .yearPick .v-field {
  /* border-radius: 0 !important; */
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

>>>.datePickerSection .monthPick .v-field {
  border-radius: 0 !important;
  padding: 0px !important;
}

>>>.datePickerSection .datePick .v-field {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}


/* >>>.v-field__input{
    padding-top:0px !important;
    padding-bottom:10px;
    padding-left:8px;
    padding-right:5px;
}
>>>.v-field__field{
    height:40px;
    background-color:white;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
} */
/* >>>.v-field__outline{
    --v-field-border-width: 0.5px !important;
    --v-field-border-opacity: 0.02 !important;
    border-radius:10px;
} */
/* >>>.v-field__append-inner{
    background-color:white !important;
    border:1px solid #dee2e6 !important;
} */



>>>.yearPick .v-field__field {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

>>>.datePick .v-field__field {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

>>>.monthPick .v-field__field {
  border-right: none !important;
}

>>>.monthPick .v-field__append-inner {
  border-left: none !important;
}

.form-check-input {
  border: 1px solid black !important;
}

/* >>>.gender .v-select__selection-text{
    margin-bottom:11px;
    padding-left:5px;
}
>>>.gender .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.gender .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
} */



/* >>>.title .v-select__selection-text{
    margin-bottom:11px;
    padding-left:5px;
}
>>>.title .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.title .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
} */


/* >>>.country .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.country .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
}
>>>.v-autocomplete__selection{
    margin-bottom:16px;
} */


>>>.Name .v-field__field {
  border-radius: 6px;
}

.logoData {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2px;
}

a {
  text-decoration: none;
}

/* >>>.v-field--active .v-label.v-field-label{
  background:#f8f9fa;
}
>>>.countryCode .v-field {
  padding-right:0px;
} */


>>>.review-flight .v-input {
  color: black !important;
  font-weight: 500 !important;
}
@media screen and (max-width:480px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
.drawer-section .offcanvas {
  width: 35%;
}
@media screen (min-width:480px) and (max-width:768px){
  .drawer-section .offcanvas {
    width:65%;
  }
}
@media screen and (max-width:430px) {

  .datePickerSection .yearPick,
  .monthPick,
  .datePick {
    width: 84px;
  }
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}


>>>.drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px !important;
}

>>>.drawer-section .v-timeline {
  justify-content: left !important;
}

>>>.drawer-section .v-list-item__content {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-header {
  background: #5293cb !important;
  color: white !important;
}

>>>.btn-icon .v-btn--size-default {
  --v-btn-height: 11px !important;
}

.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
}

>>>.drawer-section .v-slide-group__content {
  display: flex;
  justify-content: space-evenly;
}

>>>.drawer-section .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>>.drawer-section .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>>.drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>>.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content {
  color: #002d5b !important;
}

>>>.drawer-section .v-timeline-divider__inner-dot {
  background: #002d5b !important;
}

>>>.drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px;
}

>>>.drawer-section .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.travel-btn {
  position: relative;
  background-color: #002d5b !important;
  color: white !important;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

.travel-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ffc104 0%);
  transition: left 0.3s;
}

.travel-btn:hover::before {
  left: 0;
}

.travel-btn:hover {
  color: black !important;
}

.form-check .form-check-input:checked {
  background-color: #002d5b !important;
}

>>>.cancellationRules .fareRules_content__1uPa7 .FareRule h2 {
  font-size: 17px !important;
  font-weight: 700 !important;
}

>>>.cancellationRules .fareRules_content__1uPa7 .FareRule p {
  overflow-wrap: break-word !important;
  inline-size: 58%;
}

>>>.cancellationRules .fareRules_content__1uPa7 .FareRule ul li {
  overflow-wrap: break-word !important;
  inline-size: 60%;
}

>>>.drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>>.v-slide-group__content .v-btn {
  height: 45px !important;
}

>>>.v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>>.drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>>.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content {
  color: #002d5b;
}

>>>.v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}

>>>.v-tabs--fixed-tabs .v-slide-group__content>*:last-child,
.v-tabs--align-tabs-center .v-slide-group__content>*:last-child {
  margin-inline-end: inherit;
}

.true-sheet {
  border: 2px solid lightgreen;
  border-radius: 5px;
  padding: 1px 5px;
  cursor: pointer;
  background: lightgreen;
}

.true-sheet:focus {
  background: lightgreen !important;
  border: 2px solid lightgreen !important;
}

.false-sheet {
  border: 2px solid #E2E2E2;
  border-radius: 5px;
  background: #E2E2E2;
  padding: 1px 5px;
}

.tru-icon {
  background: #103769 !important;
  border: 2px solid #103769 !important;
}

.tru-pass {
  background: #103769 !important;
  color: white !important;
  /* font-size:13px; */
}

.tru-flight {
  background: #103769 !important;
  color: white !important;
  padding: 5px;
}

.tru-flightfalse {
  padding: 5px;
  border: 1px solid gray;
}

.av-sheet {
  background: lightgreen;
  width: 17px;
  height: 17px;
  border: 2px solid lightgreen;
  margin: 3px;
  border-radius: 3px;
}

.sel-sheet {
  background: #103769;
  width: 17px;
  height: 17px;
  border: 2px solid #103769;
  margin: 3px;
  border-radius: 3px;
}

.oc-sheet {
  background: lightgray;
  width: 17px;
  height: 17px;
  border: 2px solid lightgray;
  margin: 3px;
  border-radius: 3px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid lightgray;
  table-layout: fixed;
}

td,
th {
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
}
</style>
