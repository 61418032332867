<template>
  <div class="pagewrap modify-search" v-if="pageloader">
    <v-form v-model="valid" ref="form" @submit.prevent="submitForm">
      <!-- body section -->
      <div class="content-section">
        <!-- flight result page - oneway start -->
        <div class="review-flight py-3">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-8">
                <div class="mt-3 order-1">
                  <v-card style="font-size: 14px" class="billingCard bg-white">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <h4 class="ps-3" style="padding-top: 20px">
                          {{ $t("billingDetContent.headingContent.billingHeading") }}
                        </h4>
                      </div>
                    </div>

                    <div class="p-4">
                      <div class="row ps-2">
                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.fullName") }}<span class="text-danger"> *</span></p>
                          <v-text-field
                            class="form-text"
                            v-model="billingDetails.name"
                            :placeholder="this.$t('billingDetContent.labelsContent.fullName')"
                            :rules="[
                              (v) => !!v || 'Full Name is required',
                              (v) =>
                                /^[a-zA-Z -]+$/.test(v) ||
                                'Only alphabets, hyphens, and spaces are allowed',
                            ]"
                            variant="underlined"
                          ></v-text-field>
                        </div>

                        <div class="col-md-4 mt-1">
                          <p>{{ $t("billingDetContent.labelsContent.country") }}<span class="text-danger"> *</span></p>
                          <v-autocomplete
                            :items="countriesData"
                            item-title="name"
                            variant="underlined"
                            :rules="[(v) => !!v || 'Country is required']"
                            :placeholder="this.$t('billingDetContent.labelsContent.country')"
                            v-model="billingDetails.country"
                            class="country-field"
                          >
                          </v-autocomplete>
                        </div>

                        <div class="col-md-4 mt-1">
                          <p>
                            {{ $t("billingDetContent.labelsContent.regionState") }}<span class="text-danger">
                              *</span
                            >
                          </p>
                          <v-autocomplete
                            :items="stateData"
                            item-title="name"
                            variant="underlined"
                            :rules="[(v) => !!v || 'Place is required']"
                            :placeholder="this.$t('billingDetContent.labelsContent.regionState')"
                            v-model="billingDetails.state"
                            class="country-field"
                          >
                          </v-autocomplete>
                        </div>
                      </div>

                      <div class="row ps-2">
                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.city") }}<span class="text-danger"> *</span></p>
                          <v-text-field
                            :rules="[(v) => !!v || 'City is required']"
                            v-model="billingDetails.city"
                            :placeholder="this.$t('billingDetContent.labelsContent.city')"
                            variant="underlined"
                          ></v-text-field>
                        </div>

                        <div class="col-md-4">
                          <p>
                            {{ $t("billingDetContent.labelsContent.addressLine") }} 1<span class="text-danger"> *</span>
                          </p>

                          <v-text-field
                            :rules="[(v) => !!v || 'Address is required']"
                            v-model="billingDetails.address1"
                            :placeholder="`${this.$t('billingDetContent.labelsContent.addressLine')} 1`"
                            variant="underlined"
                          ></v-text-field>
                        </div>

                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.addressLine") }} 2 ({{ $t("billingDetContent.labelsContent.optional") }})</p>

                          <v-text-field
                            :placeholder="`${this.$t('billingDetContent.labelsContent.addressLine')} 2`"
                            variant="underlined"
                            v-model="billingDetails.address2"
                          ></v-text-field>

                          <!-- <v-select class="v-select" label="State" :items="['America', 'Canada']"
                                                      v-model="billingDetails.state"
                                                      :rules="[v => !!v || 'This field is required']"
                                                      variant="underlined"></v-select> -->
                        </div>
                      </div>

                      <div class="row ps-2">
                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.postelCode") }}<span class="text-danger"> *</span></p>
                          <v-text-field
                            v-model="billingDetails.zipcode"
                            :placeholder="this.$t('billingDetContent.labelsContent.postelCode')"
                            :rules="[(v) => !!v || 'This field is required']"
                            variant="underlined"
                          ></v-text-field>
                        </div>

                        <div class="col-md-4 countryCode">
                          <p>{{ $t("billingDetContent.labelsContent.countryCode") }}<span class="text-danger"> *</span></p>
                          <v-autocomplete
                            class="country_code"
                            :items="countrycode"
                            item-title="name"
                            variant="underlined"
                            width="auto"
                            :rules="[(v) => !!v || 'Country Code is required']"
                            :placeholder="this.$t('billingDetContent.labelsContent.countryCode')"
                            v-model="billingDetails.coutyCode"
                          >
                          </v-autocomplete>
                        </div>

                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.phoneNumber") }}<span class="text-danger"> *</span></p>
                          <v-text-field
                            :placeholder="this.$t('billingDetContent.labelsContent.phoneNumber')"
                            variant="underlined"
                            v-model="billingDetails.phonenumber"
                            @country-changed="handleCountryChanged"
                            @input="handleNumericInput"
                            :rules="[(v) => !!v || 'Phone Number is required']"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="row ps-2 mt-2">
                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.email") }}<span class="text-danger"> *</span></p>
                          <v-text-field
                            :placeholder="this.$t('billingDetContent.labelsContent.email')"
                            :rules="emailRequire"
                            variant="underlined"
                            v-model="billingDetails.email"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="row ps-2">
                        <div class="col-md-6">
                          <div class="d-flex justify-content-between">
                            <div class="d-flex align-end">
                              <label
                                class="mb-1 d-flex justify-center align-center"
                                style="font-size: 13px"
                                >{{ $t("billingDetContent.labelsContent.altContact") }}</label
                              >
                            </div>
                            <v-radio-group
                              class="alternate-Details"
                              inline
                              v-model="radioBtn"
                              style="height: 36px; font-size: 14px"
                            >
                              <div class="d-flex align-items-end">
                                <v-radio
                                  :label="this.$t('billingDetContent.labelsContent.phone')"
                                  value="1"
                                  @click="
                                    (altContact = true), (mailAddress = false)
                                  "
                                ></v-radio>
                                <v-radio
                                  :label="this.$t('billingDetContent.labelsContent.email')"
                                  value="2"
                                  @click="
                                    (mailAddress = true), (altContact = false)
                                  "
                                ></v-radio>
                              </div>
                            </v-radio-group>
                          </div>

                          <!-- <vue-tel-input v-model="alterPhone" @input="handleNumericInput1"
                        
                        v-if="altContact"  @country-changed="handleCountryChanged"></vue-tel-input> -->

                          <v-text-field
                            v-model="billingDetails.email1"
                            type="email"
                            :placeholder="this.$t('billingDetContent.labelsContent.email')"
                            variant="underlined"
                            required
                            v-if="mailAddress"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="row ps-2" v-if="altContact">
                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.countryCode") }}</p>
                          <v-autocomplete
                            :items="countrycode"
                            item-title="name"
                            variant="underlined"
                            width="auto"
                            :placeholder="this.$t('billingDetContent.labelsContent.countryCode')"
                            v-model="billingDetails.coutyCode1"
                          >
                          </v-autocomplete>
                        </div>

                        <div class="col-md-4">
                          <p>{{ $t("billingDetContent.labelsContent.phoneNumber") }}</p>
                          <v-text-field
                            :placeholder="this.$t('billingDetContent.labelsContent.phoneNumber')"
                            variant="underlined"
                            v-model="billingDetails.phonenumber1"
                            @country-changed="handleCountryChanged"
                            @input="handleNumericInput"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>

                <!-- payment method section -->
                <div class="row g-0 py-3 order-2">
                  <div class="col-12 col-md-6">
                    <span class="fw-bold">{{ $t("billingDetContent.headingContent.bookingDetHeading") }}</span>
                    <p class="mb-0 mt-2 font-small theme-text-accent-one">
                      * {{ $t("billingDetContent.headingContent.paymentSub") }}
                    </p>
                  </div>
                  <div class="col-12 col-md-6">
                    <!-- <img src="assets/images/icons/payment-icon.png" alt="payment-option-icons"
                                        class="img-fluid"> -->
                    <v-img :src="require('@/assets/payment-icon.png')"></v-img>
                  </div>
                </div>
                <!-- payment method body -->
                <div
                  class="theme-box-shadow theme-border-radius theme-bg-white mb-3 order-3"
                >
                  <!-- method body row -->
                  <div class="row g-0 my-3">
                    <div class="col-12 col-md-12 p-0">
                      <div class="row g-0">
                        <!-- payment tab section -->
                        <div
                          class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-3"
                          style="background-color: #f5faff"
                        >
                          <div
                            class="nav flex-column nav-pills me-1 custom-tab"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              class="nav-link first-tab active bg-white border-bottom py-3 p-0 text-center"
                              id="v-pills-credit-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-credit"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-credit"
                              aria-selected="true"
                            >
                              <!-- <v-icon class="payment-icon credit"></v-icon> -->
                              {{ $t("billingDetContent.paymentMode.subHead") }}
                            </button>

                            <!-- <button class="nav-link" id="v-pills-debit-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-debit"
                                                        type="button" role="tab" aria-controls="v-pills-debit"
                                                        aria-selected="false"><i class="payment-icon debit"></i>Debit
                                                        Card</button>

                                                    <button class="nav-link" id="v-pills-net-banking-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-net-banking"
                                                        type="button" role="tab" aria-controls="v-pills-net-banking"
                                                        aria-selected="false"><i class="payment-icon net"></i>Net
                                                        Banking</button>

                                                    <button class="nav-link" id="v-pills-upi-tab" data-bs-toggle="pill"
                                                        data-bs-target="#v-pills-upi" type="button" role="tab"
                                                        aria-controls="v-pills-upi" aria-selected="false"><i
                                                            class="payment-icon upi"></i>UPI</button>

                                                    <button class="nav-link" id="v-pills-apppay-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-apppay"
                                                        type="button" role="tab" aria-controls="v-pills-apppay"
                                                        aria-selected="false"><i
                                                            class="payment-icon phone"></i>PhonePay/ Paytm</button>

                                                    <button class="nav-link" id="v-pills-wallet-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-wallet"
                                                        type="button" role="tab" aria-controls="v-pills-wallet"
                                                        aria-selected="false"><i class="payment-icon wallet"></i>Mobile
                                                        Wallet</button>

                                                    <button class="nav-link" id="v-pills-payPal-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-payPal"
                                                        type="button" role="tab" aria-controls="v-pills-payPal"
                                                        aria-selected="false"><i
                                                            class="payment-icon paypal"></i>Paypal</button>

                                                    <button class="nav-link last-tab" id="v-pills-emi-tab"
                                                        data-bs-toggle="pill" data-bs-target="#v-pills-emi"
                                                        type="button" role="tab" aria-controls="v-pills-emi"
                                                        aria-selected="false"><i class="payment-icon emi"></i>No
                                                        Cost
                                                        EMI</button> -->
                          </div>
                        </div>
                        <!-- payment tab content section -->
                        <div
                          class="col-12 col-sm-8 col-md-10 col-lg-10 col-xl-10 col-xxl-9"
                        >
                          <div class="tab-content p-4" id="v-pills-tabContent">
                            <div
                              class="tab-pane fade show active"
                              id="v-pills-credit"
                              role="tabpanel"
                              aria-labelledby="v-pills-credit-tab"
                            >
                              <!-- credit card content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >{{ $t("billingDetContent.paymentMode.heading") }}</span
                                  >
                                  <!-- <img src="assets/images/icons/card-options-icon.png"
                                                                    alt="card-options-icon" class="img-fluid"> -->
                                  <v-img
                                    :src="
                                      require('@/assets/card-options-icon.png')
                                    "
                                    style="width: 300px"
                                  ></v-img>
                                </div>
                                <div class="col-12 col-xl-10">
                                  <div class="mb-1 position-relative">
                                    <div>
                                      <label
                                        for="exampleInputNumber"
                                        class="form-label"
                                        >{{ $t("billingDetContent.paymentMode.lebelsContent.cardNumber") }}</label
                                      >
                                      <v-text-field
                                        v-model="billingDetails.cardnumber"
                                        md="4"
                                        placeholder="#### #### #### ####"
                                        :rules="cardrules"
                                        variant="outlined"
                                        class="card_Number"
                                        @keyup="identifyCard"
                                        @keypress="formatCardnumber(event)"
                                      >
                                        <template v-slot:append-inner>
                                          <!-- <img alt="" height="30" :src="cardImage" width="60"> -->
                                          <img
                                            v-if="showImage('visa')"
                                            :src="
                                              require('@/assets/credit_cards/visa.png')
                                            "
                                            class="card"
                                            id="visa"
                                          />
                                          <img
                                            v-if="showImage('mastercard')"
                                            :src="
                                              require('@/assets/credit_cards/mastercard.png')
                                            "
                                            class="card"
                                            id="mastercard"
                                          />
                                          <img
                                            v-if="showImage('american-express')"
                                            :src="
                                              require('@/assets/credit_cards/american_express.png')
                                            "
                                            class="card"
                                            id="american-express"
                                          />
                                          <img
                                            v-if="showImage('discover')"
                                            :src="
                                              require('@/assets/credit_cards/discover.png')
                                            "
                                            class="card"
                                            id="discover"
                                          />
                                          <img
                                            v-if="showImage('jcb')"
                                            :src="
                                              require('@/assets/credit_cards/jcb.png')
                                            "
                                            class="card"
                                            id="jcb"
                                          />
                                          <img
                                            v-if="showImage('dinersclub')"
                                            :src="
                                              require('@/assets/credit_cards/diners_club.png')
                                            "
                                            class="card"
                                            id="dinersclub"
                                          />
                                          <img
                                            v-if="showImage('maestro')"
                                            :src="
                                              require('@/assets/credit_cards/maestro.png')
                                            "
                                            class="card"
                                            id="maestro"
                                          />
                                        </template>
                                        <template v-slot:append>
                                          <v-icon :color="iconColor">{{
                                            appendIcon
                                          }}</v-icon>
                                        </template>
                                      </v-text-field>
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      for="exampleInputCardName"
                                      class="form-label"
                                      >{{ $t("billingDetContent.paymentMode.lebelsContent.NameonCard") }}</label
                                    >
                                    <!-- <input
                                      type="email"
                                      class="form-control"
                                      id="exampleInputCardName"
                                      placeholder="Enter name here"
                                      v-model="billingDetails.cardholdername"
                                      @input="validateInput"
                                    />
                                    <span class="error-text" v-if="cardName"
                                      >Name is required</span
                                    > -->
                                    <v-text-field
                                      :rules="cardNameValid"
                                      type="text"
                                      :label="this.$t('billingDetContent.paymentMode.lebelsContent.NameonCard')"
                                      v-model="billingDetails.cardholdername"
                                      @keypress="filtercardName(event)"
                                      variant="outlined"
                                      tabindex="2" class="cardName"
                                    ></v-text-field>
                                  </div>
                                  <div class="mb-3">
                                    <div class="row">
                                      <div class="col-12 col-lg-6">
                                        <label class="form-label"
                                          >{{ $t("billingDetContent.paymentMode.lebelsContent.expiryDate") }}</label
                                        >
                                        <!-- <div class="row">
                                          <div
                                            class="col-6 col-md-6 pe-3 pe-lg-0"
                                          >
                                            <input
                                              type="email"
                                              class="form-control"
                                              placeholder="year"
                                              v-model="selectedYear"
                                              maxlength="4"
                                            />

                                            <span
                                              class="error-text"
                                              v-if="cardYear"
                                              >Enter valid year</span
                                            >
                                          </div>

                                          <div class="col-6 col-md-6 pe-0">
                                            <select
                                              v-model="selectedMonth"
                                              class="form-select"
                                              aria-label="Month"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                selected
                                              >
                                                Month
                                              </option>
                                              <option
                                                v-for="(month, index) in months"
                                                :key="index"
                                                :value="index + 1"
                                              >
                                                {{ month }}
                                              </option>
                                            </select>
                                            <span
                                              class="error-text"
                                              v-if="cardMonth"
                                              >Enter the Month</span
                                            >
                                          </div>
                                        </div> -->
                                        <v-text-field
                                          v-model="billingDetails.ExpireDate"
                                          :rules="expDate"
                                          @keydown="handleInput"
                                          @paste.prevent
                                          :label="this.$t('billingDetContent.paymentMode.lebelsContent.expiry')"
                                          @input="formatExpireDate"
                                          @keypress="filterExpdate(event)"
                                          variant="outlined"
                                          @keydown.tab="focusNext"
                                          tabindex="3" class="expiryDate"
                                        ></v-text-field>
                                      </div>
                                      <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                                        <label
                                          for="exampleInputCVV"
                                          class="form-label"
                                          >{{ $t("billingDetContent.paymentMode.lebelsContent.cvvCode") }}</label
                                        >
                                        <div class="d-flex align-items-center">
                                          <!-- <input
                                            type="email"
                                            class="form-control"
                                            placeholder="CVV"
                                            @keypress="filter(event)"
                                            v-model="billingDetails.cvvnumber"
                                            maxlength="3"
                                            variant="underlined"
                                            name="cvv"
                                            @paste.prevent
                                            @keydown.tab="focusNext"
                                            tabindex="4"
                                          />

                                          <span class="font-extra-small ms-1"
                                            >3 digits printed on the back of the
                                            card</span
                                          > -->
                                          <v-text-field
                                            :rules="cvvValid"
                                            :label="this.$t('billingDetContent.paymentMode.lebelsContent.cvv')"
                                            v-model="billingDetails.cvvnumber"
                                            :maxlength="showImage('american-express') ? 4 : 3"
                                            variant="outlined"
                                            name="cvv"
                                            @paste.prevent
                                            @keydown.tab="focusNext"
                                            tabindex="4" class="cvvCode"
                                            @keypress="filterNumber(event)"
                                          ></v-text-field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mb-3 d-none">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked1"
                                        checked
                                      />
                                      <label
                                        class="form-check-label font-small theme-text-accent-one"
                                        for="flexCheckChecked1"
                                      >
                                        Save your card details for faster
                                        checkout. CVV is not saved.
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- credit card section end -->
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-debit"
                              role="tabpanel"
                              aria-labelledby="v-pills-debit-tab"
                            >
                              <!-- Debit card content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with Debit Card</span
                                  >
                                  <img
                                    src="assets/images/icons/card-options-icon.png"
                                    alt="card-options-icon"
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="col-12 col-xl-10">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputCardNumber"
                                      class="form-label"
                                      >Card Number</label
                                    >
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="exampleInputCardNumber"
                                      placeholder="Enter card number here"
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputName"
                                      class="form-label"
                                      >Name on Card</label
                                    >
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="exampleInputName"
                                      placeholder="Enter name here"
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <div class="row">
                                      <div class="col-12 col-lg-6">
                                        <label class="form-label"
                                          >Expiry Date</label
                                        >
                                        <div class="row">
                                          <div class="col-6 col-md-6 pe-0">
                                            <select
                                              class="form-select"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                            </select>
                                          </div>
                                          <div
                                            class="col-6 col-md-6 pe-3 pe-lg-0"
                                          >
                                            <select
                                              class="form-select"
                                              aria-label="Default select example"
                                            >
                                              <option selected>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                                        <label
                                          for="exampleInputCreditCVV"
                                          class="form-label"
                                          >CVV Code</label
                                        >
                                        <div class="d-flex align-items-center">
                                          <input
                                            type="email"
                                            class="form-control"
                                            id="exampleInputCreditCVV"
                                            placeholder="CVV"
                                          />
                                          <span class="font-extra-small ms-1"
                                            >3 digits printed on the back of the
                                            card</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mb-3">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        checked
                                      />
                                      <label
                                        class="form-check-label font-small theme-text-accent-one"
                                        for="flexCheckChecked"
                                      >
                                        Save your card details for faster
                                        checkout. CVV is not saved.
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Debit card section end -->
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-net-banking"
                              role="tabpanel"
                              aria-labelledby="v-pills-net-banking-tab"
                            >
                              <!-- Net Banking content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with Net Banking Card</span
                                  >
                                  <img
                                    src="assets/images/icons/card-options-icon.png"
                                    alt="card-options-icon"
                                    class="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-upi"
                              role="tabpanel"
                              aria-labelledby="v-pills-upi-tab"
                            >
                              <!-- UPI content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with UPI</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-apppay"
                              role="tabpanel"
                              aria-labelledby="v-pills-apppay-tab"
                            >
                              <!-- App content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with Mobile App</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-wallet"
                              role="tabpanel"
                              aria-labelledby="v-pills-wallet-tab"
                            >
                              <!-- Wallet content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with Wallet</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-payPal"
                              role="tabpanel"
                              aria-labelledby="v-pills-payPal-tab"
                            >
                              <!-- payPal content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with PayPal</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane fade"
                              id="v-pills-emi"
                              role="tabpanel"
                              aria-labelledby="v-pills-emi-tab"
                            >
                              <!-- EMI content -->
                              <div class="row">
                                <div class="col-12 mb-3">
                                  <span class="d-flex fw-bold mb-3"
                                    >Pay with EMI</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- amount total section -->
               
                <!-- <div class="row order-4">
                  <div class="col-12"> -->
                    <!-- <div class="mb-2">
                      Total amount to be paid
                      <span class="fw-bold"
                        ><i class="bi bi-currency-dollar"></i>22,978</span
                      >
                      <span class="font-extra-small d-flex">+ Payment Fee</span>
                    </div> -->
                    <!-- <button type="submit" class="btn btn-effect btn-book px-5">
                      <span class="fw-bold">Pay Now</span>
                    </button> -->

                    <!-- <button
                      type="submit"
                      class="btn btn-effect btn-book px-5"
                      :class="payLoader ? 'pe-none opacity-50' : ''"
                    >
                      <v-progress-circular
                        v-if="payLoader"
                        :width="5"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                      <span class="fw-bold">{{
                        !payLoader ? $t("billingDetContent.paymentMode.payNow") : ""
                      }}</span>
                    </button>

                    <p class="mt-2 mb-0 theme-text-accent-one font-small">
                      {{ $t("billingDetContent.paymentMode.payNowInfo") }}.
                    </p>
                  </div>
                </div> -->
              </div>
              <div class="col-12 col-md-4 order-5">
                <div class="fw-bold mb-3">{{ $t("flightInfoContent.contents.bookingDetails") }}</div>
                <div class="row">
                  <!-- flight info strip repetable -->
                  <div class="col-12">
                    <div
                      class="theme-box-shadow theme-border-radius theme-bg-white p-3 mb-3"
                    >
                      <!-- rount -->

                      <div v-for="(res, index) of rountresult" :key="index">
                        <div class="row g-0">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row d-flex justify-content-between">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo1(res.Depature)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo1"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo1.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.departure") }} {{ getDate(res.Depature) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ getfrom(res.fromTrip) }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ getto(res.toTrip) }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Depature) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Depature) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Depaturejourney.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Depature) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Depature) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Depature) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--rount return  -->
                        <div class="row g-0">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo1(res.Return)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo1"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0 && !logo1.length > 1">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index == 0 && logo1.length > 1">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo1.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.return") }} {{ getreturnDate(res.Return) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ getfrom(res.fromTrip) }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ getto(res.toTrip) }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getreturnAirportCode(res.Return) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getreturnTime(res.Return) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Returnjourney.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getReturnStops(res.Return) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getreturnArrivalcode(res.Return) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getreturnArrivalTime(res.Return) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-center">
                          <v-btn
                            style="
                              width: 140px;
                              height: 37px;
                              min-width: 0;
                              min-height: 0;
                              text-transform: none;
                              letter-spacing: 0;
                              margin: 0 auto;
                              border-radius: 5px;
                              border: 0.5px solid black;
                            "
                            class="travel-btn btn-book"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBackdrop"
                            aria-controls="offcanvasWithBackdrop"
                          >
                            {{ $t("flightInfoContent.contents.travelDetails") }}</v-btn
                          >
                        </div>
                        <hr />
                      </div>
                      <div class="drawer-section">
                        <div
                          class="offcanvas offcanvas-end"
                          tabindex="-1"
                          id="offcanvasWithBackdrop"
                          aria-labelledby="offcanvasWithBackdropLabel"
                        >
                          <div class="offcanvas-header">
                            <h5
                              class="offcanvas-title"
                              id="offcanvasWithBackdropLabel"
                            >
                              {{ $t("flightInfoContent.contents.flightDetails") }}
                            </h5>
                            <button
                              type="button"
                              class="text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              color="white"
                            >
                              <v-icon>mdi-close</v-icon>
                            </button>
                          </div>

                          <div class="offcanvas-body pt-0">
                            <div
                              v-for="(flightdatas, index) of rountresult"
                              :key="index"
                            >
                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.departure") }}
                                  </h6>
                                  <span
                                    class="fw-bold ms-2"
                                    style="font-size: 16px"
                                  >
                                    {{ getdepName(flightdatas.fromTrip) }} -
                                    {{ getarrName(flightdatas.toTrip) }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of flightdatas.Depature"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class=""
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Depature.length - 1 !== index
                                    "
                                    style="
                                      width: 65%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          syle="padding-top:5px;"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes1[index] }}
                                          - {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>

                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.arrival") }}
                                  </h6>
                                  <span
                                    class="fw-bold ms-2"
                                    style="font-size: 16px"
                                  >
                                    {{ getdepName(flightdatas.toTrip) }} -
                                    {{ getarrName(flightdatas.fromTrip) }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of flightdatas.Return"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-500"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-500"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Return.length - 1 !== index
                                    "
                                    style="
                                      width: 65%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          style="padding-top: 5px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes2[index] }}
                                          - {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- -----oneway----- -->

                      <div v-for="(res, index) of onewayresult" :key="index">
                        <div class="row g-0">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row d-flex justify-content-between">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo1(res.Depature)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo1"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo1.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.departure") }} {{ getDate(res.Depature) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ getfrom(res.fromTrip) }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ getto(res.toTrip) }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-3 text-lg-start text-center"
                              >
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Depature) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Depature) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Depaturejourney.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Depature) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-3 text-lg-end text-center"
                              >
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Depature) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Depature) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-center">
                          <v-btn
                            style="
                              width: 140px;
                              height: 37px;
                              min-width: 0;
                              min-height: 0;
                              text-transform: none;
                              letter-spacing: 0;
                              margin: 0 auto;
                              border-radius: 5px;
                              border: 0.5px solid black;
                            "
                            class="travel-btn btn-book"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBackdrop1"
                            aria-controls="offcanvasWithBackdrop"
                          >
                            {{ $t("flightInfoContent.contents.travelDetails") }}</v-btn
                          >
                        </div>
                        <hr />
                      </div>
                      <div class="drawer-section">
                        <div
                          class="offcanvas offcanvas-end"
                          tabindex="-1"
                          id="offcanvasWithBackdrop1"
                          aria-labelledby="offcanvasWithBackdropLabel"
                        >
                          <div class="offcanvas-header">
                            <h5
                              class="offcanvas-title"
                              id="offcanvasWithBackdropLabel"
                            >
                              {{ $t("flightInfoContent.contents.flightDetails") }}
                            </h5>
                            <button
                              type="button"
                              class="text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              color="white"
                            >
                              <v-icon>mdi-close</v-icon>
                            </button>
                          </div>

                          <div class="offcanvas-body pt-0">
                            <div
                              v-for="(flightdatas, index) of onewayresult"
                              :key="index"
                            >
                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.departure") }}
                                  </h6>
                                  <span
                                    class="fw-bold ms-2"
                                    style="font-size: 16px"
                                  >
                                    {{ getdepName(flightdatas.fromTrip) }} -
                                    {{ getarrName(flightdatas.toTrip) }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of flightdatas.Depature"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class=""
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Depature.length - 1 !== index
                                    "
                                    style="
                                      width: 65%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          syle="padding-top:5px;"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes[index] }}
                                          - {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- --------multi city-------- -->

                      <div v-for="(res, index) of multiresult" :key="index">
                        <!-- ------Flight1------- -->
                        <div class="row g-0">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row d-flex justify-content-between">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo(res.Flight1)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.flight") }}1 {{ getDate(res.Flight1) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ res.muticityData[0].from }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ res.muticityData[0].to }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Flight1) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Flight1) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Flight1JourneyTime.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Flight1) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Flight1) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Flight1) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- ------Flight2------- -->

                        <div class="row g-0">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo(res.Flight2)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.flight") }}2 {{ getDate(res.Flight2) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ res.muticityData[1].from }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ res.muticityData[1].to }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Flight2) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Flight2) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Flight2JourneyTime.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Flight2) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Flight2) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Flight2) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- ------Flight3------- -->

                        <div class="row g-0" v-if="res.Flight3 ? true : false">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo(res.Flight3)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.flight") }}3 {{ getDate(res.Flight3) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ res.muticityData[2].from }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ res.muticityData[2].to }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Flight3) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Flight3) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Flight3JourneyTime.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Flight3) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Flight3) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Flight3) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- ------Flight4------- -->
                        <div class="row g-0" v-if="res.Flight4 ? true : false">
                          <div class="col-12 pb-3 mb-3 border-bottom">
                            <div class="row">
                              <div class="col-12 col-md-12 mb-2">
                                <div class="d-flex">
                                  <div
                                    v-if="getlogo(res.Flight4)"
                                    class="d-flex flex-row"
                                    style="margin: auto 0"
                                  >
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <!-- <span v-if="index == 0">
                                        <v-img class="airline-logo" :src="result" width="40px"></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img :src="result" width="20px"></v-img>
                                      </span> -->
                                      <v-img
                                        :src="result"
                                        :width="
                                          logo.length <= 1 ? '40px' : '25px'
                                        "
                                        class="me-1"
                                      ></v-img>
                                    </span>
                                  </div>
                                  <div class="d-flex flex-column ms-2">
                                    <span class="font-medium fw-bold mb-0"
                                      >{{ $t("flightInfoContent.contents.flight") }}4 {{ getDate(res.Flight4) }}
                                    </span>
                                    <span
                                      class="font-medium mb-0 theme-text-accent-one"
                                    >
                                      {{ res.muticityData[3].from }}
                                      <i class="bi bi-arrow-right"></i>
                                      {{ res.muticityData[3].to }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 col-md-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getAirportCode(res.Flight4) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getTime(res.Flight4) }}
                                </div>
                              </div>
                              <div
                                class="col-4 col-md-6 text-center align-self-center"
                              >
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ res.Flight4JourneyTime.Time }}
                                </div>
                                <div
                                  class="font-extra-small theme-text-accent-one"
                                >
                                  {{ getDepStops(res.Flight4) }}
                                </div>
                              </div>
                              <div class="col-4 col-md-3 text-end">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ getArrivalCode(res.Flight4) }}
                                </div>
                                <div class="font-medium theme-text-accent-one">
                                  {{ getArrivalTime(res.Flight4) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-center">
                          <v-btn
                            style="
                              width: 140px;
                              height: 37px;
                              min-width: 0;
                              min-height: 0;
                              text-transform: none;
                              letter-spacing: 0;
                              margin: 0 auto;
                              border-radius: 5px;
                              border: 0.5px solid black;
                            "
                            class="travel-btn btn-book"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBackdrop2"
                            aria-controls="offcanvasWithBackdrop"
                          >
                            {{ $t("flightInfoContent.contents.travelDetails") }}</v-btn
                          >
                        </div>
                        <hr />
                      </div>
                      <div class="drawer-section">
                        <div
                          class="offcanvas offcanvas-end"
                          tabindex="-1"
                          id="offcanvasWithBackdrop2"
                          aria-labelledby="offcanvasWithBackdropLabel"
                        >
                          <div class="offcanvas-header">
                            <h5
                              class="offcanvas-title"
                              id="offcanvasWithBackdropLabel"
                            >
                              {{ $t("flightInfoContent.contents.flightDetails") }}
                            </h5>
                            <button
                              type="button"
                              class="text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              color="white"
                            >
                              <v-icon>mdi-close</v-icon>
                            </button>
                          </div>

                          <div class="offcanvas-body pt-0">
                            <div
                              v-for="(res, index) of multiresult"
                              :key="index"
                            >
                              {{ console.log(res, "dataaaaa...2") }}
                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.flight") }}1
                                  </h6>
                                  <span class="fw-bold">
                                    {{ getdepNameMulti(res.muticityData[0]) }} -
                                    {{
                                      getarrNameMulti(res.muticityData[0])
                                    }}</span
                                  >
                                </div>

                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of res.Flight1"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="res.Flight1.length - 1 !== index"
                                    style="
                                      width: 55%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          style="padding-top: 5px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ res.layoverTimes1[index] }} -
                                          {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.flight") }}2
                                  </h6>
                                  <span class="fw-bold">
                                    {{ getdepNameMulti(res.muticityData[1]) }} -
                                    {{
                                      getarrNameMulti(res.muticityData[1])
                                    }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of res.Flight2"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="res.Flight2.length - 1 !== index"
                                    style="
                                      width: 55%;
                                      position: relative;
                                      left: 21%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          style="padding-top: 5px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ res.layoverTimes2[index] }} -
                                          {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                v-if="res.Flight3"
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.flight") }}3
                                  </h6>
                                  <span class="fw-bold">
                                    {{ getdepNameMulti(res.muticityData[2]) }} -
                                    {{
                                      getarrNameMulti(res.muticityData[2])
                                    }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of res.Flight3"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="res.Flight3.length - 1 !== index"
                                    style="
                                      width: 55%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          style="padding-top: 5px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ res.layoverTimes3[index] }} -
                                          {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                v-if="res.Flight4"
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    {{ $t("flightInfoContent.contents.flight") }}4
                                  </h6>
                                  <span class="fw-bold">
                                    {{ getdepNameMulti(res.muticityData[3]) }} -
                                    {{
                                      getarrNameMulti(res.muticityData[3])
                                    }}</span
                                  >
                                </div>
                                <v-divider class="my-0"></v-divider>
                                <div
                                  v-for="(data, index) of res.Flight4"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-5 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span class="fw-600 f-size-16"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span class="fw-600 f-size-16"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="res.Flight4.length - 1 !== index"
                                    style="
                                      width: 55%;
                                      position: relative;
                                      left: 15%;
                                      background-color: #002d5b;
                                      color: white;
                                    "
                                    class="p-1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          src="../assets/sandClock.png"
                                          width="15px"
                                          style="padding-top: 5px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ res.layoverTimes4[index] }} -
                                          {{ $t("flightInfoContent.contents.layoverTime") }}
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- traveller details -->
                      <div class="d-flex">
                        <span class="font-medium fw-bold">{{ $t("billingDetContent.labelsContent.travellers") }}</span>
                      </div>
                      <div
                        class="d-flex justify-content-between pt-3"
                        v-for="(data, index) of totalPassData"
                        :key="index"
                      >
                        <span class="font-medium theme-text-accent-one"
                          >{{ index + 1 }}. {{ data.salutation }}.
                          {{ data.firstname }} {{ data.middlename }}
                          {{ data.lastname }}</span
                        >
                        <span class="font-medium ms-4">{{ data.gender }}</span>
                      </div>

                      <!-- contact details -->
                      <div class="d-flex mt-3">
                        <span class="font-medium fw-bold">{{ $t("billingDetContent.headingContent.contactDetails") }}</span>
                      </div>
                      <div
                        class="d-flex align-center justify-content-start pt-3"
                      >
                        <span class="font-medium theme-text-accent-one"
                          ><i class="bi bi-envelope"></i
                        ></span>
                        <span class="font-medium ms-4">{{
                          paxcontact[0].email || ""
                        }}</span>
                      </div>
                      <div
                        class="d-flex align-center justify-content-start pt-3"
                      >
                        <span class="font-medium theme-text-accent-one"
                          ><i class="bi bi-telephone"></i
                        ></span>
                        <span class="font-medium ms-4">{{
                          paxcontact[0].number || ""
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12">
                  <div class="fw-bold mb-3">{{ $t("fareDetContent.fareDetails") }}</div>
                  <div class="row">
                    <div class="col-12 mb-3">
                      <div
                        class="theme-box-shadow theme-border-radius theme-bg-white"
                      >
                        <ul class="p-0">
                          <li class="border-bottom p-3 font-medium">
                            <div class="d-flex justify-content-between">
                              <span
                                class="font-medium"
                                style="font-weight: 500"
                              >
                                {{ $t("fareDetContent.passenger") }}</span
                              >
                              <!-- <span>
                                                        <span class="" style="font-weight:500;">Total Price</span>
                                                    </span> -->
                            </div>
                          </li>
                          <!-- gst charge fare -->
                          <li class="border-bottom p-3 font-medium">
                            <div class="d-flex flex-column">
                              <div v-if="adult > 0">{{ adult }} x {{ $t("fareDetContent.adult") }}</div>
                              <div v-if="child > 0">{{ child }} x {{ $t("fareDetContent.child") }}</div>
                              <div v-if="inf > 0">{{ inf }} x {{ $t("fareDetContent.infrant") }}</div>
                              <!-- </span> -->
                            </div>
                          </li>
                          <!-- grand total charge fare -->
                          <li class="border-bottom p-3 font-medium">
                            <div class="d-flex justify-content-between">
                              <span class="fs-6" style="font-weight: 600">
                                {{ $t("fareDetContent.grandTotal") }}</span
                              >
                              <span class="fs-6">
                                <span class="fw-bold">
                                  {{ currency }}
                                  {{ totalAmountpassenger }}</span
                                >
                              </span>
                            </div>
                          </li>
                          <li class="border-bottom p-3 font-medium">
                            <a
                              class="d-flex justify-content-between align-center theme-text-accent-one"
                              data-bs-toggle="collapse"
                              href="#faresummary"
                              role="button"
                              aria-expanded="false"
                              aria-controls="faresummary"
                            >
                              <span class="font-medium fw-bold">
                                {{ $t("fareDetContent.fareSummary") }}</span
                              >
                              <span>
                                <i
                                  class="bi bi-plus-circle-fill theme-text-accent-two"
                                ></i>
                              </span>
                            </a>
                            <div class="collapse" id="faresummary">
                              <div
                                v-for="(res, index) of getDataResult"
                                :key="index"
                                class="bordeer-top-0 border border-2 border-top-0"
                              >
                                <div class="comDetailSection">
                                  <div class="paxpricesection">
                                    <table class="table table-borderless mb-0">
                                      <thead>
                                        <tr class="f-size-10 fw-500">
                                          <td style="background: #dee2e6">{{ $t("fareDetContent.pax") }}</td>
                                          <td style="background: #dee2e6">{{ $t("fareDetContent.base") }}</td>
                                          <td style="background: #dee2e6">{{ $t("fareDetContent.tax") }}</td>
                                          <td style="background: #dee2e6">{{ $t("fareDetContent.perPerson") }}</td>
                                        </tr>
                                      </thead>
                                      <tbody class="f-size-10 fw-400">
                                        {{
                                          console.log(res, "priceDetailllll")
                                        }}
                                        <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                                          <tr v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                            <td>{{ priceDetail.pax_type }}</td>
                                            <td>{{ priceDetail.baseprice }}</td>
                                            <td>{{ priceDetail.taxprice }}</td>
                                            <td>{{ getperpersonPrice(priceDetail) }}</td>
                                          </tr>
                                        </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="border-bottom p-3 font-medium">
                            <div class="d-flex justify-content-between">
                                <span class="font-medium"
                                  >{{ $t("fareDetContent.basePrice") }}<br />
                                  {{ $t("fareDetContent.taxesFees") }}</span
                                >
                                <div class="d-flex flex-column">
                                  <div class="row">
                                    <div class="col-4 pe-0">{{ currency }}</div>
                                    <div class="col-8 text-end ps-2">
                                      {{ totalbasePassanger }}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-4 pe-0">{{ currency }}</div>
                                    <div class="col-8 text-end">
                                      {{ totalTaxPassanger }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </li>
                          <li class="border-bottom font-medium">
                            <div class="d-flex text-start">
                              <a
                                class="baggage border-none p-3"
                                style="
                                  font-size: 13px;
                                  font-weight: 600;
                                  color: rgb(13, 110, 253);
                                  text-decoration: underline;
                                "
                              >
                                <span
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#staticBackdrop"
                                  aria-controls="staticBackdrop"
                                  >{{ $t("baggageContent.heading") }}</span
                                >
                              </a>
                            </div>
                            <div class="drawer-section">
                              <div
                                class="offcanvas offcanvas-end"
                                data-bs-backdrop="static"
                                tabindex="-1"
                                id="staticBackdrop"
                                aria-labelledby="staticBackdropLabel"
                              >
                                <div
                                  class="offcanvas-header"
                                  style="
                                    background: #5293cb !important;
                                    color: #fff !important;
                                  "
                                >
                                  <h6
                                    class="offcanvas-title"
                                    id="staticBackdrop"
                                  >
                                   {{ $t("baggageContent.heading") }}
                                  </h6>
                                  <button
                                    @click="closeBaggageRules"
                                    type="button"
                                    class="text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    color="white"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </button>
                                </div>
                                <div
                                  class="offcanvas-body pt-0"
                                  style="overflow-x: hidden"
                                >
                                  <v-card class="mt-4">
                                    <v-tabs
                                      v-model="tab"
                                      align-tabs="center"
                                      stacked
                                    >
                                      <v-tab value="tab-1" class="m-0">
                                        <v-icon>mdi-bag-checked</v-icon>
                                        {{ $t("baggageContent.baggage") }}
                                      </v-tab>
                                      <v-tab value="tab-2">
                                        <v-icon>mdi-book-cancel-outline</v-icon>
                                        {{ $t("baggageContent.cancelRules") }}
                                      </v-tab>
                                    </v-tabs>

                                    <v-card-text class="pt-0">
                                      <keep-alive>
                                        <v-window v-model="tab">
                                          <div v-if="tab === 'tab-1'">
                                            <h6 class="mt-1">{{ $t("baggageContent.baggageRules") }}:</h6>
                                            <div
                                              class="p-2 rounded"
                                              v-for="(
                                                data, index
                                              ) of rountresult || onewayresult"
                                              :key="index"
                                            >
                                              <div>
                                                <div
                                                  style="
                                                    font-size: 15px;
                                                    font-weight: 500;
                                                  "
                                                >
                                                  {{ data.fromTrip }} -
                                                  {{ data.toTrip }}
                                                </div>
                                                <div class="d-flex ms-2 mt-2">
                                                  <!-- <div>
                                  
                                </div> -->
                                                  <div
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 400;
                                                      margin-left: 5px;
                                                    "
                                                  >
                                                    <span> {{ $t("baggageContent.checked") }}:</span>
                                                    <span
                                                      v-if="data.depBaggage"
                                                      class="ms-1"
                                                    >
                                                      {{
                                                        data.depBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity || 0
                                                      }}

                                                      {{
                                                        data.depBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity > 1
                                                          ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                      }}
                                                    </span>
                                                    <span
                                                      v-if="data.BaggageData"
                                                      class="ms-1"
                                                    >
                                                      {{
                                                        data.BaggageData
                                                          .PieceAllowance
                                                          .TotalQuantity || 0
                                                      }}

                                                      {{
                                                        data.BaggageData
                                                          .PieceAllowance
                                                          .TotalQuantity > 1
                                                          ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                      }}
                                                    </span>
                                                    <v-icon
                                                      color="gray"
                                                      style="
                                                        color: gray !important;
                                                      "
                                                    >
                                                      mdi-bag-suitcase
                                                    </v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <!-- <div >
                                  
                                </div> -->
                                                  <div
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 400;
                                                      margin-left: 5px;
                                                    "
                                                  >
                                                    <span>{{ $t("baggageContent.carryOn") }}:</span>
                                                    <span
                                                      v-if="data.depBaggage"
                                                      class="ms-1"
                                                    >
                                                      {{
                                                        data.depBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}
                                                    </span>
                                                    <span
                                                      v-if="data.BaggageData"
                                                      class="ms-1"
                                                    >
                                                      {{
                                                        data.BaggageData
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}
                                                    </span>
                                                    <v-icon
                                                      color="gray"
                                                      style="
                                                        color: gray !important;
                                                      "
                                                    >
                                                      mdi-bag-checked
                                                    </v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr v-if="data.Return" />
                                              <div
                                                v-if="data.Return"
                                                class="mt-2"
                                              >
                                                <div
                                                  class="mt-1"
                                                  style="
                                                    font-size: 15px;
                                                    font-weight: 500;
                                                  "
                                                >
                                                  {{ data.toTrip }} -
                                                  {{ data.fromTrip }}
                                                </div>
                                                <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                <div class="d-flex ms-2 mt-1">
                                                  <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                                  <div
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 400;
                                                      margin-left: 5px;
                                                    "
                                                  >
                                                    <span> {{ $t("baggageContent.checked") }}:</span>
                                                    <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                                    <span class="ms-1">
                                                      {{
                                                        data.arrBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity || 0
                                                      }}
                                                      {{
                                                        data.arrBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity > 1
                                                          ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                      }}
                                                    </span>
                                                    <v-icon
                                                      color="gray"
                                                      style="
                                                        color: gray !important;
                                                      "
                                                    >
                                                      mdi-bag-suitcase
                                                    </v-icon>
                                                  </div>
                                                </div>

                                                <div class="d-flex ms-2 mt-2">
                                                  <!-- <div >
                                  
                                </div> -->
                                                  <div
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 400;
                                                      margin-left: 5px;
                                                    "
                                                  >
                                                    <span>{{ $t("baggageContent.carryOn") }}:</span>
                                                    <span class="ms-1">
                                                      {{
                                                        data.arrBaggage
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}
                                                    </span>
                                                    <v-icon
                                                      color="gray"
                                                      style="
                                                        color: gray !important;
                                                      "
                                                    >
                                                      mdi-bag-checked
                                                    </v-icon>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div v-if="multiresult">
                                              <div
                                                class="border p-2 rounded"
                                                v-for="(
                                                  data, index
                                                ) of multiresult"
                                                :key="index"
                                              >
                                                <div>
                                                  <div
                                                    style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    "
                                                  >
                                                    {{
                                                      getdepNameMulti(
                                                        data.muticityData[0]
                                                      )
                                                    }}
                                                    -
                                                    {{
                                                      getarrNameMulti(
                                                        data.muticityData[0]
                                                      )
                                                    }}
                                                  </div>
                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div>
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>
                                                        {{ $t("baggageContent.checked") }}:
                                                      </span>
                                                      <span class="mt-1">
                                                        {{
                                                          data.BaggageData1
                                                            .PieceAllowance
                                                            .TotalQuantity || 0
                                                        }}{{
                                                          data.BaggageData1
                                                            .PieceAllowance
                                                            .TotalQuantity > 1
                                                            ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                        }}</span
                                                      >
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-suitcase
                                                      </v-icon>
                                                    </div>
                                                  </div>

                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div >
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                      <span class="">{{
                                                        data.BaggageData1
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}</span>
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-checked
                                                      </v-icon>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr v-if="data.Flight2" />
                                                <div
                                                  v-if="data.Flight2"
                                                  class="mt-2"
                                                >
                                                  <div
                                                    class="mt-1"
                                                    style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    "
                                                  >
                                                    {{
                                                      getdepNameMulti(
                                                        data.muticityData[1]
                                                      )
                                                    }}
                                                    -
                                                    {{
                                                      getarrNameMulti(
                                                        data.muticityData[1]
                                                      )
                                                    }}
                                                  </div>
                                                  <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div>
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>
                                                        {{ $t("baggageContent.checked") }}:
                                                      </span>
                                                      <span class="mt-1">
                                                        {{
                                                          data.BaggageData2
                                                            .PieceAllowance
                                                            .TotalQuantity || 0
                                                        }}{{
                                                          data.BaggageData2
                                                            .PieceAllowance
                                                            .TotalQuantity > 1
                                                            ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                        }}</span
                                                      >
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-suitcase
                                                      </v-icon>
                                                    </div>
                                                  </div>

                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div >
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                      <span class="">{{
                                                        data.BaggageData2
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}</span>
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-checked
                                                      </v-icon>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr v-if="data.Flight3" />
                                                <div
                                                  v-if="data.Flight3"
                                                  class="mt-2"
                                                >
                                                  <div
                                                    class="mt-1"
                                                    style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    "
                                                  >
                                                    {{
                                                      getdepNameMulti(
                                                        data.muticityData[2]
                                                      )
                                                    }}
                                                    -
                                                    {{
                                                      getarrNameMulti(
                                                        data.muticityData[2]
                                                      )
                                                    }}
                                                  </div>
                                                  <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div>
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>
                                                        {{ $t("baggageContent.checked") }}:
                                                      </span>
                                                      <span class="mt-1">
                                                        {{
                                                          data.BaggageData3
                                                            .PieceAllowance
                                                            .TotalQuantity || 0
                                                        }}{{
                                                          data.BaggageData3
                                                            .PieceAllowance
                                                            .TotalQuantity > 1
                                                            ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                        }}</span
                                                      >
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-suitcase
                                                      </v-icon>
                                                    </div>
                                                  </div>

                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div >
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                      <span class="">{{
                                                        data.BaggageData3
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}</span>
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-checked
                                                      </v-icon>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr v-if="data.Flight4" />
                                                <div
                                                  v-if="data.Flight4"
                                                  class="mt-2"
                                                >
                                                  <div
                                                    class="mt-1"
                                                    style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    "
                                                  >
                                                    {{
                                                      getdepNameMulti(
                                                        data.muticityData[3]
                                                      )
                                                    }}
                                                    -
                                                    {{
                                                      getarrNameMulti(
                                                        data.muticityData[3]
                                                      )
                                                    }}
                                                  </div>
                                                  <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div>
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>
                                                        {{ $t("baggageContent.checked") }}:
                                                      </span>
                                                      <span class="mt-1">
                                                        {{
                                                          data.BaggageData4
                                                            .PieceAllowance
                                                            .TotalQuantity || 0
                                                        }}{{
                                                          data.BaggageData4
                                                            .PieceAllowance
                                                            .TotalQuantity > 1
                                                            ? this.$t('baggageContent.pieces') : this.$t('baggageContent.piece')
                                                        }}</span
                                                      >
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-suitcase
                                                      </v-icon>
                                                    </div>
                                                  </div>

                                                  <div class="d-flex ms-2 mt-2">
                                                    <!-- <div >
                                  
                                </div> -->
                                                    <div
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      "
                                                    >
                                                      <span>{{ $t("baggageContent.carryOn") }}: </span>
                                                      <span class="">{{
                                                        data.BaggageData4
                                                          .PieceAllowance
                                                          .TotalQuantity > 0
                                                          ? 1 + " " + $t("baggageContent.piece") : 1 + " " + $t("baggageContent.piece")
                                                      }}</span>
                                                      <v-icon
                                                        color="gray"
                                                        style="
                                                          color: gray !important;
                                                        "
                                                      >
                                                        mdi-bag-checked
                                                      </v-icon>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="tab === 'tab-2'">
                                            <h6 class="mt-2">
                                              {{ $t("baggageContent.cancelRules") }}:
                                            </h6>
                                            <div class="p-2 cancellationRules">
                                              <div
                                                v-html="fareRulesContent"
                                              ></div>
                                              <p class="my-1">
                                                {{ $t("baggageContent.errMsg") }}
                                              </p>
                                            </div>
                                          </div>
                                        </v-window>
                                      </keep-alive>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="display: none">
                    <div class="col-12">
                      <div
                        class="theme-box-shadow theme-border-radius bg-light p-3 font-small"
                      >
                        Cancellation &amp; Date change charges
                        <a href="#" class="">More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-8">
                  <!-- Acknowledgement details section -->
                  <div class="d-flex  justify-content-between align-items-start mb-3">
                    <div class="fw-bold">{{ $t("flightInfoContent.contents.acknowledgement") }}</div>
                  </div>
                  <!-- Acknowledgement details -->
                  <div class=" theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-check mb-3">
                          <input v-model="formCheckBox" class="form-check-input" type="checkbox" value=""
                            id="flexCheckChecked" checked>
                          <label class="form-check-label font-small mt-1" for="flexCheckChecked">
                            {{ $t("flightInfoContent.contents.ackInfo") }} <a href="/page/privacy-policy">{{
                              $t("flightInfoContent.contents.privacyPolicy") }}</a>, the <a href="/page/terms-of-use">{{
                                $t("flightInfoContent.contents.termsCondition") }}</a>
                            of {{ portal_id }}
                          </label>
                          <p v-if="!formCheckBox && checkForm" class="w-50 m-0 f-size-12 fw-400" style="color: #b00020">
                            {{
                              $t("flightInfoContent.contents.agreeText") }} </p>
                              
                        </div>
                        <button
                      type="submit"
                      class="btn btn-effect btn-book px-5"
                      :class="payLoader ? 'pe-none opacity-50' : ''"
                    >
                      <v-progress-circular
                        v-if="payLoader"
                        :width="5"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                      <span class="fw-bold">{{
                        !payLoader ? $t("billingDetContent.paymentMode.payNow") : ""
                      }}</span>
                    </button>

                    <p class="mt-2 mb-0 theme-text-accent-one font-small">
                      {{ $t("billingDetContent.paymentMode.payNowInfo") }}.
                    </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


          </div>
        </div>
      </div>
      <!-- page footer section -->
    </v-form>
  </div>

  <v-dialog v-model="dialog3" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3>
        <h5>{{ $t("errorContent.dialog1.text2") }}</h5>
        <h6>{{ $t("errorContent.dialog1.text3") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="tryAgainBtn()" color="#002d5b">{{ $t("errorContent.button.tryAgain") }}</v-btn>

          <v-btn to="/" color="#002d5b">{{ $t("errorContent.button.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog4" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3>
        <h5>{{ $t("errorContent.dialog1.text4") }}</h5>
        <h6>{{ $t("errorContent.dialog1.text3") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <!-- <v-btn @click="dialog3 = false" color="#002d5b">Try Again</v-btn> -->

          <v-btn to="/" color="#002d5b">{{ $t("errorContent.button.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
  <v-dialog v-model="dialog5" persistent>
    <div class="d-flex justify-center">
      <v-card class="pa-5">
   
        <div style="font-weight:600">
          Please Wait, Booking in Progress...
        </div>
        <div class="mt-2 text-center">
          <v-progress-circular color="#002d5b"
            indeterminate style="width:35px;height:56px;"></v-progress-circular>
        </div>
      </v-card>
    </div>
  </v-dialog>
  <v-dialog v-model="brantfare" persistent>
    <div class="d-flex justify-center">
      <v-card class="pa-5">
        <div class="d-flex justify-end">
            <v-icon @click="brantfare = false,dialog3=true" size="25" color="red">
              mdi-close-circle-outline
            </v-icon>
          </div>
          <div>
        <brantfareCom
                    :childData="this.barentFareData"
                    @childValue="handleChildValue"
                  ></brantfareCom>
                </div>

      </v-card>
    </div>
  </v-dialog>

  <!-- <div class="text-center" v-if="pageloader">
    <pageloader></pageloader>
  </div> -->

  <div v-if="!pageloader">
    <pageLoader></pageLoader>
  </div>
</template>

<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import countries from "@/countries.json";
import state from "@/statas.json";
import { ref } from "vue";
import axios from "axios";
// import pageloader from "@/components/dataLoder.vue";
import country1 from "@/coundtrycode.json";
import pageLoader from "@/components/pageLoader.vue";

import CardValidator from "card-validator";
// import Vue from 'vue'
// import VueMask from 'v-mask'
// import Isotope from 'isotope-layout'
// import LuhnFormula from '@/components/LuhnFormula.vue'
// import modalCard from '@/components/modalCard.vue'

// Vue.use(VueMask)

import brantfareCom from "@/components/brantFare.vue";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    // pageloader,
    brantfareCom,
    pageLoader,
    // LuhnFormula,
  },
  data() {
    return {
      shoppingid: "",
      barentFareData: [],
      dialog5:false,
      selectId: "",
      tab: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      // dates: Array.from({ length:20 }, (_, index) => newDate().getFullYear + 1),
      // pageloader: false,
      selectedMonth: "",
      selectedYear: "",
      cardName: false,
      // cardNumber: false,
      cardYear: false,
      cardMonth: false,
      pageloader: true,
      strResult: "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      dialog3: false,
      portal: false,
      countrycode: [],
      booking_Id: "",
      booking_refId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "26%",
      countriesData: [],
      formCheckBox: false,
      checkForm:false,
      stateData: [],
      datenumber: false,
      alterPhone: "",
      fareupgradeData: 90,
      totalpriceData: 0,
      totalAmountpassenger: [],
      totalTaxPassanger: [],
      totalbasePassanger: [],
      det: {
        name: "",
        email: "",
        
      },
      brantfare:false,
      multiresult: [],
      paxcontact: [],
      adult: "",
      child: "",
      inf: "",
      from: "",
      to: "",
      fromdate: "",
      todate: "",
      fromname: "",
      toname: "",
      fromcode: "",
      tocode: "",
      basePrice: "",
      taxprice: "",
      city: "",
      month,
      isIcon: false,
      passDeatail: [],

      adultDetailData: [],
      childDetailData: [],
      infDetailData: [],
      totalPassData: [],
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infantLength: "",
      classed: "",
      // flow,
      billingDetails: {
        cardnumber: "",
        cardholdername: "",
        ExpireDate: "",
        cvvnumber: "",
        logo: [],
        logo1: [],
        name: "",
        country: null,
        address1: "",
        address2: "",
        state: null,
        city: "",
        zipcode: "",
        phonenumber: "",
        email: "",
        phonenumber1: "",
        coutyCode: null,
        coutyCode1: null,
        email1: "",
      },
      depAirId: [],
      countryCode: "",
      retAirId: [],
      depAirLogo: [],
      retAirLogo: [],
      rountresult: [],
      totalmsg: [],
      bookinginiId: "",
      onewayresult: [],
      roundresult: [],
      currency: "",
      valid: false,
      altContact: true,
      mailAddress: false,
      local_id: "",
      Localoneway_id: "",
      // formatDate: "Month,year",
      emailId: "john@gmail.com",
      phone: "",

      // expDate: [
      //   (v) => !!v || "Expiry Date is required",
      //   (v) => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
      //   (v) => {
      //     const [month] = v.split("/");
      //     const isInvalidMonth = parseInt(month) > 12;
      //     const isInvalidFormat = !v.match(/^(0[1-9]|1[0-2])\/\d{2}$/);

      //     if (isInvalidMonth) return "Invalid month";
      //     if (isInvalidFormat) return "Invalid format (MM / YY)";
      //     return true;
      //   },
      // ],

      cardNumberValid: [
        (v) => !!v || "Card Number is required",
        (v) => /^\d+$/.test(v) || "Only numeric characters are allowed",
        // (v) => v.length === 16 || 'Card number must be 16 digits long'
      ],

      emailRequire: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
       expDate: [
        (v) => !!v || "Expiry Date is required",
        (v) => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
        (v) => {
          const [month, year] = v.split("/");

          const isInvalidFormat = !v.match(/^(0[1-9]|1[0-2])\/\d{2}$/);
          if (isInvalidFormat) return "Invalid format (MM / YY)";
              
          const inputMonth = parseInt(month);
          const inputYear = parseInt(year);
              
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear() % 100;
          const maxYear = currentYear + 10; 
              
          if (inputMonth < 1 || inputMonth > 12) return "Invalid month";
          if (inputYear < currentYear) return "Invalid year";
          if (inputYear === currentYear && inputMonth < currentMonth) return "Invalid month";
          if (inputYear > maxYear) return "Year exceeded max year";
              
          return true;
        },
      ],
      cvvValid: [
        (v) => !!v || "CVV is required",
        (v) => {
          // Validate card number using CardValidator
          const cardDetail = CardValidator.number(this.billingDetails.cardnumber.replace(/\D/g, ''));

          // Determine the CVV size based on the card type
          const cvvSize = cardDetail.card ? cardDetail.card.code.size : null;

          // Check if the card type was recognized and a CVV size is determined
          if (cvvSize === null) {
            return "Invalid CVV number";
          }

          // Validate the CVV length
          if (v.length !== cvvSize) {
            return `CVV must be ${cvvSize} digits`;
          }

          return true;
        }
      ],

      cardNameValid: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      radioBtn: "1",
      todayDate: "",
      fullName: "",
      selectedCountries: [],
      addressLine: "",
      activated: false,
      activated1: true,
      portalbookingUrl: "",

      userBooking: {
        booking_ref_id: "",
        portal_id: "",
        portal_domain:"",
        offer_id: "",
        payment_enable: "",
        pointofsale: "",
        trip_type: "",
        hit_id: "",
        shopping_response_id: "",
        adult: 1,
        child: 0,
        paxInfant: 0,
        ip_address: "127.0.0.1",
        search_cabin_type: "",
        pax_info: [],
        pax_contact: {
          countrycode: "",
          pax_phone: "",
          pax_email: "",
        },
        bill_info: {
          bill_fullname: "",
          bill_Country: "",
          bill_state: "",
          bill_address1: "",
          bill_address2: "",
          bill_city: "",
          bill_zipcode: "",
          bill_email: "",
          bill_phone_country_code: "",
          bill_phone: "",
          bill_alternate_contactno: null,
          bill_alternate_email: null,
        },
        card_info: {
          card_no: "",
          card_name: "",
          card_expiry: "",
          card_cvc: "",
        },
      },

      getCountryname: "",
      focusTop: 0,
      portal_id:"",
      portal_domain:"",

      // maxCardlength:0,

      payLoader: false,

      cardNumber: "",
      iso: null,
      cardrules: [
        () =>
          this.billingDetails.cardnumber.trim().length === 0 ||
          this.result.isValid ||
          "Invalid",
        value => !!value || 'Card Number is Required',
        // () => this.result.isValid || 'Invalid',
      ],
      dialog: false,
      fareRulesApi:"",
    };
  },
  methods: {
    

    handleChildValue(newval) {
      // this.returnvalue=value
      // console.log(this.returnvalue,'000000000000000000000000000001111111')
      if (newval) {
        if (this.onewayresult.length > 0) {
          this.onewayresult[0].TotalPrice = newval.TotalPrice;
          this.onewayresult[0].find1 = newval.personPrice;
          this.onewayresult[0].BrandId = newval.BrandId;
          this.onewayresult[0].BrandName = newval.BrandName;

        } else if (this.rountresult.length > 0) {
          console.log(
            this.rountresult[0],
            " // this.rountresult[0]"
          );
          this.rountresult[0].TotalPrice = newval.TotalPrice;
          this.rountresult[0].find1 = newval.personPrice;     
          this.rountresult[0].BrandId = newval.BrandId;
          this.rountresult[0].BrandName = newval.BrandName;
          console.log(
            this.rountresult[0],
            " // this.rountresult[0]"
          );
        } else if (this.multiresult.length > 0) {
          this.multiresult[0].TotalPrice = newval.TotalPrice;
          this.multiresult[0].find1 =newval.personPrice;          
          this.multiresult[0].BrandId = newval.BrandId;
          this.multiresult[0].BrandName = newval.BrandName;
        }
      }
    },



    fareSharing() {
      
      if (this.onewayresult.length > 0) {
        this.onewayresult.forEach((v) => {
          console.log(this.barentFareData,'pp')
          this.barentFareData = JSON.parse(localStorage.getItem(`${"brantFare"}-${v.selectId}`));
          this.barentFareData.getPrice=v.TotalPrice 
         this.brantfare=true
        });
      }
      if (this.multiresult.length > 0) {
        this.multiresult.forEach((v) => {
          this.barentFareData = JSON.parse(localStorage.getItem(`${"brantFare"}-${v.selectId}`));
          this.barentFareData.getPrice=v.TotalPrice 
          this.brantfare=true
        });
      }
      if (this.rountresult.length > 0) {
        this.rountresult.forEach((v) => {
          this.barentFareData = JSON.parse(localStorage.getItem(`${"brantFare"}-${v.selectId}`));
          console.log(this.barentFareData,v,'vasanth')
          this.barentFareData.getPrice=v.TotalPrice 
          this.brantfare=true
           
        });
      }
    },

    getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    closeBaggageRules() {
      this.tab = "tab-1";
    },
    tryAgainBtn() {
      this.dialog3 = false;
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    filterExpdate: function (evt) {
      evt = evt || window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      // Allow only numbers and the '/' character
      if (!/^[0-9/]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filterNumber: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filtercardName: function (evt) {
      evt = evt || window.event;
      const char = String.fromCharCode(evt.which || evt.keyCode);

      // Allow only letters (a-z, A-Z) and spaces
      if (!/^[a-zA-Z\s]*$/.test(char)) {
        evt.preventDefault();
        return;
      }
    },

    identifyCard: function () {
      let getCarddetails = CardValidator.number(this.billingDetails.cardnumber);

      console.log(getCarddetails, "getCarddetailsgetCarddetailsgetCarddetails");

      if (getCarddetails.card) {
        let gaps = getCarddetails.card.gaps;
        let cardNumberWithoutSpaces = this.billingDetails.cardnumber.replace(
          /\s/g,
          ""
        );
        let formattedCardNumber = "";

        console.log(
          cardNumberWithoutSpaces,
          "cardNumberWithoutSpacescardNumberWithoutSpaces"
        );
        for (let i = 0; i < cardNumberWithoutSpaces.length; i++) {
          console.log(i, "dfsfsdfsd", this.billingDetails.cardnumber.length);
          if (gaps.includes(i)) {
            formattedCardNumber += " ";
          }
          formattedCardNumber += cardNumberWithoutSpaces[i];
        }
        this.billingDetails.cardnumber = formattedCardNumber;
      }
      // Space Script End
    },

    // showImage(cardType) {
    //   let niceType = this.result?.card?.niceType;

    //   console.log(niceType, "niceTypeniceTypeniceType...1");

    //   if (niceType) {
    //     if (niceType.toLowerCase().replace(/\s+/g, "-") === cardType) {
    //       console.log(
    //         niceType.toLowerCase().replace(/\s+/g, "-"),
    //         "working....1",
    //         cardType
    //       );
    //       return true;
    //     } else {
    //       console.log(
    //         niceType.toLowerCase().replace(/\s+/g, "-"),
    //         "working....2",
    //         cardType
    //       );
    //       return false;
    //     }
    //   }
    // },
     showImage(cardType) {
      let niceType = this.result?.card?.niceType;

      // console.log(niceType,'niceTypeniceTypeniceType...1')

      if(niceType){
        if (niceType.toLowerCase().replace(/\s+/g, '-') === cardType) {
          // console.log(niceType.toLowerCase().replace(/\s+/g, '-'),'working....1',cardType);
          return true;
        } else {
          // console.log(niceType.toLowerCase().replace(/\s+/g, '-'),'working....2',cardType);
          return false;
        }
      }
    },

    formatCardnumber: function (evt) {
      evt = evt ? evt : window.event;

      let getCarddetails = CardValidator.number(this.billingDetails.cardnumber);

      if (getCarddetails.card) {
        let getCardlength = getCarddetails.card.lengths;
        if (
          getCardlength.includes(
            this.billingDetails.cardnumber.replace(/\s/g, "").length
          )
        ) {
          evt.preventDefault();
        }
      }
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    validateInput() {
      this.billingDetails.cardholdername =
        this.billingDetails.cardholdername.replace(/[^a-zA-Z]/g, "");
    },
    getdepName(data) {
      return data.split("(")[0];
    },
    getarrName(data) {
      return data.split("(")[0];
    },
    getdepNameMulti(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },

    getdatedFlight($event) {
      return moment($event).format("MMM DD");
    },
    getdatedFlightMulti($event) {
      return moment($event[0].Date).format("MMM DD");
    },
    getdatedFlight1($event) {
      return moment($event).format("MMM DD");
    },
    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll(
          "v-text-field, input, select, textarea, button"
        )
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
        e.preventDefault();
      }
    },

    handleNumericInput() {
      this.billingDetails.phonenumber = this.billingDetails.phonenumber.replace(
        /\D/g,
        ""
      );
    },
    handleNumericInput1() {
      this.billingDetails.alterPhone = this.billingDetails.alterPhone.replace(
        /\D/g,
        ""
      );
    },
    handleCountryChanged(countryCode) {
      this.countryCode = "+" + countryCode.dialCode;
      console.log("Selected Country Code:", this.countryCode);
    },
    formatExpireDate(event) {
      if (
        this.billingDetails.ExpireDate.length <= 2 &&
        event.inputType === "deleteContentBackward"
      ) {
        this.billingDetails.ExpireDate = this.billingDetails.ExpireDate.slice(
          0,
          3
        );
      } else if (this.billingDetails.ExpireDate.length === 2) {
        this.billingDetails.ExpireDate += "/";
      }
    },

    handleInput(e) {
      if (this.billingDetails.ExpireDate.length === 5 && e.keyCode !== 8) {
        e.preventDefault();
      }
    },

    // isMMYYFormat(value) {
    //     const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    //     return regex.test(value);
    // },

    retfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        // const currentIndex = i;
        // const nextIndex = (i + 1) % data.length;

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    depfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      // console.log(layoverTime, 'layoverTimelayoverTimelayoverTimelayoverTime')
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    getlogo(data) {
      console.log(data, "ppppppppppppp");
      this.logo = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo.includes(data[i].MarketingCarrier.logo)) {
          this.logo.push(data[i].MarketingCarrier.logo);
        }
      }
      return this.logo;
      // console.log(this.logo,'logologologologo')
    },
    getlogo1(data) {
      console.log(data, "ppppppppppppp");
      this.logo1 = [];
      for (let i = 0; i < data.length; i++) {
        if (!this.logo1.includes(data[i].MarketingCarrier.logo)) {
          this.logo1.push(data[i].MarketingCarrier.logo);
        }
      }
      // console.log(this.logo1,'logologologologo')
      return this.logo1;
    },
    getData() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      console.log(localData, "localdataaaaaa");
      this.fromdate = moment(localData.dedate).format("ddd, MMM D, YYYY");
      this.todate = moment(localData.redate).format("ddd, MMM D, YYYY");
      this.city = localData.city;
      this.fromname = localData.from;
      this.toname = localData.to;
      // if (localData.from) {
      //   this.from = localData.from.split(" ");
      //   this.from.forEach((v, i) => {
      //     if (i !== this.from.length - 1) {
      //       this.fromname = v;
      //       console.log(v, this.fromname, "tdtdtddtdttdd......");
      //     }
      //   });
      //   this.fromcode = this.from[this.from.length - 1]
      //     .replace("(", "")
      //     .replace(")", "");
      // }

      // if (localData.to) {
      //   this.to = localData.to.split(" ");
      //   this.to.forEach((v, i) => {
      //     if (i !== this.to.length - 1) {
      //       this.toname = v;
      //       console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
      //     }
      //   });

      //   this.tocode = this.to[this.to.length - 1]
      //     .replace("(", "")
      //     .replace(")", "");
      // }

      // ------------->Check this Function<--------------
      // if (localData.from.name) {
      //   this.from = localData.from.name.split(" ");
      //   this.from.forEach((v, i) => {
      //     if (i !== this.from.length - 1) {
      //       this.fromname += v;
      //     }
      //   });

      //   this.fromcode = this.from[this.from.length - 1]
      //     .replace("(", "")
      //     .replace(")", "");
      // }

      // if (localData.to.name) {
      //   this.to = localData.to.name.split(" ");
      //   this.tocode = this.to[this.to.length - 1]
      //     .replace("(", "")
      //     .replace(")", "");
      // } else {
      //   this.to = localData.to.split(" ");
      //   this.tocode = this.to[this.to.length - 1]
      //     .replace("(", "")
      //     .replace(")", "");
      // }
      // -------------><--------------

      // console.log(this.fromcode, " this.from");
      // console.log(this.tocode, "  this.to");
      // console.log(localData, "localData");
    },

    getadult() {
      this.getData();

      this.rountresult.forEach((v) => {
        console.log(v, "totalAmountpassenger....1");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    // cardnumber($data) {
    //   console.log($data, "$dat");
    // },

    onewayData() {
      this.getData();
      this.onewayresult.forEach((v) => {
        console.log(v, "totalAmountpassenger....2");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    getprice1() {
      let priceData = [];
      let rount = JSON.parse(localStorage.getItem("itineryData"));
      let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
      if (rount) {
        priceData = rount;
      }
      if (oneway) {
        priceData = oneway;
      }
      this.basePrice = priceData.price;
      this.taxPrice = priceData.Taxprice;
      // console.log(priceData, "priceData");
    },

    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },

    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },

    getDate($event) {
      return moment($event[0].Departure.Date).format(" ddd, DD MMM");
    },
    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getDepAirportName($event) {
      return $event[0].Departure.AirportName;
    },

    getReturnDepAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getArrAirportName($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    getReturnArrAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getDepStops($event) {
      console.log("ff", $event);
      if ($event.length - 1 == 0) {
        return "Non Stop";
      } else if ($event.length - 1 == 1) {
        return "1 Stop";
      } else {
        return "2 Stops";
      }
    },

    getReturnStops($event) {
      if ($event.length - 1 == 0) {
        return "Non Stop";
      } else if ($event.length - 1 == 1) {
        return "1 Stop";
      } else {
        return "2 Stops";
      }
    },

    // getDep1($event) {
    //     return $event[0].Departure.AirportCode;
    // },
    getfrom($data) {
      let data = $data.split("(");
      return data[0];
    },
    getto($data) {
      let data = $data.split("(");
      return data[0];
    },
    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event,index,'$event')

      return $event[$event.length - 1].Arrival.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getreturnDate($event) {
      return moment($event[0].Departure.Date).format(" ddd, DD MMM ");
    },
    getdeparDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdated($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getreturnArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },
    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "26%";
      }

      this.lastPosition = window.scrollY;
    },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(3);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(3);

      this.booking_refId = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_refId, "this.booking_refId ");
    },

    generateBookingRefId1() {
      const randomChars1 = this.getRandomChars(6);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(6);

      this.booking_Id = randomChars1 + randomNum + randomChars2;
      console.log(this.booking_Id, "this.booking_Id");
    },

    async multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      console.log(res1, "resssss");
      let data_pass = await JSON.parse(localStorage.getItem(`multi-${res1}`));
      console.log(data_pass, "data_pass");
      if (data_pass) {
        this.adultLength = data_pass.Adult || "";
        this.childLength = data_pass.Child || "";
        this.infLength = data_pass.Inf || "";
        this.adult = data_pass.Adult || "";
        this.child = data_pass.Child || "";
        this.inf = data_pass.Inf || "";
        console.log(data_pass, "data_pass");

        let totalpass = this.adultLength + this.childLength + this.infLength;
        console.log(totalpass, "totalpass");
        console.log(this.multiresult, "   this.multiresult");

        this.multiresult.forEach((v) => {
          console.log(v, "lllllllyyyyy");
          this.currency = v.currency;
          this.totalTaxPassanger = v.Taxprice;
          this.totalbasePassanger = v.Baseprice;
          this.totalAmountpassenger = v.TotalPrice;
        });
      } else {
        console.log("Data not found in localStorage");
      }
    },

    async passengerDetail() {
      this.passDeatail = await JSON.parse(
        sessionStorage.getItem("TravelerDetails")
      );
      console.log(this.passDeatail.adultDatas.length, "this.passDeatail ");

      for (let i = 0; i < this.passDeatail.adultDatas.length; i++) {
        if (i < this.adult) {
          console.log(this.passDeatail.adultDatas.length, "adultDataaaa..1");
          this.adultDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Adult",
            seat_requested:[]
          });
        }
      }

      this.adultDetailData.forEach((a, i) => {
        this.passDeatail.adultDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested
          }
        });
      });

      console.log(
        this.adultDetailData,
        "this.adultDetailDatathis.adultDetailData"
      );

      for (let i = 0; i < this.passDeatail.childDatas.length; i++) {
        if (i < this.child) {
          this.childDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Child",
            seat_requested:[]
          });
        }
      }

      this.childDetailData.forEach((a, i) => {
        this.passDeatail.childDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested
          }
        });
      });

      for (let i = 0; i < this.passDeatail.infrantDatas.length; i++) {
        if (i < this.inf) {
          this.infDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Infant",
          });
        }
      }

      this.infDetailData.forEach((a, i) => {
        this.passDeatail.infrantDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
          }
        });
      });

      this.totalPassData = [
        ...this.adultDetailData,
        ...this.childDetailData,
        ...this.infDetailData,
      ];

      this.totalPassData.forEach((c) => {
        c.fareup = true;
      });

      if (this.totalPassData.length > 0) {
        const firstAdultPassenger = this.totalPassData.find(
          (passenger) => passenger.pax_type === "Adult"
        );
        if (firstAdultPassenger) {
          this.billingDetails.name =
            firstAdultPassenger.firstname +
            " " +
            firstAdultPassenger.middlename +
            " " +
            firstAdultPassenger.lastname;
        }
      }

      console.log(this.totalPassData, "this.totalPassDatathis.totalPassData");
    },

    updateFare(data, index) {
      console.log(data, index, "data,index");

      if (this.activated1) {
        let $data = 0;
        this.fareupgradeData = 30;
        this.totalPassData.forEach((v) => {
          if (v.fareup) {
            $data = $data + 1;
          }
        });
        if ($data == 0) {
          this.basicData();
        }
        this.fareupgradeData = (this.fareupgradeData * $data).toFixed(2);
        this.TotalData();
      } else {
        this.totalPassData.forEach((c) => {
          c.fareup = false;
        });
      }
    },
    TotalData() {
      this.totalpriceData = (
        parseFloat(this.fareupgradeData) + parseFloat(this.totalAmountpassenger)
      ).toFixed(2);
    },
    basicData() {
      let $data = 0;
      this.fareupgradeData = $data.toFixed(2);
      (this.activated = true), (this.activated1 = false);
      this.totalPassData.forEach((c) => {
        c.fareup = false;
      });
      this.TotalData();
    },
    upgradeflowData() {
      this.totalPassData.forEach((c) => {
        c.fareup = true;
      });
      let $data = 90;
      this.fareupgradeData = $data.toFixed(2);
      (this.activated1 = true), (this.activated = false);
      this.TotalData();
    },

    userbook() {
      let Travelcontact = JSON.parse(localStorage.getItem("Travelcontact"));
      console.log(Travelcontact, "Travelcontact");
      if (this.booking_refId && this.booking_Id) {
        this.userBooking.booking_ref_id = this.booking_refId;
        this.userBooking.hit_id = `${"us"}-${this.booking_Id}`;
      }
      this.userBooking.whatsapp_opted = Travelcontact.contactCheck;
      this.userBooking.newsletter_subscribe = Travelcontact.couponsCheck;
      this.userBooking.adult = this.adult;
      this.userBooking.child = this.child;
      this.userBooking.paxInfant = this.inf;
      this.userBooking.portal_id = this.portal_id;
      this.userBooking.portal_domain = this.portal_domain;

      this.userBooking.pax_contact.pax_phone = this.paxcontact[0].number;
      this.userBooking.pax_contact.pax_email = this.paxcontact[0].email;
      let data = this.paxcontact[0].code;
      let countryCode = data.split("(").pop().replace(")", "");
      this.userBooking.pax_contact.countrycode = countryCode;
      this.userBooking.card_info.card_name = this.billingDetails.cardholdername;
      this.userBooking.card_info.card_no = this.billingDetails.cardnumber;
      this.userBooking.card_info.card_cvc = this.billingDetails.cvvnumber;
      this.userBooking.card_info.card_expiry = this.billingDetails.ExpireDate;

      this.userBooking.bill_info.bill_fullname = this.billingDetails.name;
      this.userBooking.bill_info.bill_Country = this.billingDetails.country;
      this.userBooking.bill_info.bill_state = this.billingDetails.state;
      this.userBooking.bill_info.bill_address1 = this.billingDetails.address1;
      this.userBooking.bill_info.bill_address2 = this.billingDetails.address2;
      this.userBooking.bill_info.bill_city = this.billingDetails.city;
      this.userBooking.bill_info.bill_zipcode = this.billingDetails.zipcode;
      this.userBooking.bill_info.bill_email = this.billingDetails.email;
      this.userBooking.bill_info.bill_phone = this.billingDetails.phonenumber;
      this.userBooking.bill_info.bill_alternate_contactno =
        this.billingDetails.phonenumber1;
      this.userBooking.bill_info.bill_alternate_email =
        this.billingDetails.email1;
      let data1 = this.billingDetails.coutyCode;
      if (data1) {
        let countryCode1 = data1.split("(").pop().replace(")", "");
        this.userBooking.bill_info.bill_phone_country_code = countryCode1;
      }

      if (this.rountresult.length > 0) {
        this.userBooking.trip_type = "return";
        this.rountresult.forEach((s) => {
          console.log(s, "djcvbddhjbad");
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
          this.userBooking.search_brand_id = s.BrandId;
          this.userBooking.search_brand_name = s.BrandName;
        });
      }

      if (this.multiresult.length > 0) {
        this.userBooking.trip_type = "multi";
        this.multiresult.forEach((s) => {
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
          this.userBooking.search_brand_id = s.BrandId;
          this.userBooking.search_brand_name = s.BrandName;
        });
      }

      if (this.onewayresult.length > 0) {
        this.userBooking.trip_type = "oneway";
        this.onewayresult.forEach((s) => {
          console.log(s, "oooooo");
          this.userBooking.offer_id = s.selectId;
          this.userBooking.pricingid = s.pricingid;
          this.userBooking.payment_enable = s.paymentgateway;
          this.userBooking.shopping_response_id = s.ShoppingResponseId;
          this.userBooking.pointofsale = s.currency.split("$")[0];
          this.userBooking.search_cabin_type = s.cabinClass;
          this.userBooking.search_brand_id = s.BrandId;
          this.userBooking.search_brand_name = s.BrandName;
        });
      }

      // this.adultDetailData = [];
      // this.childDetailData = [];
      // this.infDetailData = [];
      // console.log('adasdasad');

      this.adultDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });

      this.childDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });

      this.infDetailData.forEach((l) => {
        this.userBooking.pax_info.push(l);
      });
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.fareRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","")
        let getHref = this.$route.href;
        if (getHref.includes("env")) {
          this.portalbookingUrl =
            getConfigData1.payload.portal_configuration.API_endpoints.meta_booking;
        } else {
          this.portalbookingUrl =
            getConfigData1.payload.portal_configuration.API_endpoints.booking;
        }
      }
    },
    getEmail(paxcontact) {
      this.billingDetails.email = paxcontact[0].email;
      this.billingDetails.phonenumber = paxcontact[0].number;
      this.billingDetails.coutyCode = paxcontact[0].code;
      console.log(paxcontact, "paxcontacttt");
    },

    submitForm() {
      this.payLoader = true;
      // this.fareSharing()
      // this.billingDetails.ExpireDate = `${this.selectedMonth}/${this.selectedYear}`;
      // console.log(
      //   this.billingDetails.ExpireDate,
      //   "this.billingDetails.ExpireDate"
      // );

      let obj = {};
      this.generateBookingRefId1();
      this.generateBookingRefId();
      this.userbook();

      console.log(
        this.valid,
        this.billingDetails.phonenumber,
        this.billingDetails.cardnumber,
        this.billingDetails.cardholdername,
        this.billingDetails.cvvnumber,
        this.selectedMonth,
        this.selectedYear,
        "CheckConditions...."
      );

      console.log(
        this.userBooking,
        this.portalbookingUrl,
        "this.userBookingthis...portalbookingUrl"
      );

      if (this.billingDetails.phonenumber) {
        this.datenumber = false;
      } else {
        this.datenumber = true;
      }
      if(!this.formCheckBox){
         this.checkForm = true
      }

      if (
        this.valid &&
        this.billingDetails.phonenumber &&
        this.billingDetails.cardnumber &&
        this.billingDetails.cardholdername &&
        this.billingDetails.cvvnumber &&
        this.formCheckBox
      ) {
        this.dialog5=true
        this.pageloader = true;
        this.datenumber = false;

        // this.payLoader = true;

        if (this.portal) {
          this.userBooking.source = "env=cert";
        } else {
          this.userBooking.source = "portal";
        }

        console.log(
          this.userBooking,
          this.portalbookingUrl,
          "this.userBookingthisportalbookingUrl"
        );

        axios
          .post(this.portalbookingUrl, this.userBooking, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "responseresponseresponseidd312513524242442");

            this.payLoader = false;

            obj = {
              booking_Id: response.data.booking_details.booking_id,
              last_name: response.data.booking_details.pax_info[0].lastname,
              pnr: response.data.booking_details.pnr,
            };

            let booking_details = JSON.stringify(obj);
            console.log(obj, booking_details, "pvacatCSAACFAfc");
            if (this.portal) {
              this.payLoader = false;

              let uniDataId = `${"env"}-${this.local_id}`;
              localStorage.setItem(
                `${"env"}-${this.local_id}`,
                JSON.stringify(this.portalResData)
              );

              this.$router.push({
                path: "/bookingView",
                query: { pass_id: uniDataId, booking_id: booking_details },
              });
              this.pageloader = false;
            } else {
              this.payLoader = false;

              if (this.local_id) {
                // setTimeout(() => {
                this.payLoader = false;

                this.$router.push({
                  path: "/bookingView",
                  query: {
                    pass_id: this.local_id,
                    booking_id: booking_details,
                  },
                });
                // }, 2000);
              }
              if (this.Localoneway_id) {
                // setTimeout(() => {
                this.$router.push({
                  path: "/bookingView",
                  query: {
                    pass_uid: this.Localoneway_id,
                    booking_id: booking_details,
                  },
                });
                // }, 2000);
              }
              this.pageloader = false;
              this.payLoader = false;

              // else {
              //   alert("routing error....");

              // }
            }

            console.log(
              response,
              "userBookinguserBookinguserBookinguserBooking"
            );
          })
          .catch((error) => {
            this.pageloader = true;

            this.payLoader = false;
             this.dialog5=false
            console.log(error, "error mision");
            
            this.dialog3 = true;
            this.dialog5 = false;
            this.fareSharing()
          });
      } else {
        this.payLoader = false;

        console.log(
          this.valid,
          this.billingDetails.phonenumber,
          this.billingDetails.cardnumber,
          this.billingDetails.cardholdername,
          this.billingDetails.cvvnumber,
          this.selectedMonth,
          this.selectedYear,
          "CheckConditions....fails"
        );
        // this.datenumber=true
        // alert(
        //   "Airlines unable to confirm seats. Please try again later or book another itinerary"
        // );

        // if (!this.billingDetails.cardnumber) {
        //   this.cardNumber = true;
        // } else {
        //   this.cardNumber = false;
        // }
        if (!this.billingDetails.cardholdername) {
          this.cardName = true;
        } else {
          this.cardName = false;
        }

        if (!this.selectedMonth) {
          this.cardMonth = true;
        } else {
          this.cardMonth = false;
        }

        if (!this.selectedYear) {
          this.cardYear = true;
        } else {
          this.cardYear = false;
        }
      }
    },

    countryData() {
      countries.forEach((v) => {
        this.countriesData.push(v);
      });
    },
  },

  computed: {
    getDataResult() {
      if (this.rountresult && this.rountresult.length > 0) {
        return this.rountresult;
      } else if (this.onewayresult && this.onewayresult.length > 0) {
        return this.onewayresult;
      } else if (this.multiresult && this.multiresult.length > 0) {
        return this.multiresult;
      }

      return [];
    },
    dotColor() {
      return this.itineraryInfo ? "primary" : "success";
    },
    timeTextcolor() {
      return this.itineraryInfo ? "blue" : "black";
    },

    result: function () {
      return CardValidator.number(this.billingDetails.cardnumber);
    },
    appendIcon() {
      return this.result.isValid
        ? "mdi-check-underline"
        : "mdi-credit-card-outline";
    },
    iconColor() {
      if (this.billingDetails.cardnumber == "") return "";
      return this.result.isValid ? "green" : "red";
    },
    // filteredCardNumber() {
    //   return this.billingDetails.cardnumber.replace(/\D/g, "");
    // },

    //     expDate() {
    //   return [
    //     (v) => !!v || 'This field is required',
    //     (v) => this.isMMYYFormat(v) || 'Invalid format (MM / YY)',
    //   ];
    // },

    // cardImage() {
    //   const cardType = this.getCardType(this.billingDetails.cardnumber);
    //   return require(`@/assets/${cardType}.png`);
    // },

    // cardImage() {
    //   const cardType = this.getCardType(this.billingDetails.cardnumber);
    //   return require(`@/assets/${cardType}.png`);
    // },
  },
  watch: {
    "billingDetails.country"(newVal) {
      let result = [];
      this.countriesData.forEach((v) => {
        if (v.name == newVal) {
          result = v.code;
        }
      });
      Object.keys(state).forEach((c) => {
        if (c == result) {
          this.stateData = state[c];
        }
      });

      this.getCountryname = newVal;

      country1.forEach((v) => {
        let obj = {
          name: `${v.name}(${v.dial_code})`,
          code: v.code,
        };
        this.countrycode.push(obj);

        if (v.name == this.getCountryname) {
          this.countrycode.find((item) => {
            this.billingDetails.coutyCode = item.name;
            // console.log(this.billingDetails.coutyCode, 'v.namev.namev.namev.name')
          });
        }
      });
    },

    "billingDetails.cvvnumber"(newValue) {
      if (newValue.length > 4) {
        this.billingDetails.cvvnumber = newValue.slice(0, 4);
      }
    },
    "billingDetails.zipcode"(newValue) {
      if (newValue.length > 7) {
        this.billingDetails.zipcode = newValue.slice(0, 7);
      }
    },

    "billingDetails.phonenumber"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber = newValue.slice(0, 12);
      }
    },
    "billingDetails.phonenumber1"(newValue) {
      if (newValue.length > 12) {
        this.billingDetails.phonenumber1 = newValue.slice(0, 12);
      }
    },
    "billingDetails.cardnumber"(newValue) {
      if (!newValue) {
        this.cardNumber = false;
      }

      // else {
      //   this.cardNumber = true;
      // }
      // console.log(newValue, "newValue");
      // const numericValue = newValue.replace(/\s/g, '');
      // if (numericValue.length > 16) {
      //   this.billingDetails.cardnumber = numericValue.slice(0, 16);
      // }
    },
    "billingDetails.cardholdername"(newValue) {
      this.billingDetails.cardholdername =
        this.billingDetails.cardholdername.replace(/[^a-zA-Z]/g, "");
      if (newValue) {
        this.cardName = false;
      }
    },
    selectedYear(newValue) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentmonth = currentDate.getMonth();
      if (newValue.length > 3) {
        if (newValue == currentYear) {
          this.cardYear = false;
          return this.months.splice(0, currentmonth);
        } else if (newValue > currentYear) {
          this.months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          // console.log('pppppp',this.months)
        } else {
          this.months = [];
        }
      }
      if (newValue >= currentYear) {
        this.cardYear = false;
      } else {
        if (newValue.length > 3) {
          this.cardYear = true;
        }
      }
    },

    selectedMonth(newValue) {
      if (newValue) {
        this.cardMonth = false;
      } else {
        this.cardMonth = true;
      }
    },
  },

  mounted() {
  
    this.passengerDetail();
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });
    console.log(this.$route.params.id, "this.$route.params.id");
    this.countryData();
    let Data = 90;
    this.fareupgradeData = Data.toFixed(2);
    this.TotalData();

    // this.iso = new Isotope('.grid', {
    //   itemSelector: '.card',
    //   masonry: {
    //     columnWidth: 30,
    //   }
    // });

    // console.log(this.iso,'this.isothis.isothis.iso')

    // setTimeout(() => {
    //   this.iso.arrange({ filter: '*' })
    // }, 100);

   
  },

  created() {
    this.focusTop = 0;
    console.log(this.focusTop, "this.focusTopthis.focusTopthis.focusTop");

    this.$vuetify.theme.dark = true;

    let $data = localStorage.getItem("delete_Id");

    if ($data) {
      this.Localoneway_id = this.$route.query.pass_id1;
      this.local_id = this.$route.query.pass_id;

      // localStorage.removeItem(`${this.Localoneway_id}`);
      // localStorage.removeItem(`${this.local_id}`);
    }

    // let $data = localStorage.getItem("loader")
    // if ($data == "true") {
    //   this.$router.push('/')
    // }

    let $paxcontact = localStorage.getItem("passengerContact");
    console.log($paxcontact, "$paxcontact");
    this.paxcontact = JSON.parse($paxcontact);
    this.getEmail(this.paxcontact);
    // console.log(this.paxcontact[0].number, "ppppp");

    this.local_id = this.$route.query.pass_id;
    this.Localoneway_id = this.$route.query.pass_id1;

    console.log(
      this.Localoneway_id,
      "this.local_id this.local_idthis.Localoneway_id "
    );
    console.log(this.local_id, "this.local_id env ");
    this.getConfig();

    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid, "sdfssdfsdf");

      if (onewaydata_uid) {
        console.log("rountresult ", onewaydata_uid);

        if (onewaydata_uid.Return) {
          this.rountresult.push(onewaydata_uid);
          console.log(this.rountresult, "workingthisrountresult...1");

          if (this.rountresult.length > 0) {
            console.log("ResultWork...1");
            this.getadult();
          }

          this.itineraryInfo = true;
        } else {
          this.onewayresult.push(onewaydata_uid);
          console.log(this.onewayresult, "workingthisrountresult...2");

          if (this.onewayresult.length > 0) {
            console.log("ResultWork...2");
            this.onewayData();
          }

          this.itineraryInfo1 = true;
        }

        setTimeout(() => {
          this.pageloader = true;
        }, 3000);
      } else {
        this.dialog4 = true;
      }
    } else if (this.local_id) {
      if (this.local_id.includes("env")) {
        this.portal = true;
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid, "data_uiddata_uidoooooooooo");

        // if (!data_uid) {
        //   alert('Something went Wrong...')
        //   this.$router.push('/')
        // }
        console.log(data_uid, "data_uiddata_uiddata_uid");
        if (data_uid) {
          if (data_uid[0].Flight3 ? true : false) {
            this.multiresult = data_uid;
          } else if (data_uid[0].Return.length > 0 ? true : false) {
            this.rountresult = data_uid;
          } else {
            this.onewayresult = data_uid;
          }

          data_uid.forEach((v) => {
            this.local_id = v.selectId;
            this.currency = v.currency;
            let totalpass = [];

            this.adult = parseFloat(v.adult);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);

            console.log(
              this.adult,
              "this.adult",
              this.child,
              "this.child",
              this.inf,
              "this.inf"
            );

            totalpass =
              parseFloat(v.adult) + parseFloat(v.child) + parseFloat(v.inf);
            console.log(totalpass, "totalpass");

            this.totalTaxPassanger = parseFloat(
              parseFloat(v.Taxprice) * totalpass
            ).toFixed(2);
            this.totalbasePassanger = parseFloat(
              parseFloat(v.Baseprice) * totalpass
            ).toFixed(2);
            this.totalAmountpassenger = parseFloat(
              parseFloat(this.totalbasePassanger) +
                parseFloat(this.totalTaxPassanger)
            ).toFixed(2);

            console.log(data_uid, "data_uiddata_uiddata_uid");
            console.log(v, "basePassangerbasePassangerbasePassanger");
            console.log(
              this.totalAmountpassenger,
              this.totalbasePassanger,
              this.totalTaxPassanger,
              "this.totalbasePassangerthis.totalbasePassanger"
            );
          });
          setTimeout(() => {
            this.pageloader = true;
          }, 3000);
        } else {
          console.log(this.dialog4, "this.dialog4......2");
          this.dialog4 = true;
          // console.log("Something went Wrong....1")
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.multiresult.push(data_uid);
          this.multiData();
          console.log(this.multiresult, "multicity66666");
          setTimeout(() => {
            this.pageloader = true;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....2");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.Localoneway_id) {
        let data_uid1 = [];
        data_uid1 = JSON.parse(localStorage.getItem(`${this.Localoneway_id}`));
        console.log(data_uid1, "data_uid1data_uid1");

        if (data_uid1) {
          this.rountresult.push(data_uid1);
          this.getadult();
          this.itineraryInfo = true;
          setTimeout(() => {
            this.pageloader = true;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....3");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      }
    } else {
      this.dialog4 = true;
      console.log(this.dialog4, "this.dialog4......3");
      // alert("Something went Wrong...");
      // this.$router.push("/");
    }
    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId;
      this.selectId = this.roundresult[0].selectId;
    } else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId;
      this.selectId = this.multiresult[0].selectId;
    }
    let fareRequest = {
      request_type: "farerules",
      shopping_response_id: this.shoppingid,
      offer_id: this.selectId,
      portal_id: "traveljunction_au",
    };
    console.log(fareRequest, "fareRequestssssss");
    axios
      .get(this.fareRulesApi, {
        // headers: {
        // 	"Content-Type": "application/JSON",
        // },
        params: fareRequest,
      })
      .then((response) => {
        this.fareRulesContent = response.data;
        // console.log(response.data, "fareRulesContentfareRulesContent.....")
      })

      .catch((error) => {
        console.log(error, "erroroor.....");
      });
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Poppins:wght@300;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css");
/* @import url("owl.carousel.min.css");
@import url("owl.theme.default.min.css");
@import url("aos.css");
@import url("jquery-ui.css");
@import url("bootstrap.min.css"); */
/*-- color style --*/
/*-- font style --*/
/*-- media query --*/
@import "@/assets/main.css";

p {
  margin: 0;
}

.f-size-20 {
  font-size: 20px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

/* extra css */

/* >>> .bi-clock::before {
  font-size: 17px;
}
>>> .bi-info-circle-fill::before {
  font-size: 17px;
}
>>> .billingCard .v-input__control {
  height: 45px;
}

>>> .billingCard .v-field__field {
  height: 45px;
}
>>> .billingCard .v-field--focused {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
  border: none !important;
}
>>> .billingCard .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
}
>>> .billingCard .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-top: 8px;
}

>>> .billingCard .v-field__input input::placeholder {
  position: relative;
  top: -10px;
}

>>> .billingCard .v-autocomplete .v-field__input input::placeholder {
  position: relative;
  top: 0px;
}

>>> .billingCard .v-autocomplete .v-field--dirty .v-autocomplete__selection {
  position: relative;
  top: -6px;
}

>>> .billingCard .v-autocomplete .v-field .v-field__input > input {
  padding-bottom: 17px;
}

>>> .billingCard .alternate-Details .v-input__details {
  display: none !important;
} 
*/

>>> .billingCard .v-text-field input {
  padding-top: 0px !important;
}

>>> .billingCard .v-field__field {
  height: 36px;
}

>>> .billingCard .v-field.v-field--appended {
  height: 36px;
}

>>> .billingCard .v-field__append-inner {
  padding-top: 8px;
}

>>> .billingCard .v-field__input {
  padding-top: 6px;
}

>>> .billingCard .v-label .v-field-label {
  color: black !important;
}

>>> .billingCard .v-input {
  color: black !important;
  font-weight: 500 !important;
}

.error-text {
  font-size: 12px;
  color: red;
  margin-left: 5px;
  position: absolute;
}

>>> .drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px !important;
}

>>> .drawer-section .v-timeline {
  justify-content: left !important;
}

>>> .drawer-section .v-list-item__content {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-header {
  background: #5293cb !important;
  color: white !important;
}

>>> .btn-icon .v-btn--size-default {
  --v-btn-height: 11px !important;
}

.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
}

>>> .drawer-section .offcanvas-backdrop.show {
  pointer-events: none !important;
}

>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: space-evenly;
}

>>> .drawer-section .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .drawer-section .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b !important;
}

>>> .drawer-section .v-timeline-divider__inner-dot {
  background: #002d5b !important;
}

>>> .drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px;
}

>>> .drawer-section .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}
.drawer-section .offcanvas {
  width: 35%;
}
@media screen (min-width:480px) and (max-width:768px){
  .drawer-section .offcanvas {
    width:65%;
  }
}
@media screen and (max-width: 430px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
@media screen and (max-width: 480px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
.travel-btn {
  position: relative;
  background-color: #002d5b !important;
  color: white !important;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

.travel-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ffc104 0%);
  transition: left 0.3s;
}

.travel-btn:hover::before {
  left: 0;
}

.travel-btn:hover {
  color: black !important;
}

.input-group-append {
  position: absolute;
  right: 8px;
  bottom: 3px;
}

.card-image {
  height: 32px;
  width: 35px;
}

>>> .countryCode .country_code .v-autocomplete__selection-text {
  margin-bottom: 9px !important;
}

.card {
  width: 42px !important;
}

>>> .card_Number .v-field {
  height: 42px;
}
>>> .card_Number .v-field .v-field__input {
  padding-top: 5px;
  letter-spacing: 4px;
  font-weight: 500;
}
>>> .card_Number .v-field__field input::placeholder {
  letter-spacing: 0 !important;
}
>>> .card_Number .v-field__append-inner {
  padding-top: 3px;
  align-items: baseline;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule h2 {
  font-size: 17px !important;
  font-weight: 700 !important;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule p {
  overflow-wrap: break-word !important;
  inline-size: 58%;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule ul li {
  overflow-wrap: break-word !important;
  inline-size: 60%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
>>>.expiryDate .v-field__input{
  padding:15px;
  top:-5px;
}
>>>.expiryDate .v-input__control{
  height:38px;
}
>>>.expiryDate .v-field--center-affix .v-label.v-field-label{
  top:35%;
}
>>>.cvvCode .v-field__input{
  padding:15px;
  top:-5px;
}
>>>.cvvCode .v-input__control{
  height:38px;
}
>>>.cvvCode .v-field--center-affix .v-label.v-field-label{
  top:35%;
}
>>>.cardName .v-field__input{
  padding:15px;
  top:-5px;
}
>>>.cardName .v-input__control{
  height:38px;
}
>>>.cardName .v-field--center-affix .v-label.v-field-label{
  top:35%;
}
</style>
